import * as React from 'react';
import Footer from '../../layouts/Footer';
import Head from '../../layouts/Head';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';

interface IFAQProps {}

const FAQ: React.FunctionComponent<IFAQProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Head Title={t('home.title')} />
      <Container className="pt-5">
        <Row>
          <Col className="py-5">
            <h2>지갑이란</h2>
            <p
              className="text-white mb-5"
              style={{ whiteSpace: 'pre-line' }}
            >{`- 월렛이란?
            K-POP NFT 서비스에서 NFT 거래 등의 서비스를 이용하기 위해선 월렛을 연결해야합니다. 월렛은 가상자산을 담는 지갑입니다. 월렛의 예시로 메타마스크, 바이낸스 체인, 카이카스 등이 있습니다. 해당 월렛을 연결할 수 있는 거래소라면 공통으로 연결해 사용할 수 있으며, 월렛을 통해 NFT와 코인/토큰 등의 가상자산을 주고 받고 저장할 수 있습니다.
            일반적으로 일컫는 월렛은 디지털을 활용하는 것으로 브라우저에 내장되거나 브라우저 확장 기능, 모바일 앱을 통해 이용되지만, 자산을 금고와 같이 안전한 곳에 보호하고 싶다면 콜드월렛이라는 형태의 유형의 월렛을 사용할 수 있습니다.
            
            - 월렛의 주소와 비밀번호
            각 월렛은 주소가 있습니다. 이 주소를 통해 가상자산을 주고받을 수 있으며, 사용자 이름 또는 계정, 혹은 주소라고 생각하실 수 있습니다.
            
            월렛의 비밀번호와 복구코드는 월렛 생성 시에 정하게 됩니다. 월렛의 비밀번호와 복구코드(개인키)는 절대 타인과 공유해선 안 되며, 복구코드(개인키)의 경우 본인만 확인할 수 있는 종이 등에 써서 보관하시는 게 좋습니다. 일반적인 웹사이트의 계정들과 달리 복구코드(개인키)를 잊으신 경우 월렛 계정을 찾지 못할 수 있습니다. 월렛의 이용에 있어 K-POP NFT 서비스는 도움드릴 수 있는 부분이 없습니다.
            
            
            - 지갑 연결
            K-POP NFT 서비스 웹페이지 우측 상단에 ‘지갑 연결하기’ 아이콘을 통해 연결할 수 있습니다. 아이콘을 클릭할 시 연결 가능한 지갑의 종류가 나옵니다. 연결하고자하는 지갑을 선택하면 로그인 팝업 창이 뜹니다. 로그인 팝업 창이 보이지 않는다면, 브라우저 설정을 확인해주세요. 로그인 팝업창을 통해 로그인 하신 후에 NFT 거래 등의 서비스를 자유롭게 이용하실 수 있습니다. 연결 가능한 지갑의 항목 중 갖고계신 지갑이 없으시다면, 월렛 개설을 해주세요.`}</p>
            <h2>NFT란</h2>
            <p
              className="text-white mb-5"
              style={{ whiteSpace: 'pre-line' }}
            >{`NFT는 Non-Fungible Token의 줄임말입니다. 대체불가능한 토큰이라는 뜻으로, 디지털 자산의 소유권을 증명할 수 있습니다. NFT는 블록체인 기술을 통해 예술, 음악, 비디오, 수집품 등 모든 디지털 자산에 각 아이템의 고유한 가치가 부여된 것입니다. 예컨대 정품 보증서가 붙어있는 디지털 자산이라고 할 수 있습니다. 따라서 위·변조가 불가능합니다.
            NFT에 부여된 파일을 복제해 가품을 생성할 수는 있지만, 작품의 원본으로서의 가치가 보장되며, NFT에 대한 소유권은 소유자에게 있습니다.
            NFT에는 다음과 같은 정보들이 함유되어 있습니다.
            누가 만들었는가
            언제 생성했는가
            누가 언제 구입했는가
            현재의 판매가는 얼마인가
            누가 현재 소유하고 있는가
            * 뿐만 아니라 다양한 정보들을 담을 수 있습니다.
            
            NFT는 코인/토큰 등의 가상자산과 같이 월렛을 통해 원하는 곳으로 이동할 수 있으며, 가스비(Gas fee)라는 이름의 블록체인망 이용 수수료가 부과됩니다. NFT의 내용은 일반적으로 생각하는 그림과 사진, 동영상과 예술작품, 게임아이템, 도메인 등 굉장히 다양하며, 앞으로 점점 더 발전해 나갈 것입니다.`}</p>
            <h2>블록체인이란</h2>
            <p className="text-white" style={{ whiteSpace: 'pre-line' }}>
              블록체인은 네트워크 상의 거래를 추적해 거래 항목, 소유주 등 많은
              정보를 처리하는 네트워크의 한 방식이라고 생각하실 수 있습니다.
              해당 정보들은 여러 블록체인 참여자에게 분산 저장되어 위-변조가
              불가능합니다. 이 블록체인망을 이용한 행위에는 가스비(Gas fee)가
              필요합니다. 가스비(Gas fee)는 절대 K-POP NFT 서비스 제공자가 얻는
              이익이 아닙니다. 가스비(Gas fee)는 블록체인망이 동작할 수 있게하는
              참여자들에게 보상으로서 지급됩니다. 즉 가스비(Gas fee)를 받는
              참여자는 보통 ‘채굴자’라고 불리우는 거래증인입니다.
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default FAQ;
