import React from 'react';
import Countdown, { CountdownRendererFn } from 'react-countdown';
import { useRecoilState, useRecoilValue } from 'recoil';

import isAuctionFinishAtom from '../../../atoms/isAuctionFinish';
import contracts from '../../../constants/contracts';
// import data from './data.json'
import { IAuction, IBid } from '../../../types/index';
import { useInterval } from 'usehooks-ts';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { getSymbolText } from '../../../utils';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import artworkfire from '../../../assets/img/art-work/fire.png';
import TestPopup from '../TestPopup';
import walletAccountAtom from '../../../atoms/walletAccount';

const HighestBid = (auction: IAuction) => {
  const { t } = useTranslation();
  const { chainId }: { chainId?: string } = useParams();
  const [bids, setBids] = React.useState<IBid[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const walletAccount = useRecoilValue(walletAccountAtom);
  const [isAuctionFinish, setIsAuctionFinish] =
    useRecoilState(isAuctionFinishAtom);

  const getBids = async () => {
    if (chainId) {
      try {
        setIsLoading(true);
        // @ts-ignore
        const result = await contracts
          .nftMarketContractByChainId(parseInt(chainId))
          .methods.getBids(auction.auctionId)
          .call();

        let bids: IBid[] = [];
        result.map((a: any) => {
          bids = [
            ...bids,
            {
              bidId: a['bidId'],
              bidder: a['bidder'],
              price: a['price'],
              timestamp: a['timestamp'],
            },
          ];
        });

        setBids(bids);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useInterval(
    () => {
      getBids();
      setIsAuctionFinish(false);
    },
    isAuctionFinish ? 1000 : null
  );

  React.useEffect(() => {
    getBids();
  }, []);

  const renderer: CountdownRendererFn = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }) => {
    if (completed) {
      return <h5>{t('itemDetail.bidDone')}</h5>;
    } else {
      return (
        <div>
          <div className="slideCountdown">
            <div className="slide-block-time days">
              <div className="wrap-stage">{days}</div>
              <div className="title">{t('itemDetail.days')}</div>
            </div>
            <div className="slide-block-time hours">
              <div className="wrap-stage">{hours}</div>
              <div className="title">{t('itemDetail.hours')}</div>
            </div>
            <div className="slide-block-time minutes">
              <div className="wrap-stage">{minutes}</div>
              <div className="title">{t('itemDetail.minutes')}</div>
            </div>
            <div className="slide-block-time seconds">
              <div className="wrap-stage">{seconds}</div>
              <div className="title">{t('itemDetail.seconds')}</div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className="col-12 col-lg-3 mt-s">
        {auction &&
          moment().isBefore(moment(parseInt(auction.expiryDate) * 1000)) && (
            <TestPopup {...auction} />
          )}
        {/* TODO: 초로 받은거 밀리초로 변환 필요 */}
        <div className="biding-end mt-0 mb-4">
          <h5 className="mb-15">{t('itemDetail.bidingEnd')} :</h5>
          <div className="count-down titled circled text-center">
            {auction.tokenInfo ? (
              <Countdown
                date={new Date(parseInt(auction.expiryDate) * 1000)}
                renderer={renderer}
              />
            ) : (
              <div className="spinner-border text-primary"></div>
            )}
          </div>
        </div>
        <div className="highest-bid mb-4">
          <h5 className="w-text mb-15">{t('itemDetail.highestBidPrice')}</h5>
          <div className="admire align-items-center">
            {auction.tokenInfo ? (
              <>
                <div className="adm w-text">
                  {auction.highestBidder.slice(0, 7)}...
                  {auction.highestBidder.slice(
                    auction.highestBidder.length - 7
                  )}
                </div>
                <div className="adm d-flex align-items-center">
                  <img src={artworkfire} width="30" alt="" className="mr-5p" />
                  <span className="bold mr-5p">
                    {new BigNumber(parseInt(auction.currentPrice))
                      .div(new BigNumber(10).pow(18))
                      .toString()}{' '}
                    {/* @ts-ignore */}
                    {getSymbolText(parseInt(chainId))}
                  </span>
                  {/* <span className="gray-text"> $534.22</span> */}
                </div>
              </>
            ) : (
              <div className="text-center w-100">
                <div className="spinner-border text-primary"></div>
              </div>
            )}
          </div>
        </div>
        <div className="highest-bid bid-item">
          <h5 className="w-text mb-15">{t('itemDetail.recentBid')}</h5>
          <div className="admire flex-column">
            {isLoading ? (
              <div className="w-100 text-center">
                <div className="spinner-border text-primary"></div>
              </div>
            ) : (
              <>
                {bids.length > 0 ? (
                  bids.map((item, i) => (
                    <div
                      key={i}
                      className={`author-item align-items-start`}
                      style={{ marginBottom: i > 0 ? 20 : 0 }}
                    >
                      {/* <div className="author-img ml-0">
                  <img src={item.img} width="40" alt="" />
                </div> */}
                      <div className="author-info">
                        <p>
                          {t('itemDetail.by')}
                          <span className="w-text">
                            {' '}
                            {item.bidder.substr(0, 7)}...
                            {item.bidder.substr(item.bidder.length - 7)}
                          </span>
                        </p>
                        <p>
                          {t('itemDetail.bidAt')}
                          <span className="w-text">
                            {' '}
                            {new BigNumber(parseInt(item.price))
                              .div(new BigNumber(10).pow(18))
                              .toString()}{' '}
                            {/* @ts-ignore */}
                            {getSymbolText(parseInt(chainId))}
                          </span>
                        </p>
                      </div>
                      <div className="bid-price">
                        {/* <p>$346.38</p> */}
                        <p>
                          <i className="fa fa-clock-o mr-5p"></i>
                          {moment(parseInt(item.timestamp) * 1000).format(
                            'YYYY-MM-DD HH:mm'
                          )}
                        </p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-white">
                    {t('itemDetail.noBid')}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* <h4 className="mb-15 mt-30">History</h4>
        <div className="highest-bid bid-item">
          <div className="author-item mb-0">
            <div className="author-img ml-0">
              <img src={authors1} width="40" alt="" />
            </div>
            <div className="author-info">
              <p>
                Listed by<span className="w-text"> Amillia Nnor</span>
              </p>
              <p>
                Price<span className="w-text mr-15"> 0.212 ETH</span>
                <span>
                  <i className="fa fa-clock-o mr-5p"></i>01:36 AM
                </span>
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default HighestBid;
