import * as React from "react";
import * as S from "./style";
import Footer from "../../layouts/Footer";
import Head from "../../layouts/Head";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";

interface IPrivacyProps {
}

const Privacy: React.FunctionComponent<IPrivacyProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Head Title={t("home.title")} />
      <Container className="pt-5">
        <Row>
          <Col className="py-5">
            <S.Title>K-POP NFT 서비스 개인정보처리방침</S.Title>
            <S.ContentsBox>
              <S.Contents>
                주식회사 싸이클럽(이하 “회사”)은 이용자의 개인정보 보호를 매우 중요하게 생각하며, 회사가 제공하는 서비스를 이용하기 위해 이용자가 제공한 개인정보를 보호하기 위해 최선을 다하고
                있습니다. 회사는 “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 및 “개인정보보호법” 등 개인정보 보호와 관련된 법령을 준수하고 있습니다. 회사는 본 개인정보처리방침을 서비스 내에서
                이용자가 언제든지 쉽게 확인할 수 있도록 공개하고 있습니다. 본 개인정보처리방침은 관계 법령 및 회사의 내부 방침에 따라 변경될 수 있으며 개정 시 공식 서비스 페이지(또는 서면, 팩스,
                이메일 등의 개별 공지)를 통하여 공지하겠습니다.
              </S.Contents>

              <S.SubTitle>제 1 조 (개인정보 수집과 목적)</S.SubTitle>
              <S.Contents>
                <S.OrderedList>
                  <S.List>
                    회사는 서비스 이용에 반드시 필요한 개인신용정보를 다음과 같은 목적을 위하여
                    회사에서 수집하는 개인정보 항목과 각 목적은 다음과 같습니다. 회사는 명시한 목적으로만 이용자의 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는
                    이용되지 않으며, 이용 목적이 변경되는 경우 개인정보 보호법 제 18 조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
                  </S.List>
                  <S.List>
                    회사는 착한의사 서비스(이하 “서비스”) 이용에 필요한 최소한의 개인신용정보만을 수집하고
                    있습니다. 따라서, 필수 항목만을 처리하고 있습니다.
                  </S.List>
                  <S.List>
                    회사가 이미 보유한 정보가 있다면 입력을 생략하거나 이용자를 위하여 입력편의성을 제공할
                    수 있으나 처음으로 이용하는 경우 회사는 이용자에게 필수적인 정보의 입력을 요청할 수
                    있습니다.
                  </S.List>
                  <S.List>
                    회사는 대부분의 서비스에 대하여 ‘취소’ 또는 ‘철회’ 기능을 제공함으로써 이미 동의한
                    개인신용정보의 수집과 이용에 대하여 언제든지 취소하고 삭제할 수 있는 방법을 제공하고
                    있습니다. 만약, 특정 서비스의 개인신용정보 수집ㆍ이용 동의를 철회하는 경우에는 해당
                    서비스의 이용은 불가능하지만 다른 서비스의 이용에는 문제가 없습니다.
                  </S.List>
                </S.OrderedList>
                <S.InnerTitle>1. 월렛연결 및 서비스 이용 등을 통해 수집 및 이용하는 개인정보 항목</S.InnerTitle>
                <S.TableContainer>
                  <S.TableHeaderContainer>
                    <div>구분</div>
                    <div>내용</div>
                    <div>필수여부</div>
                    <div>수집 및 이용시점</div>
                  </S.TableHeaderContainer>
                  <S.TableLineContainer>
                    <div>월렛연결</div>
                    <div>
                      연결 월렛(Metamask 또는 Binance Chain 또는 Klip) 주소
                    </div>
                    <div>필수</div>
                    <div>월렛 연결</div>
                  </S.TableLineContainer>
                  <S.TableLineContainer>
                    <div>서비스 이용</div>
                    <div>이름, 유저명, 주소, e-mail</div>
                    <div>필수</div>
                    <div>프로필 수정</div>
                  </S.TableLineContainer>
                  <S.TableLineContainer>
                    <div>서비스 이용</div>
                    <div>프로필 및 프로필 배경사진</div>
                    <div>선택</div>
                    <div>프로필 수정</div>
                  </S.TableLineContainer>
                  <S.TableLineContainer>
                    <div>
                      서비스 이용 중 자동으로 생성되어 수집되는 정보
                    </div>
                    <div>
                      서비스 이용기록, 접속 로그, 거래기록, IP정보, 쿠키, 불량 및 부정 이용기록, 접속 기기 정보
                      불법 또는 부정 접근 관련 기록, 서비스 접근 시도기록, 서비스의 안전한 동작 환경 확인에 필요한 정보
                    </div>
                    <div>필수</div>
                    <div>서비스 이용</div>
                  </S.TableLineContainer>
                  <S.TableLineContainer>
                    <div>이용자 문의 및 비즈니스 문의</div>
                    <div>이름, 이메일주소</div>
                    <div>필수</div>
                    <div>문의 접수 및 처리</div>
                  </S.TableLineContainer>
                </S.TableContainer>

                <S.InnerTitle>2. 개인정보 수집 및 이용 목적</S.InnerTitle>
                <S.TableContainer>
                  <S.TableHeaderContainer_2>
                    <div>구분</div>
                    <div>수집 및 이용목적</div>
                  </S.TableHeaderContainer_2>
                  <S.TableLineContainer_2>
                    <div>이용자 정보 관리</div>
                    <div>
                      이용자 식별 및 정보관리, 계정 접근권한 및 사용권한 관리, 고지사항 고지
                      이용자 문의 및 비즈니스 문의 및 처리, 피해보상
                    </div>
                  </S.TableLineContainer_2>
                  <S.TableLineContainer_2>
                    <div>서비스 제공</div>
                    <div>
                      본인확인, 개인설정, 디지털 저작물 보유 및 거래 내용 확인, 거래금액 정산
                      서비스 안정성관리 및 품질관리
                      법률 및 서비스 이용약관 등의 위반 행위 제한
                    </div>
                  </S.TableLineContainer_2>
                </S.TableContainer>

                <S.InnerTitle>3. 개인정보 수집 방법</S.InnerTitle>
                <S.TableContainer>
                  <S.TableHeaderContainer_1>
                    <div>수집 방법</div>
                  </S.TableHeaderContainer_1>
                  <S.TableLineContainer_1>
                    <div>서비스 이용 및 이메일 상담 등을 통한 수집</div>
                  </S.TableLineContainer_1>
                  <S.TableLineContainer_1>
                    <div>자동수집 장치를 통한 수집</div>
                  </S.TableLineContainer_1>
                </S.TableContainer>
              </S.Contents>

              <S.SubTitle>제 2 조 (개인정보의 보유 및 이용기간)</S.SubTitle>
              <S.Contents>
                <S.InnerTitle>1. 개인정보 보유 및 이용기간</S.InnerTitle>
                <S.TableContainer>
                  <S.TableHeaderContainer_3>
                    <div>구분</div>
                    <div>보유 사유</div>
                    <div>이용기간</div>
                  </S.TableHeaderContainer_3>
                  <S.TableLineContainer_3>
                    <div>이용자 정보</div>
                    <div>
                      이용자 관리, 서비스 이용 및 제공, 이용자 식별
                    </div>
                    <div>이용자 탈퇴 후 30일</div>
                  </S.TableLineContainer_3>
                </S.TableContainer>
                <S.OrderedList>
                  <S.List>
                    다만, 관계 법령 위반에 따른 수사 또는 조사 등이 진행 중인 경우에는 수사 또는 조사 등의 종료 시까지 보유 및 이용합니다.
                  </S.List>
                </S.OrderedList>
              </S.Contents>
              <S.InnerTitle>2. 서비스 제공에 따른 법률에 의한 보존</S.InnerTitle>
              <S.OrderedList>
                <S.List>
                  <S.InnerOrderedList>
                    <S.InnerList>
                      ① 이용자의 개인정보는 개인정보 수집 및 이용 목적 달성 시 파기하는 것을 원칙으로 합니다. 단, 서비스 부정 이용으로 인한 분쟁 방지를 위해, 부정이용기록을 내부 방침에
                      따라 1 년간 보존할 수 있습니다.
                    </S.InnerList>
                    <S.InnerList>
                      ② 회사는 법령 및 ‘개인정보 유효 기간제’에 따라 1 년간 서비스를 이용하지 않는 장기 미 이용 이용자의 개인정보를 서비스 미이용 기간 만료 30일 전까지 개인정보가
                      파기되거나 분리되어 저장/관리 된다는 사실 및 일시, 개인정보 항목을 전자우편, 서면, 팩스, 전화 또는 이에 준하는 방법으로 통지한 후 별도로 분리 저장/관리 또는
                      파기합니다. 분리 보관된 개인정보는 4 년간 보관 후 파기 합니다. 단, 법령에서 일정 기간 정보 보관 의무를 부과하는 경우에는 해당 기간 동안 개인 정보를 안전하게
                      보관합니다.
                    </S.InnerList>
                  </S.InnerOrderedList>
                </S.List>
              </S.OrderedList>
              <S.TableContainer>
                <S.TableHeaderContainer_3>
                  <div>구분</div>
                  <div>관련 법률</div>
                  <div>이용기간</div>
                </S.TableHeaderContainer_3>
                <S.TableLineContainer_3>
                  <div>계약 또는 청약철회 등에 관한 기록</div>
                  <div>전자상거래 등에서의 소비자보호에 관한 법률</div>
                  <div>5년</div>
                </S.TableLineContainer_3>
                <S.TableLineContainer_3>
                  <div>소비자의 불만 또는 분쟁 처리에 관한 기록</div>
                  <div>전자상거래 등에서의 소비자보호에 관한 법률</div>
                  <div>3년</div>
                </S.TableLineContainer_3>
                <S.TableLineContainer_3>
                  <div>표시. 광고에 관한 기록</div>
                  <div>전자상거래 등에서의 소비자보호에 관한 법률</div>
                  <div>6개월</div>
                </S.TableLineContainer_3>
                <S.TableLineContainer_3>
                  <div>보상 및 오 입금에 관한 기록</div>
                  <div>전자상거래 등에서의 소비자보호에 관한 법률</div>
                  <div>5년</div>
                </S.TableLineContainer_3>
                <S.TableLineContainer_3>
                  <div>로그인 기록</div>
                  <div>통신비밀보호법</div>
                  <div>3개월</div>
                </S.TableLineContainer_3>
                <S.TableLineContainer_3>
                  <div>특정금융거래 및 본인확인에 관한 기록</div>
                  <div>특정금융거래정보 보고 및 이용 등에 관한 법률</div>
                  <div>5년</div>
                </S.TableLineContainer_3>
              </S.TableContainer>

              <S.SubTitle>제 3 조 (개인정보처리의 제공 및 위탁)</S.SubTitle>
              <S.Contents>
                <S.OrderedList>
                  <S.List>
                    회사는 이용자의 개인정보를 이용자의 동의 없이 제3자에게 제공하지 않으며, 제3자 제공이 필요하게 된 경우 이용자에게 해당 사실을 알리고 동의를 얻은 후에 개인정보를 제3자에게
                    제공합니다. 다만, 다음 각 호의 경우에는 제3자에게 제공될 수 있습니다.
                  </S.List>
                  <S.List>
                    <S.InnerOrderedList>
                      <S.InnerList>
                        ① 다른 법률에 특별한 규정이 있는 경우
                      </S.InnerList>
                      <S.InnerList>
                        ② 이용자본인 또는 그 법정대리인이 의사표시를 할 수 없는 상태에 있거나 주소불명 등으로 사전 동의를 받을 수 없는 경우로서 명백히 정보주체 또는 제3자의 급박한 생명,
                        신체, 재산의 이익을 위하여 필요하다고 인정되는 경우
                      </S.InnerList>
                      <S.InnerList>
                        ③ 통계작성 및 학술연구 등의 목적을 위해 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우
                      </S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                </S.OrderedList>
              </S.Contents>

              <S.SubTitle>제 4 조 (이용자 및 법정대리인의 권리 및 의무와 행사 방법)</S.SubTitle>
              <S.Contents>
                <S.OrderedList>
                  <S.List>
                    <S.InnerOrderedList>
                      <S.InnerList>
                        1. 이용자는 회사에 대해 언제든지 개인정보 열람・정정・삭제・처리 정지 요구 등의 권리를 행사할 수 있습니다. 다만, 개인정보 보호법 제35조 제4항, 제36조 제1항,
                        제37조 제2항 등 관계 법령에서 정하는 바에 따라 이용자의 개인정보 열람・정정・삭제・처리 정지 요구 등의 권리 행사가 제한될 수 있습니다.
                      </S.InnerList>
                      <S.InnerList>
                        2. 이용자는 개인정보 보호법 시행령 제41조 제1항에 따라 서면, 전자우편, 팩스 등을 통하여 이용자의 권리를 행사할 수 있으며, 회사는 이에 대해 곧바로 조치합니다.
                      </S.InnerList>
                      <S.InnerList>
                        3. 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 행사할 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른
                        위임장을 제출하여야 합니다.
                      </S.InnerList>
                      <S.InnerList>
                        4. 개인정보의 정정 및 삭제 요구 시 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
                      </S.InnerList>
                      <S.InnerList>
                        5. 회사는 이용자의 이용 권리에 따른 열람의 요구, 정정.삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
                      </S.InnerList>
                      <S.InnerList>
                        6. 이용자는 권리행사의 거부 등에 대하여 불복 시 회사에 이의제기를 하실 수 있으며, 회사는 이에 대해 곧바로 조치합니다.
                      </S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                </S.OrderedList>
              </S.Contents>

              <S.SubTitle>제 5 조 (개인정보의 파기)</S.SubTitle>
              <S.Contents>
                <S.OrderedList>
                  <S.List>
                    1. 회사는 이용자의 개인정보 보유 기간이 경과하였거나 개인정보 수집 및 이용 목적 달성 등으로 인해 이용자의 개인정보가 불필요하게 되었을 때 곧바로 해당 개인정보를 파기합니다.
                  </S.List>
                  <S.List>
                    2. 이용자로부터 동의 받은 개인정보 보유 기간이 지나거나 처리 목적이 달성되었음에도 불구하고 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인 정보를 별도의
                    데이터베이스(DB)로 옮기거나 보관장소를 분리하여 보존합니다.
                  </S.List>
                  <S.List>
                    3. 개인정보 파기 방법은 다음과 같습니다.
                    <S.InnerOrderedList>
                      <S.InnerList>① 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없도록 영구 삭제</S.InnerList>
                      <S.InnerList>② 종이 문서에 기록.저장된 개인정보는 분쇄기로 분쇄하거나 소각</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                </S.OrderedList>
              </S.Contents>

              <S.SubTitle>제 6 조 (개인정보의 기술적 또는 관리적 보호 대책)</S.SubTitle>
              <S.Contents>
                <S.OrderedList>
                  <S.List>
                    회사는 이용자의 개인정보를 처리하면서 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 또는 관리적 보호 대책을 시행합니다.
                  </S.List>
                  <S.List>
                    1. 기술적 보호 대책
                    <S.InnerOrderedList>
                      <S.InnerList>① 개인정보처리시스템 접근권한 관리 : 개인정보에 대한 접근통제를 위해 개인정보 처리 데이터베이스 시스템에 대한 접근권한의 부여, 변경, 말소를 통해
                        필요한 조치를 취하며 개인정보처리시스템에 대한 침입차단시스템을 이용하여 외부로부터의 접근을 통제합니다.</S.InnerList>
                      <S.InnerList>② 개인정보의 암호화 : 이용자의 개인정보 중 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등 별도의 보안기능을
                        사용합니다.</S.InnerList>
                      <S.InnerList>③ 해킹 등에 대비한 기술적 대책 : 해킹이나 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위해 주기적으로 점검을 하며 외부로부터 접근이 통제된
                        구역에 시스템을 설치하는 등 기술적·물리적으로 감시 및 차단합니다.</S.InnerList>
                      <S.InnerList>④ 물리적 조치 : 전산실, 자료 보관실 등에 대한 접근 물리적 통제</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                  <S.List>
                    2. 관리적 보호 대책
                    <S.InnerOrderedList>
                      <S.InnerList>① 내부관리계획 수립 및 시행</S.InnerList>
                      <S.InnerList>② 개인정보 취급 직원의 최소화 및 교육 : 개인정보를 취급하는 직원을 지정하고 접근권한을 담당자에 한정함을 통해 접근인원을 최소화하여 개인정보를
                        관리하는 대책을 시행합니다.</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                </S.OrderedList>
              </S.Contents>

              <S.SubTitle>제 7 조 (개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항)</S.SubTitle>
              <S.Contents>
                <S.OrderedList>
                  <S.List>
                    회사는 이용자의 서비스 편의를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트가 이용자의 컴퓨터 브라우저(인터넷 익스플로러
                    등)에 전송하는 소량의 정보입니다.
                  </S.List>
                  <S.List>
                    1. 쿠키의 사용 목적
                    <S.InnerOrderedList>
                      <S.InnerList>쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 더욱 빠른 웹 환경을 지원하며, 이용자들의 손쉽고 편리한 이용을 위한 서비스 개선에
                        활용합니다.</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                  <S.List>
                    2. 쿠키의 설치, 운영 및 거부
                    <S.InnerOrderedList>
                      <S.InnerList>이용자는 쿠키 설치에 대한 선택권을 가지고 있으며, 이용자가 원할 시 쿠키 설정을 통해 쿠키의 저장을 거부하거나 삭제할 수
                        있습니다.</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                  <S.List>
                    3. 쿠키 설정 거부 방법
                    <S.InnerOrderedList>
                      <S.InnerList>① Internet Explorer : 도구 메뉴 선택 &gt; 인터넷 옵션 선택 &gt; 개인정보 탭 클릭 &gt; 고급 개인정보 설정 &gt; 쿠키
                        수준 설정</S.InnerList>
                      <S.InnerList>② Chrome : 설정 메뉴 선택 &gt; 고급 설정 표시 선택 &gt; 개인정보 및 보안 &gt; 콘텐츠 설정 선택 &gt; 쿠키 수준
                        설정</S.InnerList>
                      <S.InnerList>③ Safari : 환경설정 메뉴 선택 &gt; 개인정보 탭 선택 &gt; 쿠키 및 웹 사이트 데이터 수준 설정</S.InnerList>
                      <S.InnerList>④ FireFox: 도구 메뉴 선택 &gt; 환경 설정 메뉴 선택 &gt; 개인 정보 및 보안 탭 선택 &gt; 콘텐츠 차단
                        설정</S.InnerList>
                      <S.InnerList>⑤ Opera: 도구 메뉴 선택 &gt; 설정 메뉴 선택 &gt; 개인 정보 보호 및 보안 탭 선택 &gt; 쿠키 수준 설정</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                  <S.List>
                    4. 구글 애널리틱스(Google Analytics)
                    <S.InnerOrderedList>
                      <S.InnerList>① 회사는 서비스와 제품을 개선하고 이용자에게 효율적인 서비스를 제공하기위해 Google, Inc. (이하 ‘Google’)이 제공하는 웹 분석 서비스인
                        구글 애널리틱스(Google Analytics)를 사용합니다. 이를 통해 이용자들이 서비스를 어떻게 이용하는지 분석 및 평가하고 이용자의 수요를
                        파악합니다. </S.InnerList>
                      <S.InnerList>② 회사는 통계 작성 및 학술 연구 등의 목적을 위하여 구글 애널리틱스(Google Analytics)에 본 개인정보처리방침에 따라 필요한 자료를 제공할
                        수 있습니다.</S.InnerList>
                      <S.InnerList>③ 구글 애널리틱스(Google Analytics)를 통해 수집되는 정보의 처리는 Google 개인정보보호정책 및 Google Analytics 서비스
                        이용약관을 적용 받습니다.
                        <S.InnerOrderedList>
                          <S.InnerList>
                            - 구글 애널리틱스(Google Analytics) 서비스 약관: https://www.google.com/analytics/terms/kr.html
                          </S.InnerList>
                          <S.InnerList>
                            - 구글 애널리틱스(Google Analytics) 개인정보처리방침: https://policies.google.com/privacy?hl=kr
                          </S.InnerList>
                        </S.InnerOrderedList>
                      </S.InnerList>
                      <S.InnerList>④ IP 주소는 마지막 두개의 옥텟(Octet)이 제거 되어 익명화 되어 저장되며, 다른 구글 데이터와 병합 또는 연결시키지
                        않습니다.</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                </S.OrderedList>
              </S.Contents>

              <S.SubTitle>제 8 조 (개인정보 보호 책임자 및 담당 부서)</S.SubTitle>
              <S.Contents>
                <S.OrderedList>
                  <S.List>
                    1. 회사는 이용자의 개인정보를 보호하고 개인정보와 관련한 조치를 취하기 위하여 아래와 같이 관련 부서 및 개인정보 보호 책임자를 지정하고 있습니다.
                    <S.InnerOrderedList>
                      <S.InnerList>- 이름: 차경상</S.InnerList>
                      <S.InnerList>- 직책: 개인정보 보호책임자</S.InnerList>
                      <S.InnerList>- 전화번호: 02-2157-9341</S.InnerList>
                      <S.InnerList>- 이메일: privacy@cyclub.co</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                  <S.List>
                    2. 이용자가 회사의 서비스를 이용하며 발생하는 모든 개인정보 보호 관련 민원은 개인정보 보호 책임자 및 담당 부서로 문의하실 수 있습니다. 회사는 이용자의 문의 사항에 대해 답변
                    및 처리해드릴 것입니다.
                  </S.List>
                </S.OrderedList>
              </S.Contents>

              <S.SubTitle>제 9 조 (권익침해 구제방법)</S.SubTitle>
              <S.Contents>
                <S.OrderedList>
                  <S.List>
                    개인정보 침해신고센터 (한국인터넷진흥원 운영)
                    <S.InnerOrderedList>
                      <S.InnerList>- 홈페이지: privacy.kisa.or.kr</S.InnerList>
                      <S.InnerList>- 전화: (국번없이) 118</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                  <S.List>
                    개인정보 분쟁조정위원회
                    <S.InnerOrderedList>
                      <S.InnerList>- 홈페이지: www.kopico.go.kr</S.InnerList>
                      <S.InnerList>- 전화: (국번없이) 1833-6972</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                  <S.List>
                    대검찰청 사이버범죄수사단
                    <S.InnerOrderedList>
                      <S.InnerList>- 홈페이지: www.spo.go.kr</S.InnerList>
                      <S.InnerList>- 전화: 02-3480-3573</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                  <S.List>
                    경찰청 사이버안전국
                    <S.InnerOrderedList>
                      <S.InnerList>- 홈페이지: cyberbureau.police.go.kr</S.InnerList>
                      <S.InnerList>- 전화: (국번없이) 182</S.InnerList>
                    </S.InnerOrderedList>
                  </S.List>
                </S.OrderedList>
              </S.Contents>

              <S.SubTitle>제 10 조 (링크 사이트에 대한 책임)</S.SubTitle>
              <S.Contents>
                <S.OrderedList>
                  <S.List>
                    회사는 이용자에게 다른 외부 사이트로 연결되는 링크를 제공할 수 있습니다. 이 경우 회사는 외부 사이트에 대한 통제권이 없으므로 이용자가 외부 사이트로부터 제공받은 서비스나 자료의
                    유용성, 진실성, 적법성에 대해 책임 및 보증할 수 없으며, 링크된 외부 사이트의 개인정보처리방침은 회사와 무관하므로 해당 외부 사이트의 정책을 확인하시기 바랍니다.
                  </S.List>
                </S.OrderedList>
              </S.Contents>

              <S.SubTitle>제 11 조 (개인정보처리방침 변경)</S.SubTitle>
              <S.Contents>
                <S.OrderedList>
                  <S.List>
                    현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 15 일 이전부터 ‘공지사항’을 통해 알릴 것입니다. 다만, 개인정보의 수집 및 활용, 제 3 자 제공
                    등과 같이 이용자 권리의 중요한 변경이 있을 때는 최소 30 일 이전에 알립니다.
                  </S.List>
                </S.OrderedList>
              </S.Contents>

              <S.SubTitle>부칙</S.SubTitle>
              <S.Contents>
                <S.OrderedList>
                  <S.List>
                    이 약관은 2022년 1월 12일부터 적용됩니다.
                  </S.List>
                </S.OrderedList>
              </S.Contents>
            </S.ContentsBox>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Privacy;
