import React from 'react';

import { create } from 'ipfs-http-client';
import { useDropzone } from 'react-dropzone';
import { provider } from 'web3-core';

import contracts from '../../../../constants/contracts';
import walletAccountAtom from '../../../../atoms/walletAccount';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import createItemCategoryAtom from '../../../../atoms/createItemCategory';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import addresses from '../../../../constants/addresses';
import caver from '../../../../connection/caver';
import NFTCollection from '../../../../abis/NFT.json';
import toastAtom from '../../../../atoms/toast';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import { useInterval } from 'usehooks-ts';
import { caverAPI } from '../../../../connection/caver';
import QRCode from 'qrcode.react';
import { isMobile } from 'react-device-detect';
import klipRequestKeyAtom from '../../../../atoms/klipRequestKey';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FaCheck, FaChevronDown, FaChevronUp, FaTimes } from 'react-icons/fa';
import { ipfsUrl } from '../../../../constant';

const ipfs = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
});

const ContactForm: React.FC = () => {
  const { t } = useTranslation();

  const categories = [
    t('createItem.categoryItem1'),
    t('createItem.categoryItem2'),
    // t('createItem.categoryItem3'),
    // t('createItem.categoryItem4'),
    // t('createItem.categoryItem5'),
    // t('createItem.categoryItem6'),
    // t('createItem.categoryItem7'),
  ];

  const { chainId } = useWeb3React();
  const { ethereum }: { ethereum: provider } = useWallet();
  const history = useHistory();

  const walletAccount = useRecoilValue(walletAccountAtom);
  const setToast = useSetRecoilState(toastAtom);

  const [capturedFileBuffer, setCapturedFileBuffer] =
    React.useState<Buffer | null>(null);
  const [title, setTitle] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const [artist, setArtist] = React.useState<string>('');
  const [website, setWebsite] = React.useState<string>('');
  const [twitter, setTwitter] = React.useState<string>('');
  const [facebook, setFacebook] = React.useState<string>('');
  const [instagram, setInstagram] = React.useState<string>('');
  const [ipfsImage, setIpfsImage] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  const [kaikasPrivateKey, setKaikasPrivateKey] = React.useState<string>('');
  const [isShowPopup, setIsShowPopup] = React.useState<boolean>(false);
  const [isAgree1, setIsAgree1] = React.useState<boolean>(false);
  const [isAgree2, setIsAgree2] = React.useState<boolean>(false);
  const [isAgree3, setIsAgree3] = React.useState<boolean>(false);
  const [isPartner, setIsPartner] = React.useState<boolean>(false);
  const [isMulti, setIsMulti] = React.useState<boolean>(false);
  const [amount, setAmount] = React.useState<number>(1);
  const [isVideo, setIsVideo] = React.useState<boolean>(false);

  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);
  const [createItemCategory, setCreateItemCategory] = useRecoilState(
    createItemCategoryAtom
  );

  const fileSizeCheck = (file: any) => {
    return file.size / 1024 / 1024;
  };

  const onDrop = React.useCallback((files: any) => {
    if (fileSizeCheck(files[0]) > 100) {
      setToast({
        type: 'danger',
        message: t('createItem.fileSizeCheck'),
        isShow: true,
      });
      return;
    }
    setBuffer(files[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const captureFile: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (fileSizeCheck(file) > 100) {
      setToast({
        type: 'danger',
        message: t('createItem.fileSizeCheck'),
        isShow: true,
      });
      return;
    }

    setBuffer(file);
  };

  const setBuffer = (file: any) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = () => {
      // @ts-ignore
      setCapturedFileBuffer(Buffer(reader.result));
      setIsVideo(false);
    };
  };

  const kaikasSign = () => {
    try {
      // @ts-ignore
      const keyring = new caver.wallet.keyring.singleKeyring(
        walletAccount.account,
        kaikasPrivateKey
      );

      // @ts-ignore
      caver.wallet.newKeyring(walletAccount.account, kaikasPrivateKey);

      setToast((prev) => ({
        ...prev,
        type: 'success',
        message: t('message.kaikasPrivateKeyAdded'),
        isShow: true,
      }));
      setIsShowModal(false);
    } catch (e: any) {
      console.log(JSON.stringify(e));
      // setToast((prev) => ({
      //   ...prev,
      //   type: 'danger',
      //   message: e,
      //   isShow: true,
      // }));
    }
  };

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            reset();
            setKlipRequestKey('');
          }
        }
      );
    },
    klipRequestKey ? 1000 : null
  );

  const klipMint = async (metadataAdded: any) => {
    const res = await prepare.executeContract({
      bappName: '싸이클럽',
      from: walletAccount.account,
      to: addresses.nft[8217],
      value: '0',
      abi: JSON.stringify({
        inputs: [
          {
            internalType: 'string',
            name: 'tokenURI',
            type: 'string',
          },
        ],
        name: 'mintNFT',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      }),
      params: JSON.stringify([metadataAdded.path]),
    });

    if (res.request_key) {
      if (isMobile) {
        request(res.request_key);
      }
      setKlipRequestKey(res.request_key);
    }
  };

  const validate = () => {
    if (!isPartner) {
      setToast((prev) => ({
        ...prev,
        type: 'danger',
        message: t('message.createItem.notPartner'),
        isShow: true,
      }));
      return;
    }
    if (!capturedFileBuffer) {
      setToast((prev) => ({
        ...prev,
        type: 'danger',
        message: t('message.createItem.error1'),
        isShow: true,
      }));
      return;
    }

    if (!title) {
      setToast((prev) => ({
        ...prev,
        type: 'danger',
        message: t('message.createItem.error2'),
        isShow: true,
      }));
      return;
    }
    if (!description) {
      setToast((prev) => ({
        ...prev,
        type: 'danger',
        message: t('message.createItem.error3'),
        isShow: true,
      }));
      return;
    }

    setIsShowPopup(true);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const fileAdded = await ipfs.add(capturedFileBuffer!);

      const metadata = {
        title: 'Asset Metadata',
        type: 'object',
        properties: {
          name: {
            type: 'string',
            description: title,
          },
          description: {
            type: 'string',
            description: description,
          },
          image: {
            type: 'string',
            description: `${ipfsUrl}${fileAdded.path}`,
          },
          category: {
            type: 'number',
            description: createItemCategory,
          },
          artist: {
            type: 'string',
            description: artist,
          },
          website: {
            type: 'string',
            description: website,
          },
          twitter: {
            type: 'string',
            description: twitter,
          },
          facebook: {
            type: 'string',
            description: facebook,
          },
          instagram: {
            type: 'string',
            description: instagram,
          },
          chainId: {
            type: 'number',
            description: walletAccount.chainId,
          },
        },
      };

      const metadataAdded = await ipfs.add(JSON.stringify(metadata));
      if (!metadataAdded) {
        setToast((prev) => ({
          ...prev,
          type: 'danger',
          message: t('message.createItem.error4'),
          isShow: true,
        }));
        return;
      }

      if (walletAccount.wallet === 'klip') {
        await klipMint(metadataAdded);
        return;
      } else if (walletAccount.kaikasProvider) {
        const isExisted = caver.wallet.isExisted(walletAccount.account);

        if (isExisted) {
          const mint = await new caver.contract(
            // @ts-ignore
            NFTCollection.abi,
            // @ts-ignore
            addresses.nft[walletAccount.chainId],
            { from: walletAccount.account, gas: 300000 }
          ).methods
            .mintNFT(metadataAdded.path)
            .send({
              from: walletAccount.account,
              gas: 300000,
            });
        } else {
          setIsShowModal(true);
          setIsLoading(false);
          return;
        }
      } else {
        if (isMulti) {
          await contracts
            .nftContract(walletAccount.chainId, walletAccount, isMulti)
            .methods.mintNFT(amount, metadataAdded.path)
            .send({
              from: walletAccount.account,
            });
        } else {
          await contracts
            .nftContract(walletAccount.chainId, walletAccount)
            .methods.mintNFT(metadataAdded.path)
            .send({
              from: walletAccount.account,
            });
        }
      }

      reset();
    } catch (e) {
      console.log(e);
    }
  };

  const reset = () => {
    setCapturedFileBuffer(null);
    setTitle('');
    setDescription('');
    setArtist('');
    setWebsite('');
    setTwitter('');
    setFacebook('');
    setInstagram('');
    setIsLoading(false);
    setIsShowPopup(false);
    setToast((prev) => ({
      ...prev,
      type: 'success',
      message: t('message.createItem.success1'),
      isShow: true,
    }));
  };

  const getIsPartner = async () => {
    const isPartner = await contracts
      .nftContract(walletAccount.chainId, walletAccount, isMulti)
      .methods.partnerAddress(walletAccount.account)
      .call();

    console.log(isPartner);

    setIsPartner(isPartner);
  };

  React.useEffect(() => {
    if (!walletAccount.account) {
      history.replace('/connectwallet');
    }
  }, [walletAccount]);

  React.useEffect(() => {
    getIsPartner();
  }, [isMulti]);

  return (
    <>
      <div className="contact_form">
        <div className="row">
          <div className="col-12">
            <div id="success_fail_info"></div>
          </div>
          <div className="col-12 col-md-12">
            <p className="w-text">{t('createItem.uploadItemFile')}</p>
            <p className="w-text" style={{ fontSize: 12 }}>
              <span className="text-danger">* </span>
              {t('createItem.requiredText')}
            </p>
            <div className="group-file" {...getRootProps()}>
              {capturedFileBuffer ? (
                <div className="mb-3">
                  {isVideo ? (
                    <video
                      src={`data:video/mp4;base64,${capturedFileBuffer.toString(
                        'base64'
                      )}`}
                      style={{ width: '100%' }}
                      autoPlay
                      loop
                      muted
                      playsInline
                    />
                  ) : (
                    <img
                      src={`data:image/png;base64,${capturedFileBuffer.toString(
                        'base64'
                      )}`}
                      alt=""
                      onError={() => setIsVideo(true)}
                    />
                  )}
                </div>
              ) : (
                <p className="g-text">
                  {isDragActive
                    ? t('createItem.uploadItemFileText1')
                    : t('createItem.uploadItemFileText2')}
                </p>
              )}
              <div className="new_Btn more-btn">
                {t('createItem.uploadFile')}
              </div>
              <br />
              <input
                type="file"
                name="upload"
                id="upload-btn"
                required
                onChange={captureFile}
                {...getInputProps()}
              />
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="mb-15">
              <p className="w-text">{t('createItem.tokenType')}</p>
              <div className="filers-list ">
                <button
                  className={`filter-item ${!isMulti ? 'active' : ''}`}
                  style={{ marginRight: 10 }}
                  onClick={() => setIsMulti(false)}
                >
                  {t('createItem.tokenType1')}
                </button>
                <button
                  className={`filter-item ${isMulti ? 'active' : ''}`}
                  style={{ marginRight: 10 }}
                  onClick={() => setIsMulti(true)}
                >
                  {t('createItem.tokenType2')}
                </button>
              </div>
              {isMulti && (
                <div className="group">
                  <input
                    type="number"
                    step={1}
                    min={1}
                    value={amount}
                    onChange={(e) => setAmount(parseInt(e.target.value))}
                    placeholder={t('createItem.amountPlaceholder')}
                  />
                  <span className="highlight"></span>
                  <span className="bar"></span>
                </div>
              )}
            </div>
            <div className="mb-15">
              <p className="w-text">{t('createItem.chooseItemCategory')}</p>
              <div className="filers-list ">
                {categories.map((category, i: number) => (
                  <button
                    key={i}
                    className={`filter-item ${
                      createItemCategory === i + 1 ? 'active' : ''
                    }`}
                    style={{ marginRight: 10 }}
                    onClick={() => setCreateItemCategory(i + 1)}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="group">
              <input
                type="text"
                name="name"
                id="name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                placeholder={t('createItem.itemNamePlaceholder')}
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              {/* <label>Item name</label> */}
            </div>
          </div>
          <div className="col-12">
            <div className="group">
              <textarea
                name="Description"
                id="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                placeholder={t('createItem.itemDescriptionPlaceholder')}
              ></textarea>
              <span className="highlight"></span>
              <span className="bar"></span>
              {/* <label>Item Description</label> */}
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="group">
              <input
                type="text"
                name="artist"
                id="artist"
                value={artist}
                onChange={(e) => setArtist(e.target.value)}
                required
                placeholder={t('createItem.artistNamePlaceholder')}
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              {/* <label>Item name</label> */}
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="group">
              <input
                type="text"
                name="website"
                id="website"
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                required
                placeholder={t('createItem.websitePlaceholder')}
                style={{ flex: 1 }}
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              {/* <label>Website</label> */}
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="group">
              <input
                type="text"
                name="twitter"
                id="twitter"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
                required
                placeholder={t('createItem.twitterPlaceholder')}
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              {/* <label>Item name</label> */}
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="group">
              <input
                type="text"
                name="facebook"
                id="facebook"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
                required
                placeholder={t('createItem.facebookPlaceholder')}
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              {/* <label>Item name</label> */}
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="group">
              <input
                type="text"
                name="instagram"
                id="instagram"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
                required
                placeholder={t('createItem.instagramPlaceholder')}
              />
              <span className="highlight"></span>
              <span className="bar"></span>
              {/* <label>Item name</label> */}
            </div>
          </div>
          {/* {CreateItemDataInput &&
              CreateItemDataInput.map((item, i) => (
                <div
                  key={i}
                  className={`col-12 ${item.fullWidth && 'col-md-12'} ${
                    item.hafWidth && 'col-md-6'
                  }`}
                >
                  <div className="group">
                    <input
                      type="text"
                      name={item.name}
                      id={item.name}
                      required
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label>{item.title}</label>
                  </div>
                </div>
              ))} */}
          <div className="col-12 text-center">
            <button
              type="button"
              disabled={isLoading}
              className="btn more-btn mb-15 mx-auto d-flex align-items-center justify-content-center"
              // onClick={onSubmit}
              onClick={validate}
            >
              {/* {isLoading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                t('createItem.createItemButton')
                )} */}
              {t('createItem.createItemButton')}
            </button>
          </div>
          {ipfsImage && (
            <div className="col-12 text-center">
              <img src={`https://ipfs.infura.io/ipfs/${ipfsImage}`} />
            </div>
          )}
        </div>
      </div>
      {isShowPopup && (
        <Popup>
          <div className="mask" onClick={() => setIsShowPopup(false)} />
          <div className="popup">
            <div className="title-wrap">
              <div className="title">K-POP NFT 서비스 이용약관</div>
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsShowPopup(false)}
              >
                <FaTimes />
              </button>
            </div>
            <PopupItem
              title="K-POP NFT 등록을 위한 정보 수집 및 이용 안내를 읽고 동의합니다."
              content={`정보 수집 및 이용안내
              K-POP NFT 서비스 정보수집 및 이용 안내 내용
              K-POP NFT서비스는 NFT 이름, 설명, 이미지 등의 파일에 대한 정보를 수집, 활용합니다.
              해당 정보의 수집 및 활용의 목적은 NFT 발행 및 관리입니다.
              NFT 등록에 있어 활용된 내용의 보유 및 이용기간은 영구합니다. 또한 규정을 위반할 시 NFT 삭제 및 파기할 수 있습니다.`}
              isAgree={isAgree1}
              setIsAgree={setIsAgree1}
            />
            <PopupItem
              title="K-POP NFT 위험요소에 대해 읽고 동의합니다."
              content={`Risk & Security
              유동성 자산을 교환하거나 거래하는 경우, 가능한 위험요소가 존재합니다.
              인터페이스에서 확인되는 모든 가상자산은 개별 심사없이 자율 등록된 이더리움/바이낸스/클레이튼 등 멀티체인 체인상의 토큰이며 안전성을 보증하지 않습니다. 사용자는 디지털 저작물 구매 및 판매 전 반드시 토큰 관련 정보를 신중히 확인한 후 거래해주시기 바랍니다.
              디지털 저작물 거래 시 토큰 가격 변동에 따라 사용자가 적용 받는 디지털 저작물 가격은 크게 변동할 수 있습니다.
              가격 변동으로 인해 사용자가 예상하지 못한 가격으로 출금될 수 있으며, 거래한 시점의 가격과 크게 차이가 발생하는 경우 일반적으로 가상자산 또는 저작물을 보유했을 때보다 손실이 발생할 수 있습니다.
              K-POP NFT에 대한 자세한 내용은 아래 유의사항을 확인해주시기 바랍니다.`}
              isAgree={isAgree2}
              setIsAgree={setIsAgree2}
            />
            <PopupItem
              title="K-POP NFT 이용 유의사항을 읽고 동의합니다."
              content={`유의사항
              [개요]
              K-POP NFT(https://kpopnft.net/)은 (주)싸이클럽(https://www.cyclub.io/)이 개발한 멀티체인 플랫폼으로 활용하는 탈중앙 어플리케이션(DApp)입니다. K-POP NFT의 사용자는 사이트를 통해 제공되는 편리한 인터페이스를 통해 자신이 소유한 멀티체인 블록체인 지갑을 탈중앙화 프로토콜과 직접 통신하여 여러 디지털 저작물의 거래 기능을 이용할 수 있습니다. K-POP NFT 사이트 이용 시 안정적인 네트워크 환경 조성을 위해 반드시 아래 유의사항을 확인해주시기 바랍니다.
              K-POP NFT 사이트 이용 시 안정적인 네트워크 환경 조성을 위해 반드시 아래 유의사항을 확인해주시기 바랍니다.
              사이트 이용 시 한 개의 탭을 사용하는 것을 권장합니다.
              여러 개의 탭으로 장시간 접속, 회사/공용 IP를 사용하는 장소에서 접속, RPC에 과도한 요청이 발생하는 경우 이용이 어려울 수 있습니다.
              이외 기타 원인으로 인하여 사이트 이용이 어려운 경우 사이트에 접속중인 모든 탭을 종료한 후 최소 1시간 초과 후 다시 사이트에 접속해주시기 바랍니다.
              
              본 유의사항 안내문의 내용은 사용자가 K-POP NFT를 사용하기 위해 반드시 알아 두어야 하는 사항을 포함하고 있으니 주의 깊게 읽어 주시기 바랍니다. 사용자가 본 안내문을 확인하고 K-POP NFT를 계속 사용할 경우, 본 안내문의 내용을 읽고 이해하고 동의한 것으로 인정됩니다.
              
              [NFT 거래]
              사용자는 K-POP NFT에서 디지털 저작물 거래 시 다음 사항에 유의하여야 합니다.
              디지털 저작물을 거래하기 위해서는 이더리움(ETH)/바이낸스코인(BSC)/클레이튼(KLAY) 자산을 다룰 수 있는 메타마스크/바이낸스/카이카스/카카오클립 지갑과, 멀티 체인에서 트랜잭션 처리 수수료로 사용할 약간의 자산(이더리움/바이낸스코인/클레이튼)을 보유하고 있어야 합니다.
              K-POP NFT에서 거래에 활용할 수 있는 자산은 멀티체인(이더리움/바이낸스/클레이튼) 상에서 발행된 호환 토큰(ERC-20/BEP-20 or BEP-2/KCT)형태를 따릅니다. 그러므로 멀티체인 호환 토큰 종류와 다른 이종 체인(리플 등 명시적으로 언급된 자산에 한정되지 않음)의 자산은 반드시 브릿지 사이트를 통해 멀티체인 호환 토큰 형태로 전환하여 거래해야 합니다.
              사용자는 K-POP NFT에서 디지털 저작물 거래를 진행할 때 자산의 종류와 주소를 정확히 확인하여야 합니다. 사용자가 멀티체인에서 지원하지 않는 종류의 자산으로 거래하거나, 트랜잭션 시 주소를 미입력하거나 오입력할 경우 정상 처리가 되지 않아 자산 또는 디지털 저작물이 유실될 수 있습니다.
              K-POP NFT는 사용자 자산의 개인 키를 중앙에서 관리하지 않는 탈중앙형 인터페이스로서, 위와 같은 상황을 포함한 사용자의 오류에 대해 K-POP NFT에서의 자산의 복구 및 기술적 지원이 불가능한 구조로 되어 있습니다. 이 구조는 사용자가 오류를 범할 경우 해당 자산 및 디지털 저작물에 대해 K-POP NFT를 비롯한 어느 누구도 접근할 수 없게 설계되어 있으며, 이 때문에 K-POP NFT의 오류 대응은 다른 중앙형 거래소(Upbit, Bithumb 등)에서의 오입금 대응방식과는 기술적, 구조적인 사유로 인하여 동일하지 않을 수 있습니다.
              K-POP NFT는 입금주소 노출 시 유의사항 고지를 비롯하여 사용자의 오류를 예방하기 위한 인터페이스를 구현하기 위해 최선의 노력을 다하고 있으며 K-POP NFT가 추구하는 탈중앙의 가치를 유지하면서 이 문제를 개선할 방법을 찾기 위해 계속 개발자 커뮤니티와 함께 고민해 나갈 예정입니다.
              
              K-POP NFT 페이지 내 노출되는 모든 콘텐츠 정보는 NFT 발행인이 제공하고 있으며, 투자 또는 거래의 권유를 목적으로 하지 않습니다. 
              K-POP NFT는 K-POP NFT 거래 중개에 관한 홍보 목적으로 NFT에 연계된 디지털 저작물을 이용할 수 있습니다.
              K-POP NFT에서 배포 및 거래되는 모든 NFT에 대하여, 각 NFT의 발행인, 거래 일시, 거래 당사자인 회원의 지갑주소, 거래 금액, 현재 판매 여부 및 판매 금액이 서비스 화면 내 전부 또는 일부 공개될 수 있습니다.
              
              [책임의 제한]
              K-POP NFT는 사용자가 자신이 소유한 블록체인 지갑을 탈중앙화 프로토콜과 직접 통신하여 여러 가상자산의 교환 기능을 이용할 수 있도록 돕는 웹사이트 형태의 인터페이스로서 사용자의 개인 자산이나 디지털 저작물을 관리 또는 통제하거나 거래의 무결성 및 신뢰성을 보증하지 않습니다. 사용자는 각 거래의 이점 및 토큰 가격, 교환비율의 변동 등에 의한 손실의 위험에 대해 신중히 평가, 고려한 후 자신의 책임과 판단 하에 거래를 진행하여야 합니다.
              K-POP NFT와 K-POP NFT에 연결된 사이트는 독자적으로 운영되며 연결 사이트와 사용자 간에 행해진 거래에 대하여는 양 당사자 간에 해결하도록 하며 K-POP NFT는 관여하지 아니합니다. 단, 회사의 귀책사유가 입증된 경우에는 그러하지 아니합니다.
              K-POP NFT는 사용자가 K-POP NFT를 이용하여 기대하는 이익을 얻지 못하거나 거래에 내재한 위험으로 인해 자산 또는 재산의 손실을 입은 것에 대하여 책임을 지지 않습니다.
              K-POP NFT는 K-POP NFT에서 사용되는 기능과 서비스의 지속성을 보장하지 않습니다. K-POP NFT에서 제공되는 기능은 사전에 공지된 기일에 추가되거나 영구적으로 삭제될 수 있으며 K-POP NFT 서비스가 중단되거나 변경될 수 있습니다. (영구적인 기능 삭제나 서비스 중단의 경우 이로 인해 영향을 받는 사용자가 있는 경우에는 사전에 30일 이상의 합리적인 기간을 두고 사용자의 대처방법에 대해 공지 및 안내할 예정입니다.) 회원은 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를 표시하고 이용계약을 해지할 수 있습니다.
              K-POP NFT는 K-POP NFT 프로토콜이 사용하는 이더리움/바이낸스/클레이튼 블록체인 플랫폼을 비롯한 제3자 서비스에 대해 책임을 지지 않습니다.
              K-POP NFT 프로토콜의 개발 및 업데이트는 분산화 된 투표 또는 합의 방식에 의한 거버넌스를 추구하며, K-POP NFT 프로토콜의 개발자 및 거버넌스 참여자는 법령이 허용하는 한도 내에서 관련 프로토콜, 코드, 서비스 등에 대해 어떠한 직간접적인 약정, 보증을 하지 아니합니다.
              K-POP NFT는 안전하고 투명한 디지털 저작물 거래 기회를 제공하기 위해 자동화된 스마트 컨트랙트 코드를 통해 이더리움/바이낸스/클레이튼 블록체인과 직접 상호작용이 가능하도록 설계된 애플리케이션이지만, 소프트웨어의 복잡성과 기술의 본질적인 한계로 인해 코드의 무결성을 완벽하게 보장할 수 없습니다.
              
              K-POP NFT에서 사용하는 스마트 컨트랙트 코드에는 발견되지 못한 결함이 존재할 수 있으며, 이러한 결함을 악용한 해킹, 플래시론 공격 등을 통한 자산 탈취로 인한 원금 손실의 위험이 존재합니다.
              * 현재 K-POP NFT 팀은 보안 취약점을 제거하고 예방하기 위한 합리적인 노력을 다하고 있습니다.
              K-POP NFT 팀은 최신 블록 동기화를 유지할 수 있도록 최선의 노력을 하고 있으나, 천재지변, 서버점검 또는 블록체인 상의 트랜잭션 혼잡도 상승, 컴퓨터 시스템 오류, 소프트웨어 공격 등의 상황으로 K-POP NFT 인터페이스 제공은 예고 없이 중단/종료될 수 있으며, 24시간 K-POP NFT 인터페이스 제공이 보장되지 않습니다.
              위와 같은 문제 상황에서는 인터페이스 상의 표시된 수치 상의 오류, 일시적인 서비스 연결 불가, 접속오류 및 중지가 발생할 수 있습니다. 이때 사용자가 요청한 트랜잭션이 처리 과정에서 지연 및 요청 실패가 발생할 수 있습니다.
              법령상 허용되는 한도 내에서 위 두 단락과 같은 상황의 발생에 대해 K-POP NFT의 고의 또는 과실이 없는 경우 K-POP NFT는 이에 대해 책임을 지지 아니합니다.
              천재지변, 귀사의 책임 없는 서비스 장애, 관리할 수 없는 서버의 장애, 정부기관의 규정 준수 등에 대하여 귀사가 필요한 주의를 다한 경우에도 불구하고 발생한 회원의 손해에 대하여는 책임을 지지 않습니다.
              
              [NFT 소유자의 권리]
              NFT를 보유한 자에게는 "NFT 소유자의 권리"의 내용에 따라 NFT와 연계된 디지털 저작물을 이용할 수 있는 권리만이 부여됩니다. NFT는 저작권, 상표권을 포함한 지식재산권이나 초상권, 상품화 권리 등의 특정 권리 그 자체를 의미하지 않으며, NFT를 보유하는 것이 NFT와 연계된 디지털 저작물에 대한 보유 내지 디지털 저작물에 관한 저작권 등의 권리를 취득하는 것을 의미하지 않습니다.
              "NFT 소유자의 권리"는 NFT를 적법하게 보유하고 있는 동안에만 행사할 수 있으며, "NFT 소유자의 권리"는 권리를 행사하는 시점에 해당 NFT를 보유한 자에게 인정됩니다. 타인에게 NFT를 이전한 경우, NFT를 양도한 회원은 "NFT 소유자의 권리"를 더 이상 행사할 수 없습니다. K-POP NFT가 공식적으로 지원하는 방식이 아닌 방식 및/또는 관련 법령을 위반하는 방식으로 NFT를 이전 받은 자에게는 "NFT 소유자의 권리"가 보장되지 않을 수 있습니다.
              
              [사용자의 의무]
              사용자가 서비스를 이용할 때 아래 각 호의 행위는 하여서는 안 됩니다.
              타인의 정보도용
              회사가 게시한 정보의 변경
              회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등)등의 송신 또는 게시
              회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해
              회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
              외설 또는 폭력적인 메시지, 화상, 음성, 허위사실, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위
              회사의 사전 동의 없이 영리를 목적으로 서비스를 사용하는 행위
              회사의 사전 승낙 없이 에이전트(Agent), 스크립트(Script), 스파이더(Spider), 스파이웨어(Spyware), 툴바(Toolbar) 등의 자동화된 수단, 기타 부정한 방법을 통하여 서비스에 접속하는 행위, 노출횟수 및 클릭횟수를 부정하게 생성하거나 증가시키는 행위, 서비스 이용 신청을 하는 행위, 회사의 서버에 부하를 야기하는 행위
              다른 사용자의 개인정보 및 계정정보를 수집하는 행위
              가상자산의 시세에 부당한 영향을 주는 등의 방법으로 건전한 거래질서를 교란하는 행위
              기타 불법적이거나 부당한 행위
              사용자는 관계 법령, 이 약관 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
              회사는 이 약관 외에 별도의 운영정책을 둘 수 있습니다.
              
              [디지털 저작물 관련 이의제기]
              K-POP NFT를 통하여 유통되는 NFT 및 해당 NFT에 연계된 "디지털 저작물"에 의하여 본인의 저작권 기타 권리가 침해된다고 주장하는 자(이하 "권리주장자")는 해당 사실을 소명하여 K-POP NFT에 해당 NFT의 이용 및 유통을 중단할 것을 요청할 수 있습니다.
              K-POP NFT는 제1항에 따라 NFT의 이용 및 유통 중단을 요구받은 경우에는 즉시 해당 NFT에 대한 "NFT 소유자의 권리" 행사를 중단하고, 권리주장자, NFT를 보유하고 있는 회원 및 NFT 발행인에게 해당 사실을 통보하여야 합니다.
              제2항에 따라 통보를 받은 NFT 발행인이 자신에게 정당한 권리가 있음을 소명하면서 NFT의 이용 및 유통 재개를 요구하는 경우, K-POP NFT는 재개요구사실 및 재개예정일을 권리주장자와 NFT를 보유하고 있는 회원에게 지체없이 통보하고 해당 예정일에 "NFT 소유자의 권리"를 재개합니다. 다만, 권리주장자가 NFT 발행인의 침해행위에 대하여 소를 제기한 사실을 재개예정일 전에 K-POP NFT에 통보한 경우에는 그러하지 않습니다.
              특정 NFT와 이에 연계된 디지털 저작물에 관한 권리 및/또는 "NFT IP" 관련하여 제3자가 권리 침해를 주장하는 경우, K-POP NFT는 해당 NFT의 거래 중개 등 서비스 제공을 중단하고, 지체없이 권리주장자와 "NFT 발행인"에게 해당 사실을 알릴 것입니다. K-POP NFT는 해당 문제의 해결을 위하여 권리주장자와 "NFT 발행인"간 협의를 주선하여 관련 분쟁이 조속히 해결되도록 노력할 것입니다. K-POP NFT는 관련 분쟁이 종결된 후 중단되었던 NFT 거래 중개를 재개합니다. NFT 발행인과 권리주장자 사이의 분쟁이 해결되지 않아 해당 NFT를 보유하고 있는 회원에게 손해가 발생하더라도 K-POP NFT는 이에 대하여 책임지지 않습니다. 단, K-POP NFT의 고의 또는 과실로 인하여 손해가 발생한 경우에는 법령에서 정한 범위 내에서 K-POP NFT가 책임을 부담합니다.
              
              이 서비스 유의사항은 2022년 1월 12일부터 적용 및 게시됩니다.
              
              서비스 유의사항 변경 및 게시이력
              (2022년 1월 12일): 최초 제정`}
              isAgree={isAgree3}
              setIsAgree={setIsAgree3}
            />
            <button
              type="button"
              disabled={isLoading || !isAgree1 || !isAgree2 || !isAgree3}
              className="btn more-btn mt-5 mx-auto d-flex align-items-center justify-content-center"
              onClick={onSubmit}
            >
              {isLoading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                t('createItem.createItemButton')
              )}
            </button>
          </div>
          <FaCheck />
        </Popup>
      )}
      <Modal show={isShowModal} onHide={() => setIsShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Kaikas privakey</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="password"
            className="mb-3"
            placeholder="Kaikas private key"
            value={kaikasPrivateKey}
            onChange={(e) => setKaikasPrivateKey(e.target.value)}
          />
          <Button type="button" className="w-100" onClick={kaikasSign}>
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
  }
  .popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 750px;
    max-height: 80%;
    width: 90%;
    background-color: white;
    border-radius: 10px;
    padding: 30px;
    overflow-y: scroll;
    & > .title-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;
      & > .title {
        font-size: 20px;
        font-weight: bold;
      }
      .btn-close {
        width: 35px;
        height: 35px;
        border-radius: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        background-color: #422a77;
        color: white;
        background-image: none;
      }
    }
    @media (max-width: 767px) {
      padding: 20px;
      .btn-close {
        top: 20px;
        right: 20px;
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }
    }
  }
`;

interface IPopupItem {
  title: string;
  content: string;
  isAgree: boolean;
  setIsAgree: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledPopupItem = styled.div`
  &:nth-child(n + 1) {
    margin-top: 35px;
  }
  .top {
    display: flex;
    align-items: center;
    .check-wrap {
      width: 24px;
      height: 24px;
      border: 2px solid gray;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.checked {
        background-color: #5c33f6;
        border: 0;
      }
    }
    .title-wrap {
      flex: 1;
      display: flex;
      align-items: center;
      .title {
        flex: 1;
        padding: 0 10px;
      }
    }
  }
  .content {
    margin-top: 10px;
    background-color: #fafafa;
    padding: 15px;
    border-radius: 10px;
    max-height: 150px;
    overflow-y: scroll;
    white-space: pre-line;
    font-size: 14px;
  }
  @media (max-width: 767px) {
    &:nth-child(n + 1) {
      margin-top: 20px;
    }
  }
`;

const PopupItem: React.FC<IPopupItem> = React.memo((props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <StyledPopupItem>
      <div className="top">
        <div
          className={`check-wrap ${props.isAgree ? 'checked' : ''}`}
          onClick={() => props.setIsAgree(!props.isAgree)}
        >
          <FaCheck color="white" />
        </div>
        <div className="title-wrap" onClick={() => setIsOpen(!isOpen)}>
          <div className="title">{props.title}</div>
          {isOpen ? (
            <FaChevronUp color="gray" />
          ) : (
            <FaChevronDown color="gray" />
          )}
        </div>
      </div>
      {isOpen && <div className="content">{props.content}</div>}
    </StyledPopupItem>
  );
});

export default ContactForm;
