import Web3 from 'web3';

// Web 3 connection
const web3: Web3 = new Web3(Web3.givenProvider) 
export const web3Main: Web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'))
export const web3Rinkeby: Web3 = new Web3(new Web3.providers.HttpProvider('https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'))
export const web3BSC: Web3 = new Web3(new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/'))
export const web3BSCTest: Web3 = new Web3(new Web3.providers.HttpProvider('https://data-seed-prebsc-1-s1.binance.org:8545'))
export const web3Baobab: Web3 = new Web3(new Web3.providers.HttpProvider('https://api.baobab.klaytn.net:8651'))
export const web3Cypress: Web3 = new Web3(new Web3.providers.HttpProvider('https://node-api.klaytnapi.com/v1/klaytn'))

export const web3ByChainId = (chainId: number) => {
  switch (chainId) {
    case 1: return web3Main;
    case 4: return web3Rinkeby;
    case 56: return web3BSC;
    case 97: return web3BSCTest;
    case 1001: return web3Baobab;
    case 8217: return web3Cypress;
    default: return web3
  }
}

export default web3;