import Breadcumb from '../../components/Breadcumb';
import LiveAuctions from '../../components/LiveAuctions';
import { useTranslation } from 'react-i18next';
import InfoComponent from '../../components/InfoComponent';

const AuctionsContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <Breadcumb
        namePage={t('auction.breadcumbNamePage')}
        title={t('auction.breadcumbTitle')}
      /> */}
      <div className="breadcumb-area clearfix">
        <div className="breadcumb-content">
          <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-12">
                <nav
                  aria-label="breadcrumb"
                  className="breadcumb--con text-center"
                >
                  <InfoComponent
                    titleSm={t('auction.infoTitleSm')}
                    titleLg={t('auction.infoTitleLg')}
                    text={t('auction.infoText')}
                  />
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LiveAuctions />
    </>
  );
};

export default AuctionsContainer;
