import React from 'react';
import { FaPen } from 'react-icons/fa';
import styled from 'styled-components';
import { useRecoilState, useRecoilValue } from 'recoil';
import myProfileAtom from '../../atoms/myProfile';
import { getShortAddress, zeroAddress } from '../../utils';
import toastAtom from '../../atoms/toast';
import {
  getWalletDetail,
  checkUserName,
  setProfile,
  api,
} from '../../utils/api';
import walletAccountAtom from '../../atoms/walletAccount';
import web3 from '../../connection/web3';
// @ts-ignore
import Web3Token from 'web3-token';
import { IProfile } from '../../types/index';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import { create } from 'ipfs-http-client';
import { useCopyToClipboard, useInterval } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import klipRequestKeyAtom from '../../atoms/klipRequestKey';

const ipfs = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https',
});

const BackgroundImageWrap = styled.div`
  aspect-ratio: 16 / 9;
  background-color: gray;
  background-size: cover;
  background-position: center;
  position: relative;
  & > .edit-wrap {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    input[type='file'] {
      display: none;
    }
  }
  &:hover > .edit-wrap {
    display: flex;
  }
`;

const ProfileImageWrap = styled.span`
  background-color: white;
  & > .edit-wrap {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    input[type='file'] {
      display: none;
    }
  }
  &:hover > .edit-wrap {
    display: flex;
  }
`;

interface ICollectionInfo {
  isEditMode?: boolean;
}

const CollectionInfo = styled.div<ICollectionInfo>`
  position: relative;
  .btn-edit {
    display: ${({ isEditMode }) => (isEditMode ? 'none' : 'block')};
    position: absolute;
    top: 0;
    right: 20px;
    border: 0;
    padding: 10;
    background-color: transparent;
    color: white;
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  gap: 15px;
  & > button {
    flex: 1;
    min-width: auto;
  }
`;

function PartProfile() {
  const { t } = useTranslation();
  const [copiedText, setCopiedText] = useCopyToClipboard();
  const [myProfile, setMyProfile] = useRecoilState(myProfileAtom);
  const [toast, setToast] = useRecoilState(toastAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);
  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);

  const [isLoadingKlip, setIsLoadingKlip] = React.useState<boolean>(false);

  const backgroundImageRef = React.useRef();
  const profileImageRef = React.useRef();

  const [inputs, setInputs] = React.useState<IProfile>({
    username: '',
    email: '',
    bio: '',
    twitter: '',
    instagram: '',
    facebook: '',
    site: '',
    img: '',
    name: '',
    phone: '',
    address: '',
  });
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);

  const captureBackgroundImage: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    event.preventDefault();

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = async () => {
      // @ts-ignore
      const fileAdded = await ipfs.add(Buffer(reader.result));

      const data = {
        username: myProfile.username,
        email: myProfile.email,
        bio: myProfile.bio,
        twitter: myProfile.twitter,
        instagram: myProfile.instagram,
        facebook: myProfile.facebook,
        site: myProfile.site,
        img: myProfile.img,
        bgImg: `https://ipfs.infura.io/ipfs/${fileAdded.path}`,
        name: myProfile.name,
        phone: myProfile.phone,
        address: myProfile.address,
      };

      try {
        const { token, type } = await getTokenType();

        if (walletAccount.wallet === 'klip') {
          setInputs(data);
          setIsLoadingKlip(true);
          return;
        }

        await setProfile(token, type, data);
        setMyProfile((prev) => ({ ...prev, ...data }));
        setToast({
          type: 'success',
          isShow: true,
          message: t('collection.backgroundImageUpdateSuccess'),
        });
      } catch (e) {
        setToast({
          type: 'danger',
          isShow: true,
          message: t('collection.backgroundImageUpdateFail'),
        });
      } finally {
        setIsEditMode(false);
      }
    };
  };

  const captureProfileImage: React.ChangeEventHandler<HTMLInputElement> = (
    event: any
  ) => {
    event.preventDefault();

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onloadend = async () => {
      // @ts-ignore
      const fileAdded = await ipfs.add(Buffer(reader.result));

      const data = {
        username: myProfile.username,
        email: myProfile.email,
        bio: myProfile.bio,
        twitter: myProfile.twitter,
        instagram: myProfile.instagram,
        facebook: myProfile.facebook,
        site: myProfile.site,
        img: `https://ipfs.infura.io/ipfs/${fileAdded.path}`,
        bgImg: myProfile.bgImg,
        name: myProfile.name,
        phone: myProfile.phone,
        address: myProfile.address,
      };

      try {
        const { token, type } = await getTokenType();

        if (walletAccount.wallet === 'klip') {
          setIsLoadingKlip(true);
          setInputs(data);
          return;
        }

        await setProfile(token, type, data);
        setMyProfile((prev) => ({ ...prev, ...data }));
        setToast({
          type: 'success',
          isShow: true,
          message: t('collection.profileImageUpdateSuccess'),
        });
      } catch (e) {
        setToast({
          type: 'danger',
          isShow: true,
          message: t('collection.profileImageUpdateFail'),
        });
      } finally {
        setIsEditMode(false);
      }
    };
  };

  const onChangeInput: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = React.useCallback((e) => {
    const { name, value } = e.target;

    setInputs((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onClickSave = React.useCallback(async () => {
    try {
      if (!myProfile.username) {
        const checkUserNameValue = await checkUserName(inputs.username);

        if (checkUserNameValue) {
          setToast({
            type: 'danger',
            isShow: true,
            message: t('collection.usedName'),
          });
          return;
        }
      }

      const { token, type } = await getTokenType();

      if (walletAccount.wallet === 'klip') {
        setIsLoadingKlip(true);
        return;
      }

      await setProfile(token, type, inputs);
      setMyProfile((prev) => ({ ...prev, ...inputs }));
      setIsEditMode(false);
      setToast({
        type: 'success',
        isShow: true,
        message: t('collection.profileUpdateSuccess'),
      });
    } catch (e) {
      setToast({
        type: 'danger',
        isShow: true,
        message: t('collection.profileageUpdateFail'),
      });
    }
  }, [myProfile, inputs]);

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        async (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            await setProfile(result.request_key, 'klaytn', inputs);
            setMyProfile((prev) => ({ ...prev, ...inputs }));
            setKlipRequestKey('');
            setIsEditMode(false);
            setToast({
              type: 'success',
              isShow: true,
              message: t('collection.profileUpdateSuccess'),
            });
          }
        }
      );
    },
    isLoadingKlip && klipRequestKey ? 1000 : null
  );

  const getTokenType = async () => {
    let token: string, type: 'web3' | 'klaytn';

    if (walletAccount.wallet !== 'klip') {
      token = await Web3Token.sign(
        (msg: any) => {
          return web3.eth.personal.sign(msg, walletAccount.account!, '');
        },
        {
          domain: 'kpopnft.net',
          statement: '',
          not_before: new Date(Date.now() - 24 * 60 * 60 * 1000),
        }
      );
      type = 'web3';
    } else {
      const res = await prepare.auth({ bappName: '싸이클럽' });
      console.log('res', res);
      token = res.request_key;
      type = 'klaytn';

      if (res.request_key) {
        if (isMobile) {
          request(res.request_key);
        }
        setKlipRequestKey(res.request_key);
      }
    }

    return { token, type };
  };

  React.useEffect(() => {
    if (isEditMode) {
      setInputs({
        username: myProfile.username,
        email: myProfile.email,
        bio: myProfile.bio,
        twitter: myProfile.twitter,
        instagram: myProfile.instagram,
        facebook: myProfile.facebook,
        site: myProfile.site,
        img: myProfile.img,
        bgImg: myProfile.bgImg,
        name: myProfile.name,
        phone: myProfile.phone,
        address: myProfile.address,
      });
    } else {
      setInputs({
        username: '',
        email: '',
        bio: '',
        twitter: '',
        instagram: '',
        facebook: '',
        site: '',
        img: '',
        bgImg: '',
        name: '',
        phone: '',
        address: '',
      });
    }
  }, [myProfile, isEditMode]);

  const walletDetail = async () => {
    try {
      const { type, token } = await getTokenType();
      const detail = await api(
        `/profile/${walletAccount.account}/detail`,
        'GET',
        undefined,
        {
          Authorization: token,
          'access-type': type,
          'Content-Type': 'application/json',
        }
      );

      setMyProfile(detail);
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    walletDetail();
  }, [walletAccount]);

  return (
    <div className="col-12 col-lg-3">
      <div className="service_single_content collection-item">
        <BackgroundImageWrap
          className="collection_icon"
          style={{
            backgroundImage: myProfile.bgImg
              ? `url(${myProfile.bgImg})`
              : 'none',
          }}
        >
          <div
            className="edit-wrap"
            // @ts-ignore
            onClick={() => backgroundImageRef.current.click()}
          >
            <input
              // @ts-ignore
              ref={backgroundImageRef}
              type="file"
              onChange={captureBackgroundImage}
            />
            <FaPen color="white" />
          </div>
        </BackgroundImageWrap>
        <ProfileImageWrap className="aut-info">
          {myProfile.img && <img src={myProfile.img} width="50" alt="" />}
          <div
            className="edit-wrap"
            // @ts-ignore
            onClick={() => profileImageRef.current.click()}
          >
            <input
              // @ts-ignore
              ref={profileImageRef}
              type="file"
              onChange={captureProfileImage}
            />
            <FaPen color="white" />
          </div>
        </ProfileImageWrap>
        <CollectionInfo
          className="collection_info text-center"
          isEditMode={isEditMode}
        >
          <button
            type="button"
            className="btn-edit"
            onClick={() => setIsEditMode(true)}
          >
            <FaPen />
          </button>
          {isEditMode ? (
            !myProfile.username && (
              <div className="group mb-3">
                <input
                  name="username"
                  value={inputs.username}
                  placeholder={t('collection.namePlaceholder')}
                  onChange={onChangeInput}
                />
              </div>
            )
          ) : (
            <h6>{myProfile.username || t('collection.noName')}</h6>
          )}
          {isEditMode ? (
            !myProfile.email && (
              <div className="group mb-3">
                <input
                  name="email"
                  value={inputs.email}
                  placeholder={t('collection.emailPlaceholder')}
                  onChange={onChangeInput}
                />
              </div>
            )
          ) : (
            <p className="w-text mr-5p">
              {myProfile.email || t('collection.noEmail')}
            </p>
          )}
          {isEditMode ? (
            <div className="group">
              <textarea
                name="bio"
                value={inputs.bio}
                placeholder={t('collection.bioPlaceholder')}
                onChange={onChangeInput}
              />
            </div>
          ) : (
            <p className="mt-15">{myProfile.bio || t('collection.noBio')}</p>
          )}

          {!isEditMode && (
            <div className="search-widget-area mt-15">
              <form action="#" method="post">
                <input
                  type="text"
                  name="wallet"
                  id="wallet"
                  value={getShortAddress(
                    myProfile.wallet || walletAccount.account
                  )}
                />
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    myProfile.wallet && setCopiedText(myProfile.wallet);
                    setToast({
                      type: 'success',
                      isShow: true,
                      message: t('collection.copiedToClipboard'),
                    });
                  }}
                >
                  <i className="fa fa-copy"></i>
                </button>
              </form>
            </div>
          )}

          {isEditMode && (
            <>
              <div className="group mb-3">
                <input
                  name="twitter"
                  value={inputs.twitter}
                  placeholder={t('collection.twitterPlaceholder')}
                  onChange={onChangeInput}
                />
              </div>
              <div className="group mb-3">
                <input
                  name="instagram"
                  value={inputs.instagram}
                  placeholder={t('collection.instagramPlaceholder')}
                  onChange={onChangeInput}
                />
              </div>
              <div className="group mb-3">
                <input
                  name="facebook"
                  value={inputs.facebook}
                  placeholder={t('collection.facebookPlaceholder')}
                  onChange={onChangeInput}
                />
              </div>
              <div className="group mb-3">
                <input
                  name="site"
                  value={inputs.site}
                  placeholder={t('collection.sitePlaceholder')}
                  onChange={onChangeInput}
                />
              </div>
            </>
          )}

          {!isEditMode && (
            <ul className="social-links mt-30 mb-30">
              {myProfile.twitter && (
                <li>
                  <a href={myProfile.twitter} target="_blank" rel="noreferrer">
                    <span className="fa fa-twitter"></span>
                  </a>
                </li>
              )}
              {myProfile.instagram && (
                <li>
                  <a
                    href={myProfile.instagram}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fa fa-instagram"></span>
                  </a>
                </li>
              )}
              {myProfile.facebook && (
                <li>
                  <a href={myProfile.facebook} target="_blank" rel="noreferrer">
                    <span className="fa fa-facebook-f"></span>
                  </a>
                </li>
              )}
              {myProfile.site && (
                <li>
                  <a href={myProfile.site} target="_blank" rel="noreferrer">
                    <span className="fa fa-link"></span>
                  </a>
                </li>
              )}
            </ul>
          )}

          {isEditMode && (
            <ButtonWrap>
              <button
                type="button"
                className="more-btn"
                onClick={() => setIsEditMode(false)}
              >
                {t('collection.cancel')}
              </button>
              <button type="button" className="more-btn" onClick={onClickSave}>
                {t('collection.save')}
              </button>
            </ButtonWrap>
          )}
          {/* <a href="profile.html" className="more-btn">
              Follow
            </a> */}
        </CollectionInfo>
      </div>
    </div>
  );
}

export default PartProfile;
