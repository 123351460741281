import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { IProfile } from '../types';

const { persistAtom } = recoilPersist();

export interface IMyProfile extends IProfile {
  signed?: boolean;
}

export const initialMyProfile: IMyProfile = {
  pId: 0,
  username: '',
  email: '',
  img: '',
  bgImg: '',
  bio: '',
  twitter: '',
  instagram: '',
  facebook: '',
  site: '',
  wallet: '',
  uId: 0,
  name: '',
  phone: '',
  address: '',
  updatedAt: '',
  createdAt: '',
};

const myProfileAtom = atom<IMyProfile>({
  key: 'myProfile',
  default: initialMyProfile,
  effects_UNSTABLE: [persistAtom],
});

export default myProfileAtom;
