import React from "react";

function HomeAuctionsItemPlaceholder() {
    return (
        <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="pricing-item">
                <div className="wraper placeholder-glow">
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            width: '100%',
                            height: '100%',
                            aspectRatio: '1',
                            backgroundColor: `white')`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            borderRadius: 10,
                        }}
                    >
                        <div className="spinner-border"
                             style={{width: "20%", height: "20%"}}/>
                    </div>
                    <h4 className="d-flex align-items-center justify-content-between placeholder-glow w-100">
                        <div className="placeholder w-100"/>
                    </h4>
                    <span className="w-100" style={{marginBottom: "30px"}}>
                        <span className="g-text placeholder-glow w-75">
                            <div className="placeholder w-100"/>
                        </span>
                        <span className="g-text placeholder-glow w-50">
                            <div className="placeholder w-100"/>
                        </span>
                    </span>
                    <button
                        className="btn more-btn d-block w-100 placeholder">
                    </button>
                </div>
            </div>
        </div>
    );
}

export default HomeAuctionsItemPlaceholder;