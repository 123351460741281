import BigNumber from 'bignumber.js';
import moment from 'moment';
import * as React from 'react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import isApprovedAtom from '../../../atoms/isApproved';
import isApproved1155Atom from '../../../atoms/isApproved1155';
import isAuctionFinishAtom from '../../../atoms/isAuctionFinish';
import addresses from '../../../constants/addresses';
import contracts from '../../../constants/contracts';
import { IItem } from '../../../types';
import walletAccountAtom from '../../../atoms/walletAccount';
import toastAtom from '../../../atoms/toast';
import caver, { caverAPI } from '../../../connection/caver';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import klipRequestKeyAtom from '../../../atoms/klipRequestKey';
import { isMobile } from 'react-device-detect';
import NFTMarketCollection from '../../../abis/NAMarketV2.json';
import web3 from '../../../connection/web3';
import { useInterval } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { CreateAuctionABI } from '../../../utils/abis';

interface IMyItemProps extends IItem {}

// TODO: 경매 생성 시 밀리초 -> 초로 변경 필요
const MyItem: React.FunctionComponent<IMyItemProps> = (props) => {
  const { t } = useTranslation();

  const [isApproved, setIsApproved] = useRecoilState(isApprovedAtom);
  const [isApproved1155, setIsApproved1155] =
    useRecoilState(isApproved1155Atom);
  const [isCreateOpen, setIsCreateOpen] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [startingPrice, setStartingPrice] = React.useState<number>();
  const [buyNowPrice, setBuyNowPrice] = React.useState<number>();
  const [expiryDate, setExpiryDate] = React.useState<string>('');
  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  const [kaikasPrivateKey, setKaikasPrivateKey] = React.useState<string>('');
  const [isVideo, setIsVideo] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const setIsAuctionFinish = useSetRecoilState(isAuctionFinishAtom);
  const setToast = useSetRecoilState(toastAtom);
  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);

  const kaikasSign = () => {
    try {
      // @ts-ignore
      const keyring = new caver.wallet.keyring.singleKeyring(
        walletAccount.account,
        kaikasPrivateKey
      );

      // @ts-ignore
      caver.wallet.newKeyring(walletAccount.account, kaikasPrivateKey);

      setToast((prev) => ({
        ...prev,
        type: 'success',
        message: t('message.kaikasPrivateKeyAdded'),
        isShow: true,
      }));
      setIsShowModal(false);
    } catch (e: any) {
      console.log(JSON.stringify(e));
      // setToast((prev) => ({
      //   ...prev,
      //   type: 'danger',
      //   message: e,
      //   isShow: true,
      // }));
    }
  };

  const onClickCreateAuction = async () => {
    if (!isCreateOpen) {
      setIsCreateOpen(true);
      return;
    }

    if (!title) {
      setToast((prev) => ({
        ...prev,
        type: 'danger',
        message: t('message.myItem.error1'),
        isShow: true,
      }));
      return;
    }
    if (!props.is1155 && !startingPrice) {
      setToast((prev) => ({
        ...prev,
        type: 'danger',
        message: t('message.myItem.error2'),
        isShow: true,
      }));
      return;
    }
    if (!buyNowPrice) {
      setToast((prev) => ({
        ...prev,
        type: 'danger',
        message: t('message.myItem.error3'),
        isShow: true,
      }));
      return;
    }
    if (!props.is1155 && startingPrice && startingPrice >= buyNowPrice) {
      setToast((prev) => ({
        ...prev,
        type: 'danger',
        message: t('message.myItem.error4'),
        isShow: true,
      }));
      return;
    }
    if (!props.is1155 && startingPrice && startingPrice < 0.001) {
      setToast((prev) => ({
        ...prev,
        type: 'danger',
        message: t('message.myItem.error5'),
        isShow: true,
      }));
      return;
    }
    if (!expiryDate) {
      setToast((prev) => ({
        ...prev,
        type: 'danger',
        message: t('message.myItem.error6'),
        isShow: true,
      }));
      return;
    }

    if (walletAccount.wallet === 'klip') {
      createAuctionKlip();
    } else if (walletAccount.kaikasProvider) {
      const isExisted = caver.wallet.isExisted(walletAccount.account);

      if (isExisted) {
        createAuction();
      } else {
        setIsShowModal(true);
        return;
      }
    } else {
      createAuction();
    }

    // } catch (e: any) {
    //   setToast((prev) => ({
    //     ...prev,
    //     type: 'danger',
    //     message: e,
    //     isShow: true,
    //   }));
    // }
  };

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            reset();
            setKlipRequestKey('');
            setIsLoading(false);
          }
        }
      );
    },
    isLoading && klipRequestKey ? 1000 : null
  );

  const createAuctionKlip = async () => {
    const createAuctionFee = await new caverAPI.contract(
      // @ts-ignore
      NFTMarketCollection.abi,
      // @ts-ignore
      addresses.nftMarket[8217]
    ).methods
      .createAuctionFee()
      .call();

    setIsLoading(true);
    const res = await prepare.executeContract({
      bappName: t('bAppName'),
      from: walletAccount.account,
      to: addresses.nftMarket[8217],
      // value: (createAuctionFee / 1000).toString(),
      value: createAuctionFee,
      // value: web3.utils.toWei(createAuctionFee, 'milliether'),
      abi: JSON.stringify(CreateAuctionABI),
      params: JSON.stringify([
        addresses.nft[8217],
        props.id,
        web3.utils.toWei(startingPrice!.toString(), 'ether'),
        title,
        web3.utils.toWei(buyNowPrice!.toString(), 'ether'),
        new Date(expiryDate).getTime(),
        props.category,
        1,
        1,
      ]),
    });

    if (res.request_key) {
      if (isMobile) {
        request(res.request_key);
      }
      setKlipRequestKey(res.request_key);
    }
  };

  const createAuction = async () => {
    const createAuctionFee = await contracts
      .nftMarketContract(walletAccount.chainId, walletAccount)
      .methods.createAuctionFee()
      .call();

    const auction = await contracts
      .nftMarketContract(walletAccount.chainId, walletAccount)
      .methods.createAuction(
        props.is1155
          ? // @ts-ignore
            addresses.nft1155[walletAccount.chainId]
          : // @ts-ignore
            addresses.nft[walletAccount.chainId],
        props.id,
        new BigNumber(props.is1155 ? 0.001 : startingPrice!)
          .times(new BigNumber(10).pow(18))
          .toNumber()
          .toFixed(0),
        title,
        new BigNumber(buyNowPrice!)
          .times(new BigNumber(10).pow(18))
          .toNumber()
          .toFixed(0),
        new Date(expiryDate).getTime() / 1000,
        props.category,
        props.is1155 ? 2 : 1,
        props.is1155 ? props.count : 1
      )
      .send({
        from: walletAccount.account,
        value: createAuctionFee,
      });

    reset();
  };

  const getIsApproved = async () => {
    const isApproved = await contracts
      .nftContract(walletAccount.chainId, walletAccount)
      .methods.isApprovedForAll(
        walletAccount.account,
        // @ts-ignore
        addresses.nftMarket[walletAccount.chainId]
      )
      .call();

    setIsApproved(isApproved);
  };

  const setApprovalForAll = async () => {
    await contracts
      .nftContract(walletAccount.chainId, walletAccount)
      .methods // @ts-ignore
      .setApprovalForAll(addresses.nftMarket[walletAccount.chainId], true)
      .send({ from: walletAccount.account });

    setIsApproved(true);
  };

  const getIsApproved1155 = async () => {
    const isApproved = await contracts
      .nftContract(walletAccount.chainId, walletAccount)
      .methods.isApprovedForAll(
        walletAccount.account,
        // @ts-ignore
        addresses.nftMarket[walletAccount.chainId]
      )
      .call();

    setIsApproved1155(isApproved);
  };

  const setApprovalForAll1155 = async () => {
    await contracts
      .nftContract(walletAccount.chainId, walletAccount, true)
      .methods // @ts-ignore
      .setApprovalForAll(addresses.nftMarket[walletAccount.chainId], true)
      .send({ from: walletAccount.account });

    setIsApproved1155(true);
  };

  const reset = () => {
    setIsCreateOpen(false);
    setTitle('');
    setStartingPrice(undefined);
    setBuyNowPrice(undefined);
    setExpiryDate('');
    setIsAuctionFinish(true);
    setToast((prev) => ({
      ...prev,
      type: 'success',
      message: t('message.myItem.success1'),
      isShow: true,
    }));
  };

  React.useEffect(() => {
    if (!klipRequestKey) {
      setIsLoading(false);
    }
  }, [klipRequestKey]);

  React.useEffect(() => {
    if (walletAccount.account) {
      getIsApproved();
      getIsApproved1155();
    }
  }, [walletAccount]);

  return (
    <>
      <div className={`col-12 col-md-6 col-lg-3 single_gallery_item`}>
        <div className="pricing-item ">
          <div className="wraper">
            <div
              style={{
                width: '100%',
                aspectRatio: '1',
                backgroundImage: `url('${props.img}')`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                borderRadius: 10,
              }}
            >
              {isVideo ? (
                <video
                  src={props.img}
                  style={{ width: '100%', height: '100%' }}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              ) : (
                <img
                  src={props.img}
                  style={{ width: 0 }}
                  alt=""
                  onError={() => setIsVideo(true)}
                />
              )}
            </div>
            <h4
              className="d-flex align-items-center justify-content-between"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              #{props.id} {props.title}
              {props.is1155 ? (
                <span>{props.count} 개</span>
              ) : (
                <span>&nbsp;</span>
              )}
            </h4>
            {isCreateOpen && (
              <div className="group mb-3">
                <input
                  placeholder={t('myItem.titlePlaceholder')}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                {!props.is1155 && (
                  <input
                    type="number"
                    placeholder={t('myItem.startingPricePlaceholder')}
                    value={startingPrice}
                    onChange={(e) =>
                      setStartingPrice(parseFloat(e.target.value))
                    }
                  />
                )}
                <input
                  type="number"
                  placeholder={t('myItem.buyNowPricePlaceholder')}
                  value={buyNowPrice}
                  onChange={(e) => setBuyNowPrice(parseFloat(e.target.value))}
                />
                <input
                  type="datetime-local"
                  placeholder={t('myItem.expiryDatePlaceholder')}
                  value={expiryDate}
                  onChange={(e) => setExpiryDate(e.target.value)}
                  min={moment().add(1, 'days').toISOString().slice(0, -8)}
                  max={moment().add(1, 'years').toISOString().slice(0, -8)}
                />
              </div>
            )}
            {props.is1155 ? (
              isApproved1155 ? (
                <button
                  className="btn more-btn w-100 text-center my-0 mx-auto d-flex align-items-center justify-content-center"
                  disabled={isLoading}
                  onClick={onClickCreateAuction}
                >
                  {isLoading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    t('myItem.createAuctionButton')
                  )}
                </button>
              ) : (
                <button
                  className="btn more-btn w-100 text-center my-0 mx-auto d-flex align-items-center justify-content-center"
                  onClick={setApprovalForAll1155}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    t('myItem.setApprovalForAllButton')
                  )}
                </button>
              )
            ) : isApproved ? (
              <button
                className="btn more-btn w-100 text-center my-0 mx-auto d-flex align-items-center justify-content-center"
                disabled={isLoading}
                onClick={onClickCreateAuction}
              >
                {isLoading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  t('myItem.createAuctionButton')
                )}
              </button>
            ) : (
              <button
                className="btn more-btn w-100 text-center my-0 mx-auto d-flex align-items-center justify-content-center"
                onClick={setApprovalForAll}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  t('myItem.setApprovalForAllButton')
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal show={isShowModal} onHide={() => setIsShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('kaikasPrivateKeyTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="password"
            className="mb-3"
            placeholder={t('kaikasPrivateKeyPlaceholder')}
            value={kaikasPrivateKey}
            onChange={(e) => setKaikasPrivateKey(e.target.value)}
          />
          <Button type="button" className="w-100" onClick={kaikasSign}>
            {t('kaikasPrivateKeySubmit')}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MyItem;
