import HeroContainer from "./Hero";
import TopSellers from "../../components/TopSellers";
// import TopCollections from '../../components/TopCollections';
// import ListedItems from '../../components/ListedItems';
import HomeAuctions from "../../components/HomeAuctions";
import { Carousel, OverlayTrigger, Tooltip } from "react-bootstrap";

import Banner1 from "../../assets/img/banner1.png";
import Banner2 from "../../assets/img/banner2.png";
import Banner3 from "../../assets/img/banner3.png";
import BannerMobile1 from "../../assets/img/banner-mobile1.png";
import BannerMobile2 from "../../assets/img/banner-mobile2.png";
import BannerMobile3 from "../../assets/img/banner-mobile3.png";

import "../../assets/css/home.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InfoComponent from "../../components/InfoComponent";
import Desc from "../../assets/img/home-img/desc-img.png";
import MobileDesc from "../../assets/img/home-img/m-desc.png";
import NftDesc from "../../assets/img/home-img/nft-desc.png";
import MobileNftDesc from "../../assets/img/home-img/m-nft-desc.png";
import CardDesc from "../../assets/img/home-img/card-desc.png";
import MobileCardDesc from "../../assets/img/home-img/m-card-desc.png";
import ToolTip from "../../assets/img/home-img/tooltip.png";

const HomeContainer = () => {
  const { t } = useTranslation();

  return (
    <div className="home-container">
      {/* <HeroContainer /> */}
      <Carousel fade>
        <Carousel.Item>
          <Link to="/auctions/1">
            <picture>
              <source srcSet={BannerMobile3} media="(max-width: 767px)" />
              <img className="d-block w-100" src={Banner3} alt="First slide" />
            </picture>
          </Link>
          {/* <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <Link to="/auctions/2">
            <picture>
              <source srcSet={BannerMobile2} media="(max-width: 767px)" />
              <img className="d-block w-100" src={Banner2} alt="Second slide" />
            </picture>
          </Link>

          {/* <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <Link to="/">
            <picture>
              <source srcSet={BannerMobile1} media="(max-width: 767px)" />
              <img className="d-block w-100" src={Banner1} alt="Third slide" />
            </picture>
          </Link>

          {/* <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
      {/*<TopSellers />*/}
      {/*<TopCollections /> */}
      {/*<ListedItems /> */}
      <HomeAuctions />
      <section className="features  section-padding-100 ">
        <div className="container">
          <InfoComponent
            titleSm="아티스트의 컬렉션을 만나보세요"
            titleLg="NFT 이용 가이드"
            text=""
            noAnimation
          />
          <div className="home-contents-wrapper">
            <a
              className="pc-nft-desc"
              rel="noreferrer"
              target="_blank"
              href="https://link.medium.com/MBGCxFG6unb"
            >
              <img src={NftDesc} alt="NFT 이용 가이드" />
            </a>
            <img
              className="m-nft-desc"
              src={MobileNftDesc}
              alt="NFT 이용 가이드"
            />
            <button
              className="m-desc-btn"
              onClick={() => {
                window.open("https://link.medium.com/MBGCxFG6unb", "_blank");
              }}
            >
              자세히 보기
            </button>
          </div>
        </div>
      </section>
      <section className="features  section-padding-100 ">
        <div className="container">
          <InfoComponent
            titleSm="리미티드 에디션카드"
            titleLg="카드 등급 소개"
            titleLgRight=
              {
                <OverlayTrigger
                  key="right"
                  placement="right"
                  overlay=
                    {
                      <Tooltip>
                        카드 등급에 따라 NFT 발행량과 컨텐츠 내용이 달라집니다.
                        <br />
                        좋아하는 아티스트의 프리미엄 카드를 소장하세요.
                      </Tooltip>
                    }
                >
                  <div className="tool-tip">
                    <img className="tooltip-icon" src={ToolTip} alt="카드 등급 소개" />
                  </div>
                </OverlayTrigger>
              }
            text=""
            noAnimation
          />
          <div className="home-contents-wrapper">
            <picture>
              <source media="(max-width: 991px)" srcSet={MobileCardDesc} />
              <img className="card-desc" src={CardDesc} alt="카드 등급 소개" />
            </picture>
            <button
              className="desc-btn"
              onClick={() => {
                window.open("https://link.medium.com/6VF6UlO6unb", "_blank");
              }}
            >
              자세히 보기
            </button>
          </div>
        </div>
      </section>
      <section className="features  section-padding-100-200 ">
        <div className="container">
          <InfoComponent
            titleSm="독점 컨텐츠"
            titleLg="오직, K-POP NFT에서만"
            text=""
            noAnimation
          />
          <div className="home-contents-wrapper">
            <picture>
              <source media="(max-width: 991px)" srcSet={MobileDesc} />
              <img className="kpop-desc" src={Desc} alt="" />
            </picture>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeContainer;
