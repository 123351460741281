import PartProfile from '../../../components/PartProfile';

const CollectionItem = () => {
  return (
    <>
      <PartProfile />
    </>
  );
};

export default CollectionItem;
