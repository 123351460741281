import { useTranslation } from 'react-i18next';
function NameInput({
  Class,
  delay,
  name,
  title,
}: {
  Class: string;
  delay: number;
  name: string;
  title: string;
}) {
  const { t } = useTranslation();

  return (
    <div className={Class}>
      <div className="group" data-aos-delay={delay} data-aos="fade-up">
        <input
          type="text"
          name={name}
          id="name"
          placeholder={`${title} *`}
          required
          onInput={(e: any) => e.target.setCustomValidity('')}
          onInvalid={(e: any) =>
            e.target.setCustomValidity(`${title}${t('contact.requiredText')}`)
          }
        />
        <span className="highlight"></span>
        <span className="bar"></span>
        {/* <label>{title}</label> */}
      </div>
    </div>
  );
}

export default NameInput;
