import Breadcumb from '../../components/Breadcumb';
import InfoComponent from '../../components/InfoComponent';
import FeaturesList from './FeaturesList';
import { useTranslation } from 'react-i18next';

const ConnectWalletContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <Breadcumb
        namePage={t('connectWallet.breadcumbNamePage')}
        title={t('connectWallet.breadcumbTitle')}
        useTitle={false}
      /> */}
      <section className="features section-padding-100 ">
        <div className="container pt-5">
          <InfoComponent
            titleSm={t('connectWallet.infoTitleSm')}
            titleLg={t('connectWallet.infoTitleLg')}
            text={t('connectWallet.infoText')}
          />
          <div className="row justify-content-center">
            <FeaturesList />

            {/* <ImageBox /> */}
          </div>
        </div>
      </section>
      {/* <CardSection /> */}
    </>
  );
};

export default ConnectWalletContainer;
