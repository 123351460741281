const WidgetTitle = () => {

  return (
    <>
      <div className="widget-title wow fadeInUp" data-wow-delay="0.9s">
          <h5>subscribe</h5>
      </div>
    </>
  );
}

export default WidgetTitle;