import Head from '../../layouts/Head';
import CollectionContainer from '../../containers/Collection';
import Footer from '../../layouts/Footer';
import { useTranslation } from 'react-i18next';

// import '../../assets/css/profile.css'

const Collection = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head Title={t('collection.title')} />
      <CollectionContainer />
      <Footer />
    </>
  );
};

export default Collection;
