import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Switch,
  Route,
  withRouter,
  useHistory,
  useLocation,
} from "react-router-dom";
import Aos from "aos";
import {
  Activity,
  Auctions,
  Authors,
  ConnectWallet,
  Contact,
  CreateItem,
  Discover,
  Home,
  ItemDetails,
  Collection,
  Login,
  SignUp,
  Privacy,
  Policy,
  FAQ,
} from "./pages";

import "aos/dist/aos.css";
// import './assets/css/bootstrap.min.css';
import "./assets/css/global.css";
// import '../../assets/css/style.css';
// import './global.css';

import "bootstrap/dist/js/bootstrap.bundle.min";
import { useWeb3React } from "@web3-react/core";
import { injectedConnector, kaikasConnector } from "./connector/index";
import walletAccountAtom from "./atoms/walletAccount";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { Toast, ToastContainer } from "react-bootstrap";
import toastAtom from "./atoms/toast";
import tokenPriceAtom from "./atoms/tokenPrice";
import { getTokenPrice } from "./utils";
import { useInterval } from "usehooks-ts";
import moment from "moment";
import QRCode from "qrcode.react";
import { isMobile } from "react-device-detect";
import klipRequestKeyAtom from "./atoms/klipRequestKey";
import { ReactComponent as LogoKlip } from "./assets/img/icons/logo-klip.svg";
import { useWallet } from "@binance-chain/bsc-use-wallet";
import { provider } from "web3-core";
import ReactGA from "react-ga";
import { api } from "./utils/api";
import myProfileAtom from "./atoms/myProfile";

const App = () => {
  const [walletAccount, setWalletAccount] = useRecoilState(walletAccountAtom);
  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);
  const [toast, setToast] = useRecoilState(toastAtom);
  const setTokenPrice = useSetRecoilState(tokenPriceAtom);
  const myProfile = useRecoilValue(myProfileAtom);
  const { account, activate, active, chainId } = useWeb3React();
  const bsc = useWallet();
  const history = useHistory();
  const location = useLocation();

  const [isLoadingProfileCheck, setIsLoadingProfileCheck] =
    React.useState<boolean>(false);

  const profileCheck = React.useCallback(async () => {
    if (walletAccount.account) {
      try {
        const result = await api(
          `/profile/${walletAccount.account}/check`,
          "GET",
        );
        if (!result) {
          setIsLoadingProfileCheck(false);
          history.replace("/signup");
        } else {
          setIsLoadingProfileCheck(true);
        }
      } catch (e) {
        profileCheck();
      }
    }
  }, [walletAccount]);

  useEffect(() => {
    ReactGA.initialize("UA-216254820-1");
    history.listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });

    Aos.init({
      duration: 1000,
    });
    setToken();
  }, []);

  useInterval(() => {
    setToken();
  }, 1000 * 60 * 5);

  // useEffect(() => {
  //   if (walletAccount.account) {
  //     // ethereum
  //     if ([1, 3, 4, 5, 42].includes(walletAccount.chainId)) {
  //       web3.activate(injectedConnector);
  //     }
  //     // bsc
  //     if ([56, 97].includes(walletAccount.chainId)) {
  //     }
  //     // klaytn
  //     if ([1001, 8217].includes(walletAccount.chainId)) {
  //       if (walletAccount.kaikasProvider) {
  //         // @ts-ignore
  //         web3.activate(kaikasConnector);
  //         // loginKaikas();
  //       }
  //     }
  //   }
  // }, [walletAccount, web3]);

  useEffect(() => {
    if (
      !walletAccount.account &&
      window.localStorage.getItem("wallet") === "metamask"
    ) {
      activate(injectedConnector);
    }
    if (
      !walletAccount.account &&
      window.localStorage.getItem("wallet") === "binance"
    ) {
      bsc.connect("bsc");
    }
    if (
      !walletAccount.account &&
      window.localStorage.getItem("wallet") === "kaikas"
    ) {
      // @ts-ignore
      activate(kaikasConnector);
    }
  }, []);

  useEffect(() => {
    if (
      active &&
      walletAccount.chainId !== chainId &&
      window.localStorage.getItem("wallet") === "metamask"
    ) {
      setWalletAccount({
        chainId: chainId!,
        wallet: "metamask",
        account: account!,
      });
    }
  }, [walletAccount, active, chainId, account]);

  useEffect(() => {
    if (
      bsc.account &&
      bsc.chainId &&
      !walletAccount.account &&
      window.localStorage.getItem("wallet") === "binance"
    ) {
      setWalletAccount({
        chainId: bsc.chainId,
        wallet: "binance",
        account: bsc.account,
        ethereum: bsc.ethereum as provider,
      });
    }
  }, [walletAccount, bsc]);

  useEffect(() => {
    if (
      walletAccount.account &&
      history.location.pathname !== "/signup"
    ) {
      profileCheck()
      // history.replace("/signup");
    }
  }, [history.location.pathname]);

  const setToken = async () => {
    const price = await getTokenPrice();

    setTokenPrice((prev) => ({
      ...prev,
      ETH: { rate: price.ethereum.usd, updatedAt: moment() },
      BNB: { rate: price.binancecoin.usd, updatedAt: moment() },
      KLAY: { rate: price["klay-token"].usd, updatedAt: moment() },
    }));
  };

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <title>NFT Marketplace</title>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
        />
      </Helmet>

      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/activity" component={Activity} />
        <Route path="/auctions/:category" component={Auctions} />
        <Route path="/auctions" component={Auctions} />
        <Route path="/authors" component={Authors} />
        <Route path="/connectWallet" component={ConnectWallet} />
        <Route path="/contact" component={Contact} />
        <Route path="/createitem" component={CreateItem} />
        <Route path="/discover" component={Discover} />
        <Route path="/itemDetails/:chainId/:id" component={ItemDetails} />
        <Route path="/itemDetails/:id" component={ItemDetails} />
        <Route path="/itemDetails" component={ItemDetails} />
        <Route path="/collection" component={Collection} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={SignUp} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/policy" component={Policy} />
        <Route path="/faq" component={FAQ} />
      </Switch>
      <ToastContainer
        className="position-fixed p-3"
        position={isMobile ? "bottom-center" : "bottom-end"}
        style={{ zIndex: 1000 }}
      >
        <Toast
          show={toast.isShow}
          bg={toast.type}
          autohide={true}
          onClose={() => setToast({ ...toast, isShow: false })}
        >
          <Toast.Body
            className={`d-flex align-items-center justify-content-between ${
              toast.type !== "light" ? "text-white" : ""
            }`}
          >
            {toast.message}{" "}
            <button
              type="button"
              className="btn-close"
              onClick={() => setToast({ ...toast, isShow: false })}
            ></button>
            {" "}
          </Toast.Body>
        </Toast>
      </ToastContainer>
      {!isMobile && klipRequestKey && (
        <div
          style={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 2001,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
            onClick={() => setKlipRequestKey("")}
          ></div>
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              padding: 50,
              backgroundColor: "white",
              display: "flex",
            }}
          >
            <div style={{ border: "1px solid #ddd", padding: 20, fontSize: 0 }}>
              <QRCode
                value={`https://klipwallet.com/?target=/a2a?request_key=${klipRequestKey}`}
              />
            </div>
            <div style={{ width: 200, marginLeft: 20 }}>
              <LogoKlip />
              <div style={{ fontSize: 12, marginTop: 10 }}>
                {`QR코드 리더기 혹은 카카오톡 앱 > 더보기 > 우측 상단의 스캔 아이콘을 눌러 QR코드를 스캔해 주세요.`}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const ScrollToTop = withRouter(({ history }) => {
  React.useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
});

export default App;
