import BigNumber from 'bignumber.js';
import React from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import contracts from '../../../constants/contracts';
import isAuctionFinishAtom from '../../../atoms/isAuctionFinish';
import { IAuction } from '../../../types/index';
import walletAccountAtom from '../../../atoms/walletAccount';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import klipRequestKeyAtom from '../../../atoms/klipRequestKey';
import toastAtom from '../../../atoms/toast';
import addresses from '../../../constants/addresses';
import { isMobile } from 'react-device-detect';
import { useInterval } from 'usehooks-ts';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import web3 from '../../../connection/web3';
import { useHistory } from 'react-router-dom';
import { PlaceBidABI } from '../../../utils/abis';

const TestPopup = (props: IAuction) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [bidPrice, setBidPrice] = React.useState<number>(0);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const setIsAuctionFinish = useSetRecoilState(isAuctionFinishAtom);
  const setToast = useSetRecoilState(toastAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);
  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            setKlipRequestKey('');
            reset();
          }
        }
      );
    },
    isLoading && klipRequestKey ? 1000 : null
  );

  const calcedBidPrice = React.useMemo(
    () => new BigNumber(bidPrice).times(new BigNumber(10).pow(18)),
    [bidPrice]
  );

  const onPlaceBid = async () => {
    const currentPrice = new BigNumber(parseInt(props.currentPrice))
      .div(new BigNumber(10).pow(18))
      .toNumber();
    if (currentPrice > bidPrice) {
      setToast((prev) => ({
        ...prev,
        type: 'danger',
        message: t('message.placeBid.error1'),
        isShow: true,
      }));
      return;
    }

    setIsLoading(true);

    if (walletAccount.wallet === 'klip') {
      const res = await prepare.executeContract({
        bappName: '싸이클럽',
        from: walletAccount.account,
        to: addresses.nftMarket[8217],
        value: new BigNumber(bidPrice * 0.025 + bidPrice)
          .times(new BigNumber(10).pow(18))
          .toNumber()
          .toFixed(0),
        abi: JSON.stringify(PlaceBidABI),
        params: JSON.stringify([
          props.auctionId,
          calcedBidPrice.toNumber().toFixed(0),
        ]),
      });

      if (res.request_key) {
        if (isMobile) {
          request(res.request_key);
        }
        setKlipRequestKey(res.request_key);
      }
      return;
    }

    try {
      await contracts
        .nftMarketContract(walletAccount.chainId, walletAccount)
        .methods.placeBid(props.auctionId, calcedBidPrice.toNumber().toFixed(0))
        .send({
          from: walletAccount.account,
          value: new BigNumber(bidPrice * 0.025 + bidPrice)
            .times(new BigNumber(10).pow(18))
            .toNumber()
            .toFixed(0),
        });

      reset();
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const reset = () => {
    setBidPrice(0);
    setIsAuctionFinish(true);
    setIsLoading(false);
    setToast((prev) => ({
      ...prev,
      type: 'success',
      message: t('message.placeBid.success1'),
      isShow: true,
    }));
  };

  React.useEffect(() => {
    if (!klipRequestKey) {
      setIsLoading(false);
    }
  }, [klipRequestKey]);

  return (
    <>
      <div
        id="test-popup"
        className="white-popup mfp-hide text-center mb-4"
        style={{ padding: 20 }}
      >
        <div className="top-form-header">
          <h5>{t('itemDetail.placeABid')}</h5>
        </div>
        <form action="#" method="post" id="main_login_form">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="group">
                <input
                  type="number"
                  name="name"
                  id="name0"
                  value={bidPrice}
                  onChange={(e) => {
                    if (
                      parseFloat(e.target.value) >=
                      parseFloat(web3.utils.fromWei(props.buyNowPrice))
                    ) {
                      setBidPrice(
                        parseFloat(web3.utils.fromWei(props.buyNowPrice))
                      );
                      return;
                    }
                    setBidPrice(parseFloat(e.target.value));
                  }}
                  placeholder={t('itemDetail.placeBidPlaceholder')}
                />
                <span className="highlight"></span>
                <span className="bar"></span>
                {/* <label>Your Bid Amount</label> */}
              </div>
              {/* <p className="g-text">{t('itemDetail.placeBidText')}</p> */}
            </div>
            <div className="col-12">
              {!walletAccount.account ? (
                <button
                  type="button"
                  className="btn more-btn w-100"
                  onClick={() => history.push('/connectWallet')}
                >
                  {t('header.connectWallet')}
                </button>
              ) : (
                props.tokenInfo?.chainId === walletAccount.chainId && (
                  <button
                    type="button"
                    disabled={isLoading}
                    className="btn more-btn w-100"
                    onClick={onPlaceBid}
                  >
                    {isLoading ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      t('itemDetail.placeBid')
                    )}
                  </button>
                )
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default TestPopup;
