import React from 'react';
import { useHistory } from 'react-router-dom';
import { getTokenInfo } from '../../utils';
import Breadcumb from '../../components/Breadcumb';

import '../../assets/css/profile.css';
import contracts from '../../constants/contracts';
import myAuctionsAtom from '../../atoms/myAuctions';
import myItemsAtom from '../../atoms/myItems';
import { IAuction } from '../../types';
import { useRecoilState } from 'recoil';
import MyAuction from './MyAuction/index';
import MyItem from './MyItem';
import { useMoralis, useMoralisWeb3Api } from 'react-moralis';
import addresses from '../../constants/addresses';
import { useInterval } from 'usehooks-ts';
import isAuctionFinishAtom from '../../atoms/isAuctionFinish';
import walletAccountAtom from '../../atoms/walletAccount';
import { caverAPI } from '../../connection/caver';
import { useTranslation } from 'react-i18next';
import CollectionItem from './CollectionItem';
import styled from 'styled-components';
import { IItem } from '../../types/index';
import MyBidAuction from './MyBidAuction';
import myBidAuctionsAtom from '../../atoms/myBidAuctions';

const RightWrap = styled.div`
  border-left: 1px dashed rgba(255, 255, 255, 0.3);
  @media (max-width: 991px) {
    border-left: 0;
  }
`;

const CollectionContainer = () => {
  const { t } = useTranslation();
  const { isInitialized } = useMoralis();
  const { account } = useMoralisWeb3Api();
  const history = useHistory();

  const [currentTab, setCurrentTab] = React.useState<number>(0);
  const [myAuctions, setMyAuctions] = useRecoilState(myAuctionsAtom);
  const [myBidAuctions, setMyBidAuctions] = useRecoilState(myBidAuctionsAtom);
  const [myItems, setMyItems] = useRecoilState(myItemsAtom);
  const [walletAccount, setWalletAccount] = useRecoilState(walletAccountAtom);

  const [isAuctionFinish, setIsAuctionFinish] =
    useRecoilState(isAuctionFinishAtom);

  const getAuctions = async () => {
    try {
      const userAuctions = await contracts
        .nftMarketContract(walletAccount.chainId, walletAccount)
        .methods.getUserAuctions(walletAccount.account)
        .call();

      const promises = userAuctions.map(async (auction: IAuction) => {
        if (
          auction.auctionTypes.status === '1' ||
          auction.auctionTypes.status === '2'
        ) {
          const tokenInfo: IItem | undefined = await getTokenInfo(
            parseInt(auction['tokenId']),
            walletAccount.chainId,
            auction.auctionTypes.tokenType === '2'
          );

          return {
            ...auction,
            tokenInfo: {
              ...tokenInfo,
              is1155: auction.auctionTypes.tokenType === '2',
              count: auction.auctionTypes.quantity,
            },
          };
        } else {
          return null;
        }
      });

      const data = await Promise.all(promises);

      // @ts-ignore
      setMyAuctions(data.filter((el) => el != null));
    } catch (e) {
      console.log(e);
    }
  };

  const getMyBidAuctions = async () => {
    try {
      const userAuctions = await contracts
        .nftMarketContract(walletAccount.chainId, walletAccount)
        .methods.getUserBidAuctions(walletAccount.account)
        .call();

      const promises = userAuctions.map(async (auction: IAuction) => {
        if (
          auction.auctionTypes.status === '1' ||
          auction.auctionTypes.status === '2'
        ) {
          const tokenInfo: IItem | undefined = await getTokenInfo(
            parseInt(auction['tokenId']),
            walletAccount.chainId,
            auction.auctionTypes.tokenType === '2'
          );

          return {
            ...auction,
            tokenInfo: {
              ...tokenInfo,
              is1155: auction.auctionTypes.tokenType === '2',
              count: auction.auctionTypes.quantity,
            },
          };
        } else {
          return null;
        }
      });

      const data = await Promise.all(promises);

      // @ts-ignore
      setMyAuctions(data.filter((el) => el != null));
    } catch (e) {
      console.log(e);
    }
  };

  const getTotalSupply = async () => {
    let datas: any[] = [];
    let chain = 'eth';

    switch (walletAccount.chainId) {
      case 1:
        chain = 'eth';
        break;
      case 3:
        chain = 'ropsten';
        break;
      case 4:
        chain = 'rinkeby';
        break;
      case 5:
        chain = 'goerli';
        break;
      case 42:
        chain = 'kovan';
        break;
      case 56:
        chain = 'bsc';
        break;
      case 97:
        chain = 'bsc testnet';
        break;
    }

    if ([1001, 8217].includes(walletAccount.chainId)) {
      const tokens = await caverAPI.kas.tokenHistory.getNFTListByOwner(
        // @ts-ignore
        addresses.nft[walletAccount.chainId],
        walletAccount.account
      );

      if (tokens.items) {
        tokens.items.map(async (token: any) => {
          const tokenId = parseInt(token.tokenId, 16);
          const result = await contracts
            .nftContract(walletAccount.chainId, walletAccount)
            .methods.ownerOf(tokenId)
            .call();

          if (result === walletAccount.account) {
            const tokenInfo = await getTokenInfo(
              tokenId,
              walletAccount.chainId
            );

            datas = [{ ...tokenInfo }, ...datas];
            setMyItems(datas);
          }
        });
      }
    }
    if (isInitialized) {
      const tokens = await account.getNFTsForContract({
        // @ts-ignore
        chain,
        address: walletAccount.account,
        // @ts-ignore
        token_address: addresses.nft[walletAccount.chainId],
      });
      const tokens1155 = await account.getNFTsForContract({
        // @ts-ignore
        chain,
        address: walletAccount.account,
        // @ts-ignore
        token_address: addresses.nft1155[walletAccount.chainId],
      });

      if (tokens.result) {
        tokens.result.map(async (token) => {
          const result = await contracts
            .nftContract(walletAccount.chainId, walletAccount)
            .methods.ownerOf(token.token_id)
            .call();

          if (result === walletAccount.account) {
            const tokenInfo = await getTokenInfo(
              parseInt(token.token_id),
              walletAccount.chainId
            );

            datas = [{ ...tokenInfo }, ...datas];
            setMyItems(datas);
          }
        });
      }
      if (tokens1155.result) {
        tokens1155.result.map(async (token) => {
          const result = await contracts
            .nftContract(walletAccount.chainId, walletAccount, true)
            .methods.balanceOf(walletAccount.account, token.token_id)
            .call();

          if (result) {
            const tokenInfo = await getTokenInfo(
              parseInt(token.token_id),
              walletAccount.chainId,
              true
            );

            datas = [{ ...tokenInfo, is1155: true, count: result }, ...datas];
            setMyItems(datas);
          }
        });
      }
    }
  };

  useInterval(
    () => {
      if (walletAccount.account) {
        getTotalSupply();
        getAuctions();
        setIsAuctionFinish(false);
      }
    },
    isAuctionFinish ? 1000 : null
  );

  React.useEffect(() => {
    if (walletAccount.account) {
      getTotalSupply();
      getAuctions();
      getMyBidAuctions();
    } else {
      setMyItems([]);
      history.replace('/connectwallet');
    }
  }, [isInitialized, walletAccount]);

  return (
    <>
      {/* <Breadcumb
        namePage={t('collection.breadcumbNamePage')}
        title={t('collection.breadcumbTitle')}
        useTitle={false}
      /> */}
      <section
        className="blog-area section-padding-100"
        style={{ minHeight: '50vh' }}
      >
        <div className="container pt-5">
          <div className="row">
            <CollectionItem />

            <RightWrap className="col-12 col-lg-9">
              <div className="dream-projects-menu mb-50">
                <div className="text-center portfolio-menu">
                  <button
                    type="button"
                    className={`btn ${currentTab === 0 && 'active'}`}
                    onClick={() => setCurrentTab(0)}
                  >
                    {t('collection.myNFT')}
                  </button>
                  <button
                    type="button"
                    className={`btn ${currentTab === 1 && 'active'}`}
                    onClick={() => setCurrentTab(1)}
                  >
                    {t('collection.myAuctions')}
                  </button>
                  <button
                    type="button"
                    className={`btn ${currentTab === 2 && 'active'}`}
                    onClick={() => setCurrentTab(2)}
                  >
                    {t('collection.myBidAuctions')}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="container">
                  <div className="row" data-aos="fade-up">
                    {currentTab === 0 &&
                      (myItems.length > 0 ? (
                        myItems.map((item, i) => <MyItem key={i} {...item} />)
                      ) : (
                        <p className="text-center">{t('collection.noNFTs')}</p>
                      ))}
                    {currentTab === 1 &&
                      (myAuctions.length > 0 ? (
                        myAuctions.map((item, i) => (
                          <MyAuction key={i} {...item} />
                        ))
                      ) : (
                        <p className="text-center">
                          {t('collection.noAuctions')}
                        </p>
                      ))}
                    {currentTab === 2 &&
                      (myBidAuctions.length > 0 ? (
                        myBidAuctions.map((item, i) => (
                          <MyBidAuction key={i} {...item} />
                        ))
                      ) : (
                        <p className="text-center">
                          {t('collection.noAuctions')}
                        </p>
                      ))}
                  </div>
                </div>

                {/* <div className="col-12 col-lg-12 text-center">
                  <a className="btn more-btn" href="/discover">
                    Load More
                  </a>
                </div> */}
              </div>
            </RightWrap>
          </div>
        </div>
      </section>
    </>
  );
};

export default CollectionContainer;
