import { AbiItem } from 'web3-utils';
import web3, {
  web3BSC,
  web3BSCTest,
  web3Cypress,
  web3Main,
} from '../connection/web3';
import addresses from './addresses';
import NFTCollection from '../abis/KPNFT721.json';
import NFT1155Collection from '../abis/KPNFT1155.json';
import NFTMarketCollection from '../abis/NAMarketV2.json';
import { IWalletAccount } from '../atoms/walletAccount';
import Web3 from 'web3';
import { AbiItem as CaverAbiItem } from 'caver-js';
import { provider } from 'web3-core';
import caver from '../connection/caver';
import { web3Baobab, web3Rinkeby, web3ByChainId } from '../connection/web3';
import { caverAPI } from '../connection/caver';

const contracts = {
  nftContract: (
    chainId: number,
    walletAccount?: IWalletAccount,
    isMulti?: boolean
  ) => {
    const collection = isMulti ? NFT1155Collection : NFTCollection;
    const address = isMulti
      ? // @ts-ignore
        addresses.nft1155[chainId]
      : // @ts-ignore
        addresses.nft[chainId];
    // bsc
    if ([56, 97].includes(chainId)) {
      if (walletAccount?.wallet === 'binance') {
        const bsc = new Web3(walletAccount.ethereum as provider);
        return new bsc.eth.Contract(
          collection.abi as unknown as AbiItem,
          address
        );
      }
    }
    // klaytn
    if ([1001, 8217].includes(chainId)) {
      if (walletAccount?.wallet === 'kaikas') {
        // @ts-ignore
        return new caver.contract(
          collection.abi as unknown as CaverAbiItem[],
          address,
          { from: walletAccount.account, gas: 30000 }
        );
      }
      if (walletAccount?.wallet === 'klip') {
        return new caverAPI.contract(collection.abi, address);
      }
    }
    return new web3.eth.Contract(collection.abi as unknown as AbiItem, address);
  },
  nftMarketContract: (chainId: number, walletAccount?: IWalletAccount) => {
    // bsc
    if ([56, 97].includes(chainId)) {
      if (walletAccount?.wallet === 'binance') {
        const bsc = new Web3(walletAccount.ethereum as provider);
        return new bsc.eth.Contract(
          NFTMarketCollection.abi as unknown as AbiItem,
          // @ts-ignore
          addresses.nftMarket[chainId]
        );
      }
    }
    // klaytn
    if ([1001, 8217].includes(chainId)) {
      if (walletAccount?.wallet === 'kaikas') {
        return new caver.contract(
          NFTMarketCollection.abi as unknown as CaverAbiItem[],
          // @ts-ignore
          addresses.nftMarket[chainId]
        );
      }
      if (walletAccount?.wallet === 'klip') {
        return new caverAPI.contract(
          // @ts-ignore
          NFTMarketCollection.abi,
          // @ts-ignore
          addresses.nftMarket[8217]
        );
      }
    }
    return new web3.eth.Contract(
      NFTMarketCollection.abi as unknown as AbiItem,
      // @ts-ignore
      addresses.nftMarket[chainId]
    );
  },
  nftMarketContractByChainId: (chainId: number) => {
    let currentWeb3 = web3;
    switch (chainId) {
      case 1:
        currentWeb3 = web3Main;
        break;
      case 4:
        currentWeb3 = web3Rinkeby;
        break;
      case 56:
        currentWeb3 = web3BSC;
        break;
      case 97:
        currentWeb3 = web3BSCTest;
        break;
      case 1001:
        currentWeb3 = web3Baobab;
        break;
      case 8217:
        currentWeb3 = web3Cypress;
        break;
    }

    return new currentWeb3.eth.Contract(
      // @ts-ignore
      NFTMarketCollection.abi as unknown as AbiItem,
      // @ts-ignore
      addresses.nftMarket[chainId]
    );
  },
};

export default contracts;
