import { NavLink } from 'react-router-dom';
import { IAuction } from '../../types';
import BigNumber from 'bignumber.js';
import { getSymbolText } from '../../utils';
import React from 'react';
import IconETH from '../../assets/img/icons/icon-eth.png';
import IconBNB from '../../assets/img/icons/icon-bnb.png';
import IconKLAY from '../../assets/img/icons/icon-klay.png';
import { useRecoilValue } from 'recoil';
import tokenPriceAtom from '../../atoms/tokenPrice';
import walletAccountAtom from '../../atoms/walletAccount';
import { useTranslation } from 'react-i18next';

function LiveAuctionsItem(props: IAuction) {
  const { t } = useTranslation();
  const [isVideo, setIsVideo] = React.useState<boolean>(false);

  const tokenPrice = useRecoilValue(tokenPriceAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);

  const currentPrice = new BigNumber(parseInt(props.currentPrice)).div(
    new BigNumber(10).pow(18)
  );
  const buyNowPrice = new BigNumber(parseInt(props.buyNowPrice)).div(
    new BigNumber(10).pow(18)
  );

  const link = `/itemdetails/${props.tokenInfo?.chainId}/${props.auctionId}`;

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!props.tokenInfo) {
      e.preventDefault();
    }
  };

  let rate = tokenPrice.ETH.rate;
  let Icon = IconETH;

  switch (props.tokenInfo?.chainId) {
    case 56:
    case 97:
      rate = tokenPrice.BNB.rate;
      Icon = IconBNB;
      break;
    case 1001:
    case 8217:
      rate = tokenPrice.KLAY.rate;
      Icon = IconKLAY;
      break;
  }

  return (
    <div className="col-lg-3 col-sm-6 col-xs-12">
      <div className="pricing-item">
        <div className="wraper">
          <NavLink to={link} onClick={handleClick}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: '100%',
                height: '100%',
                aspectRatio: '1',
                backgroundImage: `url('${props.tokenInfo?.img}')`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                borderRadius: 10,
              }}
            >
              {!props.tokenInfo ? (
                <div className="spinner-border" />
              ) : isVideo ? (
                <video
                  src={props.tokenInfo.img}
                  style={{ width: '100%', height: '100%' }}
                  autoPlay
                  loop
                  muted
                  playsInline
                />
              ) : (
                <img
                  src={props.tokenInfo.img}
                  style={{ width: 0 }}
                  alt=""
                  onError={() => setIsVideo(true)}
                />
              )}
            </div>
          </NavLink>

          <NavLink to={link} onClick={handleClick}>
            <h4 className="d-flex align-items-center justify-content-between">
              {props.tokenInfo ? (
                <>
                  <div
                    style={{
                      flex: 1,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      paddingRight: 10,
                    }}
                  >
                    #{props.tokenId} {props.tokenInfo?.title}
                  </div>
                  <img
                    src={Icon}
                    alt=""
                    style={{
                      width: 25,
                      height: 25,
                    }}
                  />
                </>
              ) : (
                <div className="placeholder-glow w-100">
                  <div className="placeholder col-7"/>
                </div>
              )}
            </h4>
          </NavLink>
          <span className="w-100">
            {props.tokenInfo?.is1155 ? (
              <>
                {props.tokenInfo ? (
                  <>
                    <span className="g-text">{t('auctionItem.price')}</span>{' '}
                    {buyNowPrice.toString()} {/* @ts-ignore */}
                    {getSymbolText(props.tokenInfo?.chainId)}
                    {rate && (
                      <>
                        {' '}
                        ($
                        {(rate * buyNowPrice.toNumber()).toFixed(2)})
                      </>
                    )}
                  </>
                ) : (
                  <span className="g-text placeholder-glow w-100">
                    <div className="placeholder col-5"/>
                  </span>
                )}
                <br />
                {props.tokenInfo ? (
                  <>
                    <span className="g-text">{t('auctionItem.count')}</span>{' '}
                    {props.tokenInfo.count}
                  </>
                ) : (
                  <span className="g-text placeholder-glow w-100">
                    <div className="placeholder col-5"/>
                  </span>
                )}
              </>
            ) : (
              <>
                {props.tokenInfo ? (
                  <>
                    <span className="g-text">
                      {t('auctionItem.currentPrice')}
                    </span>{' '}
                    {currentPrice.toString()} {/* @ts-ignore */}
                    {getSymbolText(props.tokenInfo?.chainId)}
                    {rate && (
                      <>
                        {' '}
                        ($
                        {(rate * currentPrice.toNumber()).toFixed(2)})
                      </>
                    )}
                  </>
                ) : (
                  <span className="g-text placeholder-glow w-100">
                    <div className="placeholder col-5"/>
                  </span>
                )}
                <br />
                {props.tokenInfo ? (
                  <>
                    <span className="g-text">
                      {t('auctionItem.buyNowPrice')}
                    </span>{' '}
                    {buyNowPrice.toString()} {/* @ts-ignore */}
                    {getSymbolText(props.tokenInfo?.chainId)}
                    {rate && (
                      <>
                        {' '}
                        ($
                        {(rate * buyNowPrice.toNumber()).toFixed(2)})
                      </>
                    )}
                  </>
                ) : (
                  <span className="g-text placeholder-glow w-100">
                    <div className="placeholder col-5"/>
                  </span>
                )}
              </>
            )}
            {/* <span className="g-text ml-15">1 of 10</span> */}
          </span>
          {props.seller.toLocaleLowerCase() !==
            walletAccount.account.toLocaleLowerCase() &&
            props.tokenInfo && (
              <NavLink
                className="btn more-btn d-block w-100 text-center"
                to={link}
                onClick={handleClick}
                style={{ marginTop: 31 }}
              >
                {t(
                  props.tokenInfo.is1155
                    ? 'auctionItem.buyText'
                    : 'auctionItem.linkText'
                )}
              </NavLink>
            )}
        </div>
      </div>
    </div>
  );
}

export default LiveAuctionsItem;
