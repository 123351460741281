import TopSellersItem from '../TopSellersItem';
// import {team2 , logoSpotify} from '../../allImgs'
// import data from './data.json'
import {
  TopSellersData1,
  TopSellersData2,
  TopSellersData3,
} from '../../data/data-components/data-TopSellers.js';
import React from 'react';
import { ISellerSale } from '../../types';
import web3, {
  web3BSCTest,
  web3Rinkeby,
  web3Baobab,
} from '../../connection/web3';
import NFTMarketCollection from '../../abis/NAMarketV2.json';
import addresses from '../../constants/addresses';
import { caverAPI } from '../../connection/caver';
import moment from 'moment';
import { getShortAddress } from '../../utils';
import { useRecoilValue } from 'recoil';
import tokenPriceAtom from '../../atoms/tokenPrice';
import { getProfile } from '../../utils/api';
import { useTranslation } from 'react-i18next';
import TopSellersItemPlaceholder from '../TopSellersItemPlaceholder';
import { IProfile } from '../../types/index';
import InfoComponent from "../InfoComponent";

function TopSellersContainer() {
  const { t } = useTranslation();
  const tokenPrice = useRecoilValue(tokenPriceAtom);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selletSalesList, setSellerSalesList] = React.useState<ISellerSale[]>(
    []
  );

  const getSellerSalesList = async () => {
    setIsLoading(true);
    try {
      const rinkebyContract = new web3Rinkeby.eth.Contract(
        // @ts-ignore
        NFTMarketCollection.abi,
        addresses.nftMarket[4]
      );
      const bscTestContract = new web3BSCTest.eth.Contract(
        // @ts-ignore
        NFTMarketCollection.abi,
        addresses.nftMarket[97]
      );
      const cypressContract = new caverAPI.contract(
        // @ts-ignore
        NFTMarketCollection.abi,
        addresses.nftMarket[8217]
      );
      const baobabContract = new web3Baobab.eth.Contract(
        // @ts-ignore
        NFTMarketCollection.abi,
        addresses.nftMarket[1001]
      );

      let sellerSalesLists: ISellerSale[] = [];

      let rinkebyResult = await rinkebyContract.methods
        .getSellerSalesList(moment().days(-7).unix())
        .call();
      let bscTestResult = await bscTestContract.methods
        .getSellerSalesList(moment().days(-7).unix())
        .call();
      let cypressResult = await cypressContract.methods
        .getSellerSalesList(moment().days(-7).unix())
        .call();
      let baobabResult = await baobabContract.methods
        .getSellerSalesList(moment().days(-7).unix())
        .call();

      let sellerWallets: string[] = [];

      rinkebyResult = rinkebyResult.map((item: ISellerSale) => {
        sellerWallets.push(item.seller);
        const data = { ...item };

        data.price = (
          parseFloat(web3.utils.fromWei(data.price)) * tokenPrice.ETH.rate
        ).toString();
        return data;
      });

      bscTestResult = bscTestResult.map((item: ISellerSale) => {
        sellerWallets.push(item.seller);
        const data = { ...item };

        data.price = (
          parseFloat(web3.utils.fromWei(data.price)) * tokenPrice.BNB.rate
        ).toString();
        return data;
      });

      cypressResult = cypressResult.map((item: ISellerSale) => {
        sellerWallets.push(item.seller);
        const data = { ...item };

        data.price = (
          parseFloat(web3.utils.fromWei(data.price)) * tokenPrice.KLAY.rate
        ).toString();
        return data;
      });

      baobabResult = baobabResult.map((item: ISellerSale) => {
        sellerWallets.push(item.seller);
        const data = { ...item };

        data.price = (
          parseFloat(web3.utils.fromWei(data.price)) * tokenPrice.KLAY.rate
        ).toString();
        return data;
      });

      const sellerInfos = await getProfile(sellerWallets);

      sellerSalesLists = [
        ...rinkebyResult,
        ...bscTestResult,
        ...cypressResult,
        ...baobabResult,
      ];

      sellerInfos.result.map((info: IProfile) => {
        sellerSalesLists = sellerSalesLists.map((item: ISellerSale) => {
          if (item.seller.toLowerCase() === info.wallet?.toLowerCase()) {
            item.sellerInfo = info;
          }
          return item;
        });
      });

      setSellerSalesList(sellerSalesLists);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    getSellerSalesList();
  }, []);

  return (
    <section
      className="about-us-area section-padding-150 clearfix"
      // style={{ paddingTop: 100 }}
    >
      <div className="container">
        <InfoComponent
                    titleSm={t('home.rankingText1')}
                    titleLg={t('home.rankingText2')}
                    text={""}
                    noAnimation
                />
        <div className="row align-items-center">
          {isLoading ? (
            Array(1)
              // @ts-ignore
              .fill()
              .map((_, i: number) => {
                return (
                  <div className="home-contents-wrapper" key={i}>
                    <div className="ranker-wrapper">
                      {Array(4)
                        // @ts-ignore
                        .fill()
                        .map((_, j: number) => (
                          <TopSellersItemPlaceholder key={j} />
                        ))}
                    </div>
                  </div>
                );
              })
          ) : (
            <>
              {selletSalesList.slice(0, 3).length > 0 && (
                <div className="home-contents-wrapper">
                  <div className="ranker-wrapper">
                    {selletSalesList.slice(0, 3).map((item, i) => (
                        <TopSellersItem
                        key={i}
                        rank={i + 1}
                        title={getShortAddress(item.seller)}
                        price={item.price}
                        sellerInfo={item.sellerInfo}
                      />
                    ))}
                  </div>
                </div>
              )}

              {/*{selletSalesList.slice(4, 7).length > 0 && (*/}
              {/*  <div className="col-12 col-lg-4 mt-s">*/}
              {/*    <div className="creator-sec dd-bg">*/}
              {/*      {selletSalesList.slice(4, 7).map((item, i) => (*/}
              {/*        <TopSellersItem*/}
              {/*          key={i}*/}
              {/*          rank={i + 5}*/}
              {/*          title={getShortAddress(item.seller)}*/}
              {/*          price={item.price}*/}
              {/*          sellerInfo={item.sellerInfo}*/}
              {/*        />*/}
              {/*      ))}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}

              {/*{selletSalesList.slice(8, 11).length > 0 && (*/}
              {/*  <div className="col-12 col-lg-4 mt-s">*/}
              {/*    <div className="creator-sec dd-bg">*/}
              {/*      {selletSalesList.slice(8, 11).map((item, i) => (*/}
              {/*        <TopSellersItem*/}
              {/*          key={i}*/}
              {/*          rank={i + 9}*/}
              {/*          title={getShortAddress(item.seller)}*/}
              {/*          price={item.price}*/}
              {/*          sellerInfo={item.sellerInfo}*/}
              {/*        />*/}
              {/*      ))}*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*)}*/}
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default TopSellersContainer;
