import '../../assets/css/home.css';
// import './home.css'
import Head from '../../layouts/Head';
import HomeContainer from '../../containers/Home';
import Footer from '../../layouts/Footer';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <>
      <Head Title={t('home.title')} />
      <HomeContainer />
      <Footer />
    </>
  );
};

export default Home;
