import NameInput from './NameInput';
import { useTranslation } from 'react-i18next';
import React from 'react';
import emailjs from '@emailjs/browser';
import { useSetRecoilState } from 'recoil';
import toastAtom from '../../../atoms/toast';

const ContactForm = () => {
  const { t } = useTranslation();
  const setToast = useSetRecoilState(toastAtom);

  const data = [
    {
      Class: 'col-12 col-md-6',
      delay: 300,
      name: 'name',
      title: t('contact.nameTitle'),
    },
    {
      Class: 'col-12 col-md-6',
      delay: 400,
      name: 'email',
      title: t('contact.emailTitle'),
    },
    {
      Class: 'col-12',
      delay: 500,
      name: 'subject',
      title: t('contact.subjectTitle'),
    },
  ];

  const form = React.useRef();

  const sendEmail: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_3xqssxv',
        'template_ikyv2uq',
        // @ts-ignore
        form.current,
        'user_yNl1fiVudp7TpHkhL9HJ1'
      )
      .then(
        (result) => {
          setToast({
            type: 'success',
            message: t('message.contact.success'),
            isShow: true,
          });

          console.log(result.text);

          // @ts-ignore
          form.current.reset();
        },
        (error) => {
          setToast({
            type: 'danger',
            message: t('message.contact.fail'),
            isShow: true,
          });

          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8">
          <div className="contact_form">
            {/* @ts-ignore */}
            <form ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-12">
                  <div id="success_fail_info"></div>
                </div>
                {data &&
                  data.map((item, i) => (
                    <NameInput
                      Class={item.Class}
                      name={item.name}
                      title={item.title}
                      delay={item.delay}
                    />
                  ))}

                <div className="col-12">
                  <div
                    className="group"
                    data-aos-delay="600"
                    data-aos="fade-up"
                  >
                    <textarea
                      name="message"
                      id="message"
                      placeholder={`${t('contact.contentTitle')} *`}
                      required
                      onInput={(e: any) => e.target.setCustomValidity('')}
                      onInvalid={(e: any) =>
                        e.target.setCustomValidity(
                          `${t('contact.contentTitle')}${t(
                            'contact.requiredText'
                          )}`
                        )
                      }
                    ></textarea>
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    {/* <label>{t('contact.contentTitle')}</label> */}
                  </div>
                </div>
                <div
                  className="col-12 text-center"
                  data-aos-delay="700"
                  data-aos="fade-in"
                >
                  <button type="submit" className="more-btn">
                    {t('contact.submitButton')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
