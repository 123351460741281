const addresses = {
  nft: {
    1: '0x6037a4E625B88F162bC328A3E4e7F4c03C16EA59', // Mainet
    3: '0x7C50d8E441FAFB0F63aE7A6f14bFebAf54fBE1A8', // Ropsten
    4: '0x7C50d8E441FAFB0F63aE7A6f14bFebAf54fBE1A8', // Rinkeby
    5: '0x7C50d8E441FAFB0F63aE7A6f14bFebAf54fBE1A8', // Goerli
    42: '0x7C50d8E441FAFB0F63aE7A6f14bFebAf54fBE1A8', // Kovan
    56: '0xC1B155d07baa931eC6dC918fC1024911549C8b7D', // Bsc
    97: '0x40Fd9b68dBD8EcD0a8A3C26757e2797eA90b06D7', // Bsc Testnet
    1001: '0xb7190E81777ec149279e2cc95b1b1b4a6EB5472f', // Baobab Test Network
    1337: '0x7C50d8E441FAFB0F63aE7A6f14bFebAf54fBE1A8', // Localhost
    8217: '0x2aF5700591899221b04EF5D43C9A0E201BAa690D', // Cypress Main Network
  },
  nft1155: {
    1: '0x439895b3450C6C2e1b10f963FBFf757555D09b8e', // Mainet
    3: '0x7C50d8E441FAFB0F63aE7A6f14bFebAf54fBE1A8', // Ropsten
    4: '0x7C50d8E441FAFB0F63aE7A6f14bFebAf54fBE1A8', // Rinkeby
    5: '0x7C50d8E441FAFB0F63aE7A6f14bFebAf54fBE1A8', // Goerli
    42: '0x7C50d8E441FAFB0F63aE7A6f14bFebAf54fBE1A8', // Kovan
    56: '0xC1B155d07baa931eC6dC918fC1024911549C8b7D', // Bsc
    97: '0xC1Ceb1103F3B036a94f7dD090B101B2dFD33A5Dc', // Bsc Testnet
    1001: '0xb7190E81777ec149279e2cc95b1b1b4a6EB5472f', // Baobab Test Network
    1337: '0x7C50d8E441FAFB0F63aE7A6f14bFebAf54fBE1A8', // Localhost
    8217: '0x2aF5700591899221b04EF5D43C9A0E201BAa690D', // Cypress Main Network
  },
  nftMarket: {
    1: '0xc13d1D042b72A829a9c2F3D08f8A4f93eFe79E43', // Mainet
    3: '0x5bAAaf20AAA9534057AbAB0044D672ef83c72bcC', // Ropsten
    4: '0x5bAAaf20AAA9534057AbAB0044D672ef83c72bcC', // Rinkeby
    5: '0x5bAAaf20AAA9534057AbAB0044D672ef83c72bcC', // Goerli
    42: '0x5bAAaf20AAA9534057AbAB0044D672ef83c72bcC', // Kovan
    56: '0x1301979C47716E652549eb3A9f0c7f4cc3c11cc4', // Bsc
    97: '0x6037a4E625B88F162bC328A3E4e7F4c03C16EA59', // Bsc Testnet
    1001: '0x78b9366459bc5A0F9C4Bd18A8f4f17AB3625Bcaf', // Baobab Test Network
    1337: '0x5bAAaf20AAA9534057AbAB0044D672ef83c72bcC', // Localhost
    8217: '0x328eBd2882d6cB5FdCCDf9b7321ad88AcE45e214', // Cypress Main Network
  },
};

export default addresses;
