import React from "react";
import InfoComponent from "../InfoComponent";
import LiveAuctionsItem from "../LiveAuctionsItem";

import "./liveAuctions.css";
import {IAuction} from "../../types";
import {getTokenInfo, getTokenPrice} from "../../utils";
import {web3BSC, web3BSCTest, web3Main} from "../../connection/web3";
import NFTMarketCollection from "../../abis/NAMarketV2.json";
import addresses from "../../constants/addresses";
import {caverAPI} from "../../connection/caver";
import {useTranslation} from "react-i18next";
import HomeAuctionsItemPlaceholder from "../HomeAuctionsItemPlaceholder";

function LiveAuctionsContainer() {
    const {t} = useTranslation();
    const [auctions, setAuctions] = React.useState<IAuction[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const web3 =
        process.env.NODE_ENV === "production"
            ?
            [{web3: web3Main, chainId: 1}]
            :
            [
                {web3: web3Main, chainId: 1},
                {web3: web3BSC, chainId: 56},
                {web3: web3BSCTest, chainId: 97},
                {web3: caverAPI, chainId: 8217},
            ];

    const getAuctions = async () => {
        setIsLoading(true);
        getTokenPrice();
        try {
            const web3Contract = web3.map(({web3, chainId}) => {
                if (chainId === 8217) {
                    // @ts-ignore
                    return new web3.contract(NFTMarketCollection.abi, addresses.nftMarket[chainId]);
                } else {
                    // @ts-ignore
                    return new web3.eth.Contract(NFTMarketCollection.abi, addresses.nftMarket[chainId]);
                }
            });

            const web3AuctionPromises =
                web3Contract.map((contract) =>
                    contract.methods
                        .getOpenAuctions(0, 1, "", 0, 10)
                        .call()
                );

            const auctionsArray = await Promise.allSettled(web3AuctionPromises);

            const auctionsArrays = auctionsArray.filter((auction) => {
                return auction.status === "fulfilled"
                // @ts-ignore
            }).map((auction) => auction);

            let resultArray: any[] = [];

            auctionsArrays.map(auctions => {
                // @ts-ignore
                auctions.value[0].map(auction => {
                    // 토큰 타입별로 1155주소나 721주소 가져옴
                    let tokenAddresses = auction.auctionTypes.tokenType === '2' ? addresses.nft1155 : addresses.nft;
                    // 주소들 키값 가져옴
                    let keys = Object.keys(tokenAddresses);
                    let chainId = 0;

                    // 주소들 키값 map
                    keys.map((key) => {
                        // 주소들 키값의 값이 auction.contractAddress와 같으면 해당 chainId를 가져옴
                        // @ts-ignore
                        if (tokenAddresses[key] === auction.contractAddress) {
                            chainId = parseInt(key);
                        }
                    });
                    const auctionItem = {...auction}
                    auctionItem.chainId = chainId;
                    resultArray.push(auctionItem);
                })
            })

            const itemResults = resultArray.map(async (auction) => {
                const tokenInfo: any = await getTokenInfo(parseInt(auction.tokenId), parseInt(auction.chainId),
                    auction.auctionTypes.tokenType === "2");
                if (tokenInfo) {
                    tokenInfo.chainId = auction.chainId;
                    tokenInfo.is1155 = auction.auctionTypes.tokenType === "2";
                    tokenInfo.count = auction.auctionTypes.quantity;
                }
                return {...auction, tokenInfo}
            })


            const auctions = await Promise.allSettled(itemResults);

            setAuctions(
                auctions.filter((auction) => {
                    return auction.status === "fulfilled"
                    // @ts-ignore
                }).map((auction) => auction.value));

        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    React.useEffect(() => {
        getAuctions();
    }, []);

    return (
        <section className="features  section-padding-50 ">
            <div className="container">
                <InfoComponent
                    titleSm={t("home.infoTitleSm")}
                    titleLg={t("home.infoTitleLg")}
                    text={t("home.infoText")}
                    noAnimation
                />
                {isLoading ?
                    (Array(1)
                        // @ts-ignore
                        .fill()
                        .map((_, i: number) => {
                            return (
                                <div className="row align-items-start" key={i}>
                                    {Array(4)
                                        // @ts-ignore
                                        .fill()
                                        .map((_, j: number) => (
                                            <HomeAuctionsItemPlaceholder key={j}/>
                                        ))}
                                </div>
                            );
                        }))
                    :
                    <div className="row align-items-start">
                        {auctions.map((item, i) => (
                            <LiveAuctionsItem key={i} {...item} />
                        ))}
                    </div>
                }
            </div>
        </section>
    );
}

export default LiveAuctionsContainer;
