import Breadcumb from '../../components/Breadcumb';
// import CollectionItem from './CollectionItem';
import CreatorSec from './CreatorSec';

import '../../assets/css/createItem.css';
import { useTranslation } from 'react-i18next';

const CreateItemContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <Breadcumb
        namePage={t('createItem.breadcumbNamePage')}
        title={t('createItem.breadcumbTitle')}
        useTitle={false}
      /> */}
      <section className="blog-area section-padding-100">
        <div className="container pt-5">
          <div className="row justify-content-center">
            {/* <CollectionItem /> */}

            <div className="col-12 col-lg-8">
              <CreatorSec />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateItemContainer;
