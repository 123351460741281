import React from 'react';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Detailed = ({file}: { file?: string }) => {
    const [isVideo, setIsVideo] = React.useState<boolean>(false);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    return (
        <>
            <div className="col-12 col-lg-5">
                <div className="detailed-img">
                    {file ? (
                        isVideo ? (
                            <video
                                src={file}
                                style={{width: '100%'}}
                                autoPlay
                                playsInline
                                loop
                                muted
                                controls
                            />
                        ) : (
                            <>
                                <img
                                    onClick={() => setIsOpen(true)}
                                    src={file}
                                    alt=""
                                    style={{width: "100%"}}
                                    onError={() => setIsVideo(true)}
                                />
                                {isOpen && (
                                    <div style={{width: "100%", height: "100vh", position: "absolute" ,top: "0" }}
                                         onClick={() => setIsOpen(false)}>
                                        <Lightbox
                                            mainSrc={file}
                                            onCloseRequest={() => setIsOpen(false)}
                                        >
                                        </Lightbox>
                                    </div>

                                )}
                            </>
                        )
                    ) : (
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{aspectRatio: '1'}}
                        >
                            <div className="spinner-border text-primary"></div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Detailed;
