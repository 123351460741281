import React from 'react';

function InfoComponent({
  titleSm,
  titleLg,
  titleLgRight,
  text,
  noAnimation,
}: {
  titleSm: string;
  titleLg: string;
  titleLgRight?: React.ReactNode;
  text: string;
  noAnimation?: boolean;
}) {
  return (
    <div className="section-heading text-center">
      <div
        className="dream-dots justify-content-center"
        data-aos={noAnimation ? 'none' : 'fade-up'}
        data-aos-delay="200"
      >
        <span>{titleSm}</span>
      </div>
      <h2
        data-aos={noAnimation ? 'none' : 'fade-up'}
        data-aos-delay="300"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {titleLg}
        {titleLgRight}
      </h2>
      <p
        data-aos={noAnimation ? 'none' : 'fade-up'}
        data-aos-delay="400"
        style={{ whiteSpace: 'pre-line' }}
      >
        {text}
      </p>
    </div>
  );
}

export default InfoComponent;
