import { atom } from 'recoil';

interface IToast {
  type:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light';
  message: string;
  isShow: boolean;
}

const toastAtom = atom<IToast>({
  key: 'toast',
  default: {
    type: 'primary',
    message: '',
    isShow: false,
  },
});

export default toastAtom;
