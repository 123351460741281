import Caver from 'caver-js';
// @ts-ignore
import CaverExtKAS from 'caver-js-ext-kas'

// @ts-ignore
// const caver = new Caver(new Caver().transaction.smartContractExecution)
const caver = new Caver(new Caver.providers.HttpProvider('https://api.baobab.klaytn.net:8651/'))

export const caverAPI = new CaverExtKAS(8217, 'KASKNJ1RVBJRL2GFW1BLV42V', '6iIpVJ4rTF_YJBiFzfYO-oIZjlXY8M6Tpq-AM4Mc')

export default caver;