import $ from 'jquery';
// @ts-ignore
import jQueryBridget from 'jquery-bridget';
import Isotope from 'isotope-layout';
import 'isotope-cells-by-row';
import {
  web3BSCTest,
  web3Baobab,
  web3Rinkeby,
  web3Cypress,
  web3BSC,
} from '../connection/web3';
import NFTCollection from '../abis/NFT.json';
import NFT1155Collection from '../abis/KPNFT1155.json';
import { AbiItem } from 'web3-utils';
import addresses from '../constants/addresses';
import { caverAPI } from '../connection/caver';
import { web3Main } from '../connection/web3';
import { ipfsUrl } from '../constant';

let portfolioIsotope: any = null;

function SortingCard() {
  jQueryBridget('isotope', Isotope, $);

  portfolioIsotope = null;

  portfolioIsotope = $('.dream-portfolio').isotope({
    itemSelector: '.single_gallery_item',
    layoutMode: 'fitRows',
  });

  $('.portfolio-menu button').on('click', function () {
    $('.portfolio-menu button').removeClass('active');
    $(this).addClass('active');

    portfolioIsotope.isotope({
      filter: $(this).data('filter'),
    });
  });
}

function Addshrink() {
  let RelBanner: any = document.querySelector('#banner');

  window.addEventListener('scroll', (e) => {
    if (window.pageYOffset > 86) {
      RelBanner.classList.add('shrink');
    } else {
      if (!$('#collapsibleNavbar').hasClass('show')) {
        RelBanner.classList.remove('shrink');
      }
    }
  });
  $('.navbar-toggler').on('click', function () {
    if ($('#collapsibleNavbar').hasClass('show')) {
      $('#banner').removeClass('shrink');
    } else {
      $('#banner').addClass('shrink');
    }
  });
}

function loader() {
  var fadeTarget: any = document.getElementById('preloader');

  function fadeOutEffect() {
    var fadeEffect = setInterval(function () {
      if (fadeTarget.style.opacity > 0) {
        fadeTarget.style.opacity -= 0.1;
      } else {
        clearInterval(fadeEffect);
        fadeTarget.style.display = 'none';
      }
    }, 100);
  }

  // @ts-ignore
  window.onload = setTimeout(fadeOutEffect, 1000);
}

const getTokenInfo = async (id: number, chainId: number, isMulti?: boolean) => {
  try {
    let contract = new web3Main.eth.Contract(
      NFTCollection.abi as unknown as AbiItem,
      // @ts-ignore
      addresses.nft[chainId]
    );
    let contract1155 = new web3Main.eth.Contract(
      NFT1155Collection.abi as unknown as AbiItem,
      // @ts-ignore
      addresses.nft1155[chainId]
    );
    // bsc
    if (chainId === 56) {
      contract = new web3BSC.eth.Contract(
        NFTCollection.abi as unknown as AbiItem,
        // @ts-ignore
        addresses.nft[chainId]
      );
      contract1155 = new web3BSC.eth.Contract(
        NFT1155Collection.abi as unknown as AbiItem,
        // @ts-ignore
        addresses.nft1155[chainId]
      );
    }
    if (chainId === 97) {
      contract = new web3BSCTest.eth.Contract(
        NFTCollection.abi as unknown as AbiItem,
        // @ts-ignore
        addresses.nft[chainId]
      );
      contract1155 = new web3BSCTest.eth.Contract(
        NFT1155Collection.abi as unknown as AbiItem,
        // @ts-ignore
        addresses.nft1155[chainId]
      );
    }
    // klaytn
    if ([1001].includes(chainId)) {
      contract = new web3Baobab.eth.Contract(
        NFTCollection.abi as unknown as AbiItem,
        // @ts-ignore
        addresses.nft[chainId]
      );
    }
    if ([8217].includes(chainId)) {
      contract = new caverAPI.contract(
        // @ts-ignore
        NFTCollection.abi,
        // @ts-ignore
        addresses.nft[chainId]
      );
      contract1155 = new caverAPI.contract(
        // @ts-ignore
        NFT1155Collection.abi,
        // @ts-ignore
        addresses.nft1155[chainId]
      );
    }

    let hash;
    if (isMulti) {
      hash = await contract1155.methods.uri(id).call();
    } else {
      hash = await contract.methods.tokenURI(id).call();
    }

    let response = await fetch(`https://ipfs.infura.io/ipfs/${hash}?clear`);
    if (!response.ok) {
      throw new Error('Something went wrong');
    }

    const metadata = await response.json();

    let isApproved = false;
    let owner = zeroAddress;

    if (isMulti) {
      // owner = await contract.methods.owner(id).call();
    } else {
      isApproved = await contract.methods.getApproved(id).call();
      owner = await contract.methods.ownerOf(id).call();
    }

    return {
      id,
      title: metadata.properties.name.description,
      description: metadata.properties.description.description,
      category: metadata.properties.category
        ? metadata.properties.category.description
        : 1,
      artist: metadata.properties.artist
        ? metadata.properties.artist.description
        : '',
      website: metadata.properties.website
        ? metadata.properties.website.description
        : '',
      twitter: metadata.properties.twitter
        ? metadata.properties.twitter.description
        : '',
      facebook: metadata.properties.facebook
        ? metadata.properties.facebook.description
        : '',
      instagram: metadata.properties.instagram
        ? metadata.properties.instagram.description
        : '',
      chainId: metadata.properties.chainId
        ? metadata.properties.chainId.description
        : 1,
      img:
        metadata.properties.image.description.indexOf(ipfsUrl) !== -1
          ? metadata.properties.image.description
          : `${ipfsUrl}${metadata.properties.image.description}`,
      isApproved,
      owner,
    };
  } catch (e) {
    console.log(e);
    return;
  }
};

const getShortAddress = (address: string) => {
  return `${address.substr(0, 7)}...${address.substr(address.length - 7)}`;
};

const getSymbolText = (chainId: number) => {
  // ethereum
  if ([1, 3, 4, 5, 42].includes(chainId)) {
    return 'ETH';
  }
  // bsc
  if ([56, 97].includes(chainId)) {
    return 'BNB';
  }
  // klaytn
  if ([1001, 8217].includes(chainId)) {
    return 'KLAY';
  }
};

const getTokenPrice = async () => {
  try {
    const data = await fetch(
      new Request(
        `https://api.coingecko.com/api/v3/simple/price?ids=ethereum,binancecoin,klay-token&vs_currencies=usd,krw`
      ),
      {
        method: 'GET',
        headers: new Headers({
          'content-type': 'application/json',
        }),
      }
    ).then(async (res) => {
      return await res.json();
    });

    return data;
  } catch (_) {
    return 0;
  }
};

const zeroAddress = '0x0000000000000000000000000000000000000000';

const getFileType = async (url: string) => {
  const response = await fetch(url);

  return response.body;
};

export {
  SortingCard,
  Addshrink,
  loader,
  getTokenInfo,
  getShortAddress,
  getSymbolText,
  getTokenPrice,
  zeroAddress,
  getFileType,
};
