import * as React from 'react';
import Footer from '../../layouts/Footer';
import Head from '../../layouts/Head';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';

interface IPolicyProps {}

const Policy: React.FunctionComponent<IPolicyProps> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <Head Title={t('home.title')} />
      <Container className="pt-5">
        <Row>
          <Col className="py-5">
            <p
              className="text-white"
              style={{ whiteSpace: 'pre-line' }}
            >{`K-POP NFT 이용약관
            제 1조 (목적)
            본 이용약관은 K-POP NFT 이용약관에 동의한 K-POP NFT 회원(이하 "회원"이라고 합니다)이 주식회사 싸이클럽(이하 "회사"라고 합니다)이 제공하는 K-POP NFT 서비스(https://www.kpopnft.co.kr) 내에서 NFT(본 약관 제2조에서 정의합니다)를 거래함에 있어 회사와 회원의 권리와 의무, 기타 필요한 사항을 규정함을 목적으로 합니다.
            
            제 2조 (정의)
            "NFT"란, 대체 불가능한 토큰(Non-Fungible Token)으로서, (i)이미지, 동영상, 음악, 서적 등의 "디지털 저작물"에 대한 메타데이터 정보를 포함한 상태로 발행되어, (ii)해당 NFT 소유자에게 상기 "디지털 저작물"에 관한 특정 권리(이하 "NFT 소유자의 권리"라고 합니다)가 부여되는 블록체인 기반의 디지털 자산을 의미합니다.
            "디지털 저작물"이란, NFT에 연계된 디지털 이미지, 동영상, 음악, 서적 등의 디지털 저작물을 의미합니다.
            "NFT 소유자의 권리"란, NFT가 K-POP NFT에서 최초로 판매되는 시점에 고지된 권리를 의미합니다. "NFT 소유자의 권리"는 NFT에 연계된 "디지털 저작물"을 일정 한도 내에서 이용할 수 있는 권한을 의미할 뿐 "디지털 저작물" 자체에 대한 소유권, 저작권을 포함한 지식재산권, 기타 권리를 취득하는 것을 의미하는 것은 아닙니다. 각 NFT 별로 "NFT 소유자의 권리"의 내용은 다를 수 있습니다.
            "디지털 저작물 온라인 전시장"이란, NFT와 연계된 "디지털 저작물"을 조회하고 감상할 수 있는 웹사이트 및 앱 등의 온라인 화면 및 그 구현에 필요한 서버, 데이터베이스 등을 의미합니다.
            "NFT 마켓플레이스"란, NFT를 거래할 수 있는 온라인 상의 장소 및 그 구현에 필요한 서버, 데이터베이스 등을 의미합니다. 별도의 기재가 없는 한 본 약관에서 "NFT 마켓플레이스"라 함은 회사가 제공하는 서비스인  K-POP NFT를 의미합니다.
            "NFT 발행인"이란, 다음 각 목의 행위를 하는 데 필요한 일체의 권한(이하 "NFT IP"라고 합니다)을 적법하게 확보한 후, NFT를 발행하여 최초로 배포하는 자를 의미합니다. 일반적으로는 NFT와 연계된 디지털 저작물을 제작한 자가 "NFT 발행인"이 됩니다.
            "디지털 저작물" 제작 [(i) "디지털 저작물" 자체에 대한 지식재산권을 취득하는 경우, (ii) 원저작물 저작권자와의 디지털 저작물 제작 위탁계약 등을 통하여 "디지털 저작물" 제작에 필요한 지식재산권 또는 이에 관한 라이선스를 취득하는 경우를 포함한다]
            직접 또는 타인이 제공하는 솔루션 및/또는 거래 중개 서비스를 이용하여 "디지털 저작물"과 연계된 NFT 발행 및 유통
            발행 및 유통된 NFT를 소유한 자에게 "NFT 소유자의 권리" 보장
            “NFT 판매자”란, NFT를 판매할 의사로 해당 NFT를 회사가 온라인으로 제공하는 양식에 맞추어 등록한 자를 의미합니다.
            “NFT 구매자”란, NFT를 구매할 의사로 해당 NFT를 회사가 온라인으로 제공하는 양식에 맞추어 등록한 자를 의미합니다.
            "외부 출금"이란, 회사가 운영하는 디지털 자산 지갑으로부터 회사가 아닌 다른 디지털 자산 사업자가 운영하는 디지털 자산 지갑주소 또는 외부의 개인 지갑주소로 디지털 자산을 출금하는 행위를 의미합니다.
            “디지털 자산”이란, ERC-721 표준, ERC-1155 표준 또는 기타 유사한 대체 불가 토큰 표준을 준수하는 이더리움(ETH) 기반 토큰, KIP-17 표준 또는 기타 유사한 대체 불가 토큰 표준을 준수하는 클레이튼(KLAY) 기반 토큰, BEP-20 표준 또는 기타 유사한 대체 불가 토큰 표준을 준수하는 바이낸스(BSC) 기반 토큰 및 기타 메인넷을 사용하는 토큰을 의미합니다.
            “토큰”이란, 서비스 내에서 NFT 거래에 사용할 수 있는 가상의 지급수단을 말합니다. 
            
            제 3조 (NFT 소유자의 권리)
            NFT를 보유한 자에게는 "NFT 소유자의 권리"의 내용에 따라 NFT와 연계된 디지털 저작물을 이용할 수 있는 권리만이 부여됩니다. NFT는 저작권, 상표권을 포함한 지식재산권이나 초상권, 상품화 권리 등의 특정 권리 그 자체를 의미하지 않으며, NFT를 보유하는 것이 NFT와 연계된 디지털 저작물에 대한 보유 내지 디지털 저작물에 관한 저작권 등의 권리를 취득하는 것을 의미하지 않습니다.
            "NFT 소유자의 권리"는 NFT를 적법하게 보유하고 있는 동안에만 행사할 수 있으며, "NFT 소유자의 권리"는 권리를 행사하는 시점에 해당 NFT를 보유한 자에게 인정됩니다. 타인에게 NFT를 이전한 경우, NFT를 양도한 회원은 "NFT 소유자의 권리"를 더 이상 행사할 수 없습니다. "회사"가 공식적으로 지원하는 방식이 아닌 방식 및/또는 관련 법령을 위반하는 방식으로 NFT를 이전받은 자에게는 "NFT 소유자의 권리"가 보장되지 않을 수 있습니다.
            
            제 4조 (서비스 이용 관련 유의사항)
             K-POP NFT에서 거래되는 NFT는 이더리움(ETH)이 운영하는 이더리움 메인넷의 ERC-721, ERC-1155 프로토콜, 카카오 자회사 그라운드X가 운영하는 클레이튼 메인넷의 KIP-17 프로토콜, 바이낸스가 운영하는 바이낸스 메인넷의 BEP-721 프로토콜을 포함한 멀티체인에 기반한 NFT입니다. "NFT 발행인"은 해당 블록체인 운영사에서 제공하는 솔루션을 활용하여 NFT를 발행합니다. 
            회사는 서비스와 관련하여 이 약관에 명시되지 않은 어떠한 사항에 대하여 보증을 하지 않습니다. 또한, 회사는 회사가 발행하거나 지급을 보증하지 아니한 디지털 자산의 가치를 보증하지 않습니다.
            회사는 천재지변, 디도스(DDos) 공격, IDC 장애, 서비스 접속의 폭등으로 인한 서버 다운, 기간통신사업자의 회선 장애 등 기타 불가항력적인 사유로 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다. 단, 회사의 고의 또는 과실이 있는 경우에는 그러하지 아니합니다.
            회사는 블록체인의 문제, 디지털 자산 발행 관리 시스템 자체의 하자 또는 기술적 문제, 통신서비스 업체의 불량, 정기적인 서버점검 등으로 인하여 불가피하게 장애가 발생하였을 경우 책임을 지지 않습니다. 단, 회사의 고의 또는 과실이 있는 경우에는 그러하지 아니합니다.
            회사는 회원의 귀책사유로 인한 서비스 이용 장애나 그 결과에 대하여는 책임을 지지 않습니다. 단, 회사의 고의 또는 과실이 있거나 회원에게 정당한 사유가 있는 경우에는 그러하지 아니합니다. 
            회사는 회원간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다. 단, 회사의 고의 또는 과실이 있는 경우에는 그러하지 아니합니다.
            회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없거나 회사의 고의 또는 과실이 없는 한 책임을 지지 않습니다.
            회사는 NFT에 연계된 "디지털 저작물"의 진정성, 권리의 완전성 등에 대하여 보증하지 않습니다. 단, 회사의 고의 또는 과실로 인하여 손해가 발생한 경우에는 법령에서 정한 범위 내에서 회사가 책임을 부담합니다.
            다음 각 호의 사유에 해당하는 경우 회사는 NFT 거래 중개 서비스를 일부 또는 전부 종료할 수 있으며, 이 경우 거래 중개 서비스가 종료된 NFT를 보유한 자는 "NFT 소유자의 권리"를 더 이상 행사할 수 없습니다. 단, 회사와 "블록체인 운영사"는, NFT 거래 중개 서비스 제공을 종료한 시점의 NFT 소유자가 NFT에 연계된 디지털 저작물 원본 파일을 종료일로부터 적어도 삼십(30)일간 다운로드할 수 있도록 지원합니다. 위 NFT 소유자는 타인에게 공개되지 않는 개인 저장 공간(제3자가 열람할 수 없도록 설정한 개인 SNS, 웹클라우드, PC 등)에 저장하는 등 개인 소장 목적으로 디지털 저작물을 계속 이용할 수 있습니다. 서비스(개별 디지털 자산의 입출금 지원 포함) 일부 또는 전부 종료에 관한 사전 통지 방법, 종료 후 외부 출금 지원 등 본 조에서 정하지 않은 사항은  K-POP NFT 서비스 이용약관 제15조 제3항과 제4항에서 정한 바에 의합니다.
            "이더리움"이 이더리움 메인넷의 ERC-721 프로토콜의 운영을 중단하는 경우
            국가 기관의 지시, 권고 또는 관련 법령에 의할 때 기존 NFT 관련 서비스의 전부 또는 일부를 종료할 필요가 있는 경우(거래 지원 중인 NFT가 회사의 특수관계인(「상법 시행령」 제34조 제4항 각 호에 따른 특수관계인을 말합니다)이 발행한 것으로 확인된 경우를 포함합니다)
             K-POP NFT 서비스 이용약관 제18조 제4항에서 정하는 서비스 중단 사유에 해당하는 경우
             K-POP NFT 서비스 이용약관 제18조 제5항에서 정하는 디지털 자산 거래지원 종료 사유에 해당하는 경우
            NFT 소유권 이전에 따른 제세공과금이 발생할 경우 이는 NFT 소유권을 이전한 자가 부담하여야 합니다.
            회사는  K-POP NFT 및  K-POP NFT 내 NFT 거래 중개에 관한 홍보 목적으로 NFT에 연계된 디지털 저작물을 이용할 수 있습니다.
            "디지털 저작물 온라인 전시장" 개설 여부는 각 NFT별로 달라질 수 있고, 회사는 각 NFT의 "디지털 저작물 온라인 전시장" 개설을 보장하지 않습니다.
            "NFT 마켓플레이스"에서 제공되는 서비스의 범위(거래 지원 여부, 거래 지원 기간, 입금 및 외부 출금 지원 여부를 포함하며, 이에 한정하지 않습니다)는 각 NFT별로 상이할 수 있으며, 회사는 위 서비스 범위에 관한 독자적인 판단 권한을 갖습니다.
            "NFT 마켓플레이스"에서 배포 및/또는 거래되는 모든 NFT에 대하여, 각 NFT의 발행인, 거래 일시, 거래 당사자인 회원의  K-POP NFT 닉네임, 거래 금액, 현재 판매 여부 및 판매 금액이 서비스 화면 내 전부 또는 일부 공개될 수 있습니다.
            
            제 5조 (금지 행위 및 해지)
            회원은  K-POP NFT에서 유통된 NFT 및 이에 연계된 디지털 저작물과 관련하여 다음 각 호의 행위를 할 수 없습니다.
            디지털 저작물을 수정, 왜곡하여 게시하는 행위
            본인 또는 제3자의 제품 또는 서비스를 광고하는 등 디지털 저작물을 영리 목적으로 이용하는 행위
            디지털 저작물과 연계된 별도의 NFT를 발행하는 행위
            "NFT 소유자의 권리"에 따라 이용 가능한 디지털 저작물을 악용하여, 별도의 지식재산권을 등록, 취득하는 행위
            특정 회원과 통정하여 반복 매매하는 방식 등을 통하여 NFT 가격을 비정상적으로 변동시키는 행위
            회원이 전항을 위반한 경우 회사는 서비스 이용을 제한함과 동시에 시정요구를 할 수 있습니다. 시정요구에도 불구하고 상당한 기간 내에 시정되지 않거나 2회 이상 반복적으로 같은 위반행위를 하는 경우에는  K-POP NFT 서비스 이용계약을 해지할 수 있습니다.
            회원은  K-POP NFT 지갑 내에 NFT를 보유한 상태에서는 서비스를 탈퇴할 수 없습니다. 회원은 보유한 NFT를 처분하거나 출금한 후 서비스를 탈퇴할 수 있습니다. 단, 회원은 회사에게 NFT의 보관을 요청한 후 서비스를 탈퇴할 수 있고, 이 경우 회사는 최대 10년 간 회원이 보관을 의뢰한 NFT를 안전하게 보관합니다.
            본 조에 따라  K-POP NFT 서비스 이용약관 제10조, 제20조, 제21조, 제23조의 적용이 배제되는 것은 아닙니다.
            
            제 6조 (NFT 거래 방법과 회사의 역할)
            NFT 1차 거래는 Korean Action 방식으로 진행되며, Dutch Auction 및/또는 English Auction을 지원합니다.
            회원은 1차 거래 화면 하단에 설명되어 있는 각 경매 방식을 완전히 숙지하고, 거래에 참여해야 합니다.
            가격 조작을 목적으로 한 허위 입찰을 방지하기 위하여 입찰 취소가 제한될 수 있습니다.
            회사는 NFT거래 중개자이며, NFT거래 당사자가 아닙니다. NFT거래에 관한 책임은 판매자가 부담하고, 회사는 판매자를 대리하거나 그 어떠한 보증을 하지 않습니다. 단, 회사의 고의 또는 과실로 인하여 회원에게 손해가 발생한 경우 회사는 그 손해를 배상합니다.
            
            제 7조(디지털 저작물 관련 이의제기)
            "회사"가 제공하는 "NFT 마켓플레이스"를 통하여 유통되는 NFT 및 해당 NFT에 연계된 "디지털 저작물"에 의하여 본인의 저작권 기타 권리가 침해된다고 주장하는 자(이하 "권리주장자")는 해당 사실을 소명하여 "회사"에 해당 NFT의 이용 및 유통을 중단할 것을 요청할 수 있습니다.
            "회사"는 제1항에 따라 NFT의 이용 및 유통 중단을 요구받은 경우에는 즉시 해당 NFT에 대한 "NFT 소유자의 권리" 행사를 중단하고, 권리주장자, NFT를 보유하고 있는 회원 및 NFT 발행인에게 해당 사실을 통보하여야 합니다.
            제2항에 따라 통보를 받은 NFT 발행인이 자신에게 정당한 권리가 있음을 소명하면서 NFT의 이용 및 유통 재개를 요구하는 경우, "회사"는 재개요구사실 및 재개예정일을 권리주장자와 NFT를 보유하고 있는 회원에게 지체없이 통보하고 해당 예정일에 "NFT 소유자의 권리"를 재개합니다. 다만, 권리주장자가 NFT 발행인의 침해행위에 대하여 소를 제기한 사실을 재개예정일 전에 회사에 통보한 경우에는 그러하지 않습니다.
            특정 NFT와 이에 연계된 디지털 저작물에 관한 권리 및/또는 "NFT IP" 관련하여 제3자가 권리 침해를 주장하는 경우, "회사"는 본 조에서 정한 바에 따라 해당 NFT의 거래 중개 등 서비스 제공을 중단하고, 지체없이 권리주장자와 "NFT 발행인"에게 해당 사실을 알릴 것입니다. "회사"는 해당 문제의 해결을 위하여 권리주장자와 "NFT 발행인"간 협의를 주선하여 관련 분쟁이 조속히 해결되도록 노력할 것입니다. 회사는 관련 분쟁이 종결된 후 중단되었던 NFT 거래 중개를 재개합니다. NFT 발행인과 권리주장자 사이의 분쟁이 해결되지 않아 해당 NFT를 보유하고 있는 회원에게 손해가 발생하더라도 회사는 이에 대하여 책임지지 않습니다. 단, 회사의 고의 또는 과실로 인하여 손해가 발생한 경우에는 법령에서 정한 범위 내에서 회사가 책임을 부담합니다.
            
            제 8조 디지털 저작물에 대한 위탁
            위탁자는 회사에 디지털 저작물을 판매 위탁하여 K-POP NFT 내에 옥션 또는 에디션 등의 방식으로 판매되도록 할 수있습니다. 다만, 각 호의 경우에 회사는 위탁자의 판매 위탁을 제한 또는 중지할 수 있습니다.
            디지털 아트 제작에 별도 원화가 존재하는 기존 미술작품의 이미지 등이 사용되었을 시, 해당 미술작품의 원저작권자가 위탁자가 동일 인물임을 확인하기 어렵거나 또는 해당 미술작품의 원저작권자와 위탁자 간 저작권 사용 허가 관계를 확인하기 어려운 경우
            위탁자가 판매 위탁하는 디지털 아트의 성격 또는 특성이 회사의 서비스/콘텐츠 관련 정책과 부합하지 않거나 사회통념 상 부적절하다고 간주될 우려가 있는 경우
            위탁자와 회사 간 디지털 아트의 시작가 또는 판매가에 대한 협의가 도출되지 못할 경우
            위탁자가 판매 위탁하는 디지털 아트에 위탁자가 저작권을 보유하지 않은 저작물이 부적절하게 사용되었을 우려가 있는 경우
            위탁자 등이 판매 위탁된 디지털 아트의 판매 가격 등에 영향을 미치기 위해 옥션 및 에디션 판매에 허위 응찰, 거래 허위 체결, 응찰 시세 조작 등 부정한 방식으로 참여하였음이 확인되거나 참여하였을 우려가 있는 경우
            기타 회사에서 적합하지 않다고 판단되는 경우
            위탁자는 다음 각 호의 의무와 책임을 집니다.
            위탁자는 자신이 판매 위탁하는 디지털 아트를 적법하게 양도할 수 있는 권리를 지녀야 하며, 자신에게 정당한 소유권 또는 원저작권이 있음을 보증할 의무와 책임이 있습니다.
            위탁자는 디지털 아트의 낙찰 또는 판매와 동시에 디지털 아트 소유자에게 배타적으로 양도되는 저작재산권 상의 권리 (작품 원본을 복제할 권리 등) 행사를 중지할 책임이 있습니다.
            위탁자는 자신이 판매 위탁하는 디지털 아트의 소유권 양도가 본 약관 및 기타 모든 유관 법률에 저촉되지 아니함을 보장하여야 합니다.
            위탁자는 디지털 아트의 판매 위탁 등을 임의로 철회할 수 없으며, 부득이하게 철회를 하는 경우에는 판매일이 도래하기 앞서 최소 3영업일 전에 서면으로 회사에 위탁철회 의사를 통보하여야 합니다. 또한, 위탁자의 위탁철회로 회사에 손해가 발생할 경우 회사는 손해배상을 청구할 수 있습니다.
            회사의 고의 또는 과실로 불이익이 발생한 경우가 아닌 한, 회사는 유찰되거나 또는 판매가 이루어지지 않은 디지털 아트에 대하여 어떠한 책임도 지지 않습니다.
             
            제 9조 (청약철회 등)
            회원은 옥션 또는 에디션 판매를 통해 구매한 디지털 아트에 대해 낙찰일 또는 구매일로부터 7일 이내에 계약 철회를 요청할 수 있습니다.
            회원은 구매한 디지털 아트가 다음 각 호에 해당하는 경우에는 철회 또는 환불할 수 없습니다.
            회원의 책임있는 사유로 인해 서비스 내에서 구매한 디지털 아트의 소유권 증명서가 삭제되어 회사가 회원의 작품 소유 여부를 판단하기 어려운 경우
            회원이 디지털 아트 구매 후 제공받은 특전 또는 특별 혜택을 신청 또는 수령하였거나, 온라인 또는 오프라인에서 이를 사용한 이력이 조회될 경우
            회원은 제1항 및 제2항의 규정에도 불구하고 디지털 아트의 내용이 표시 내용과 다르거나 다르게 이행된 경우에는 공급받은 날부터 3개월 이내, 또는 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
            제 10 조 (손해배상)
            회사의 책임 있는 사유로 인하여 회원에게 손해가 발생한 경우 회사의 손해배상 범위는 민법에서 정하고 있는 통상손해를 포함하고, 특별한 사정으로 인한 손해는 회사가 그 사정을 알았거나 알 수 있었을 때에 한하여 배상책임이 있습니다.
            전항에도 불구하고 다음 각 호의 어느 하나에 해당하는 경우에는 회원이 그 책임의 전부 또는 일부를 부담할 수 있습니다.
            회원이 손해 발생의 원인 또는 손해 발생 사실을 알았음에도 불구하고 회사에 즉시 통지하지 않은 경우
            회원이 고의 또는 과실로 제3자에게 지갑 관련 계정 및 거래 정보를 유출하거나 지갑을 사용하게 한 경우
            그 외에 손해 발생에 있어서 회원의 고의나 과실이 있는 경우
            회사는 회사의 책임 없는 사유로 인하여 회원에게 발생한 손해에 대하여는 배상책임이 없습니다.
            회원이 회사에 제공한 정보가 사실과 다를 경우, 회사는 언제든지 서비스의 제공을 중단하고 본 약관에 의해 계약 전체 또는 일부를 해지할 수 있으며, 이로 인하여 회사에 손해가 발생한 경우, 회원에게 그 손해의 배상을 청구할 수 있습니다.
            회원이 회사의 서비스 또는 관련 시스템 운영을 방해하는 등 고의 또는 과실로 법령, 본 약관, 사회질서에 위배하는 행위 등을 통하여 회사에 손해를 발생시킨 경우에는 회사에 발생한 손해를 모두 배상해야 합니다.
            회원이 회사에 손해배상을 청구할 경우 회사는 회원과 합의하여 회원의 지갑으로 디지털 자산을 지급하는 방법으로 회원의 손해를 배상할 수 있습니다.
             
            제 11조 ( K-POP NFT 이용약관과의 관계)
            본 이용약관에 명시되지 않은 사항은  K-POP NFT 이용약관(링크) 및 관계 법령에서 정하는 바에 따르며, 관계 법령에서 정하지 아니한 사항은 상관례를 따릅니다.
            본 이용약관과  K-POP NFT 이용약관이 충돌할 경우 본 이용약관을 우선하여 적용합니다.
            
            제 12조 (준거법 및 분쟁 해결 방법)
            본 약관과 관련된 분쟁 발생 시 대한민국 및 재단 설립지 싱가포르 법을 적용합니다.
            
            <부칙>
            이 약관은 2022년 1월 10일부터 적용됩니다.
            
            `}</p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Policy;
