import {IAuction} from '../../../types/index';
import BigNumber from 'bignumber.js';
import addresses from '../../../constants/addresses';
import contracts from '../../../constants/contracts';
import moment from 'moment';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import walletAccountAtom from '../../../atoms/walletAccount';
import {getSymbolText} from '../../../utils';
import {useParams} from 'react-router';
import IconETH from '../../../assets/img/icons/icon-eth.png';
import IconBNB from '../../../assets/img/icons/icon-bnb.png';
import IconKLAY from '../../../assets/img/icons/icon-klay.png';
import React from 'react';
import tokenPriceAtom from '../../../atoms/tokenPrice';
import {useInterval} from 'usehooks-ts';
import klipRequestKeyAtom from '../../../atoms/klipRequestKey';
// @ts-ignore
import {prepare, request, getResult} from 'klip-sdk';
import {isMobile} from 'react-device-detect';
import {Spinner} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import TestPopup from '../TestPopup';
import {useHistory} from 'react-router-dom';
import isAuctionFinishAtom from '../../../atoms/isAuctionFinish';
import toastAtom from '../../../atoms/toast';
import {PlaceBidABI} from '../../../utils/abis';

const SidebarArea = (props: IAuction) => {
    const history = useHistory();
    const {t} = useTranslation();
    const {chainId}: { chainId?: string } = useParams();
    const setIsAuctionFinish = useSetRecoilState(isAuctionFinishAtom);
    const setToast = useSetRecoilState(toastAtom);

    const categories = [
        t('createItem.categoryItem1'),
        t('createItem.categoryItem2'),
        t('createItem.categoryItem3'),
        t('createItem.categoryItem4'),
        t('createItem.categoryItem5'),
        t('createItem.categoryItem6'),
        t('createItem.categoryItem7'),
    ];

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const walletAccount = useRecoilValue(walletAccountAtom);
    const tokenPrice = useRecoilValue(tokenPriceAtom);
    const [klipRequestKey, setKlipRequestKey] =
        useRecoilState(klipRequestKeyAtom);
    const currentPrice = new BigNumber(parseInt(props.currentPrice)).div(
        new BigNumber(10).pow(18)
    );
    const buyNowPrice = new BigNumber(parseInt(props.buyNowPrice)).div(
        new BigNumber(10).pow(18)
    );

    let rate = tokenPrice.ETH.rate;
    let Icon = IconETH;

    switch (props.tokenInfo?.chainId) {
        case 56:
        case 97:
            rate = tokenPrice.BNB.rate;
            Icon = IconBNB;
            break;
        case 1001:
        case 8217:
            rate = tokenPrice.KLAY.rate;
            Icon = IconKLAY;
            break;
    }

    useInterval(
        () => {
            getResult(klipRequestKey).then(
                (result: {
                    expiration_time: number;
                    request_key: string;
                    result: { klaytn_address: string };
                    klaytn_address: string;
                    status: string;
                }) => {
                    if (result.status === 'completed') {
                        setKlipRequestKey('');
                        reset();
                    }
                }
            );
        },
        isLoading && klipRequestKey ? 1000 : null
    );

    const onBuyNow = async () => {
        const buyNowPrice = new BigNumber(parseInt(props.buyNowPrice))
            .div(new BigNumber(10).pow(18))
            .toNumber();

        setIsLoading(true);

        if (walletAccount.wallet === 'klip') {
            const res = await prepare.executeContract({
                bappName: '싸이클럽',
                from: walletAccount.account,
                to: addresses.nftMarket[8217],
                value: new BigNumber(buyNowPrice * 0.025 + buyNowPrice)
                    .times(new BigNumber(10).pow(18))
                    .toNumber()
                    .toFixed(0), gas: 400000,
                abi: JSON.stringify(PlaceBidABI),
                params: JSON.stringify([
                    props.auctionId,
                    new BigNumber(buyNowPrice)
                        .times(new BigNumber(10).pow(18))
                        .toNumber()
                        .toFixed(0),
                ]),
            });

            if (res.request_key) {
                if (isMobile) {
                    request(res.request_key);
                }
                setKlipRequestKey(res.request_key);
            }
            return;
        }

        try {
            await contracts
                .nftMarketContract(walletAccount.chainId)
                .methods.placeBid(
                    props.auctionId,
                    new BigNumber(buyNowPrice)
                        .times(new BigNumber(10).pow(18))
                        .toNumber()
                        .toFixed(0)
                )
                .send({
                    from: walletAccount.account,
                    value: new BigNumber(buyNowPrice * 0.025 + buyNowPrice)
                        .times(new BigNumber(10).pow(18))
                        .toNumber()
                        .toFixed(0), gas: 400000
                });

            reset();
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const reset = () => {
        setIsAuctionFinish(true);
        setIsLoading(false);
        // setToast((prev) => ({
        //   ...prev,
        //   type: 'success',
        //   message: t('message.placeBid.success1'),
        //   isShow: true,
        // }));
    };

    const renderBtn = () => {
        //'월렛 연결하기' 버튼
        const connectWalletBtn = () => {
            return (
                <button
                    type="button"
                    className="btn open-popup-link more-btn width-100"
                    onClick={() => history.push('/connectWallet')}
                >
                    {t('header.connectWallet')}
                </button>
            );
        };

        //'즉시 구매 하기' 버튼
        const buyNowBtn = () => {
            return (
                <button
                    type="button"
                    disabled={isLoading}
                    className="btn open-popup-link more-btn width-100"
                    onClick={onBuyNow}
                >
                    {isLoading ? (
                        <Spinner animation="border" variant="light"/>
                    ) : (
                        t('itemDetail.buyNow')
                    )}
                </button>
            );
        };

        //즉시구매 아이템의 경우 expireDate 가 현재 날짜와 동일 또는 더 낮을 경우 아래 조건에서 false 가 반환된다.
        if (!moment().isBefore(moment(parseInt(props.expiryDate) * 1000))) {
            //moment().isBefore() = false 를 반환했을 시 is1155를 통해 해당 Item 이 즉시구매 인지 경매인지 확인한다.
            // is1155 = true  이면 즉시구매, false 이면 경매
            if (props.tokenInfo?.is1155) {
                if (!walletAccount.account) {
                    return (
                        connectWalletBtn()
                    );
                } else if (props.tokenInfo?.chainId === walletAccount.chainId) {
                    return (
                        buyNowBtn()
                    );
                }
            }
        } else {
            //moment().isBefore() = true 일시 종료일시가 미래의 날짜이므로 account 여부를 판단하여 버튼을 render
            if (!walletAccount.account) {
                return (
                    connectWalletBtn()
                );
            } else if (props.tokenInfo?.chainId === walletAccount.chainId) {
                return (
                    buyNowBtn()
                );
            }
        }
    };

    React.useEffect(() => {
        if (!klipRequestKey) {
            setIsLoading(false);
        }
    }, [klipRequestKey]);

    // console.log(moment(parseInt(props.expiryDate) * 1000).format());

    return (
        <>
            <div className="col-12 col-lg-4 mt-s">
                <div className="sidebar-area">
                    <div className="donnot-miss-widget">
                        <div className="who-we-contant">
                            <div className="filers-list">
                                {props.tokenInfo ? (
                                    <div className="filter-item">
                                        {categories[props.tokenInfo?.category - 1]}
                                    </div>
                                ) : (
                                    <div className="placeholder-glow w-100 text-white">
                                        <div className="placeholder col-3"></div>
                                    </div>
                                )}
                            </div>
                            <h4>
                                {props.tokenInfo ? (
                                    <>
                                        <div className="d-flex align-items-center justify-content-between">
                                            #{props.tokenId} {props.tokenInfo?.title}
                                            <img
                                                src={Icon}
                                                width={30}
                                                height={30}
                                                alt="Blockchain symbol"
                                            />
                                        </div>
                                        <p
                                            style={{
                                                whiteSpace: 'pre-line',
                                                wordBreak: 'break-all',
                                                maxHeight: 200,
                                                overflowY: 'auto',
                                            }}
                                        >
                                            {props.tokenInfo.description}
                                        </p>
                                    </>
                                ) : (
                                    <div className="placeholder-glow text-white">
                                        <div className="placeholder col-6"></div>
                                    </div>
                                )}
                            </h4>
                        </div>
                        {props.tokenInfo?.is1155 ? (
                            <>
                                <div className="mb-0 gray-text">
                                    {props.tokenInfo ? (
                                        <span className="w-text mr-15">
                                            {t('itemDetail.price')} {buyNowPrice.toString()}{' '}
                                            {/* @ts-ignore */}
                                            {getSymbolText(parseInt(chainId))}{' '}
                                            {rate && (
                                                <>
                                                    {' '}
                                                    ($
                                                    {(rate * buyNowPrice.toNumber()).toFixed(2)})
                                                </>
                                            )}
                                        </span>
                                    ) : (
                                        <div className="placeholder-glow text-white">
                                            <div className="placeholder col-4"></div>
                                        </div>
                                    )}
                                    {/* <span className="gray-text mr-15">$534.22</span> 1/10 */}
                                </div>
                                <div className="mb-15 gray-text">
                                    {props.tokenInfo ? (
                                        <span className="w-text mr-15">
                                            {t('itemDetail.count')} {props.tokenInfo.count}
                                        </span>
                                    ) : (
                                        <div className="placeholder-glow text-white">
                                            <div className="placeholder col-4"></div>
                                        </div>
                                    )}
                                    {/* <span className="gray-text mr-15">$534.22</span> 1/10 */}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="mb-0 gray-text">
                                    {props.tokenInfo ? (
                                        <span className="w-text mr-15">
                                            {t('itemDetail.currentPrice')} {currentPrice.toString()}{' '}
                                            {/* @ts-ignore */}
                                            {getSymbolText(parseInt(chainId))}{' '}
                                            {rate && (
                                                <>
                                                    {' '}
                                                    ($
                                                    {(rate * currentPrice.toNumber()).toFixed(2)})
                                                </>
                                            )}
                                        </span>
                                    ) : (
                                        <div className="placeholder-glow text-white">
                                            <div className="placeholder col-4"></div>
                                        </div>
                                    )}
                                    {/* <span className="gray-text mr-15">$534.22</span> 1/10 */}
                                </div>
                                <div className="mb-15 gray-text">
                                    {props.tokenInfo ? (
                                        <span className="w-text mr-15">
                                            {t('itemDetail.buyNowPrice')} {buyNowPrice.toString()}{' '}
                                            {/* @ts-ignore */}
                                            {getSymbolText(parseInt(chainId))}{' '}
                                            {rate && (
                                                <>
                                                    {' '}
                                                    ($
                                                    {(rate * buyNowPrice.toNumber()).toFixed(2)})
                                                </>
                                            )}
                                        </span>
                                    ) : (
                                        <div className="placeholder-glow text-white">
                                            <div className="placeholder col-4"></div>
                                        </div>
                                    )}
                                    {/* <span className="gray-text mr-15">$534.22</span> 1/10 */}
                                </div>
                            </>
                        )}
                        <div className="details-list">
                            <p>
                                {props.tokenInfo ? (
                                    <>
                                        {t('itemDetail.seller')} :{' '}
                                        <span>
                                            {props.seller.slice(0, 7)}...
                                            {props.seller.slice(props.seller.length - 7)}
                                        </span>
                                    </>
                                ) : (
                                    <div className="placeholder-glow text-white">
                                        <div className="placeholder col-6"></div>
                                    </div>
                                )}
                            </p>
                            <p>
                                {props.tokenInfo ? (
                                    <>
                                        {t('itemDetail.creator')} :{' '}
                                        <span>{props.tokenInfo?.artist || '-'}</span>
                                    </>
                                ) : (
                                    <div className="placeholder-glow text-white">
                                        <div className="placeholder col-6"></div>
                                    </div>
                                )}
                            </p>
                            <ul className="social-links" style={{marginLeft: -2}}>
                                {props.tokenInfo ? (
                                    <>
                                        {props.tokenInfo?.website && (
                                            <li>
                                                <a
                                                    href={props.tokenInfo?.website}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span className="fa fa-link"></span>
                                                </a>
                                            </li>
                                        )}
                                        {props.tokenInfo?.twitter && (
                                            <li>
                                                <a
                                                    href={props.tokenInfo?.twitter}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span className="fa fa-twitter"></span>
                                                </a>
                                            </li>
                                        )}
                                        {props.tokenInfo?.facebook && (
                                            <li>
                                                <a
                                                    href={props.tokenInfo?.facebook}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span className="fa fa-facebook-f"></span>
                                                </a>
                                            </li>
                                        )}
                                        {props.tokenInfo?.instagram && (
                                            <li>
                                                <a
                                                    href={props.tokenInfo?.instagram}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span className="fa fa-instagram"></span>
                                                </a>
                                            </li>
                                        )}
                                        {chainId && getSymbolText(parseInt(chainId)) === 'ETH' && (
                                            <li>
                                                <a
                                                    // @ts-ignore
                                                    href={`https://opensea.io/assets/${addresses.nft1155[chainId]}/${props.tokenInfo?.id}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <span className="fa fa-eye"></span>
                                                </a>
                                            </li>
                                        )}
                                    </>
                                ) : (
                                    <div className="placeholder-glow placeholder-lg text-white">
                                        <div className="placeholder col-12"></div>
                                    </div>
                                )}
                            </ul>
                        </div>
                        {/*<div className="author-item mb-30">*/}
                        {/*    <div className="author-img ml-0">*/}
                        {/*        <img src={authors8} width="70" alt=""/>*/}
                        {/*    </div>*/}
                        {/*    <div className="author-info">*/}
                        {/*        <NavLink to="/profile">*/}
                        {/*            <h5 className="author-name">LarySmith-30</h5>*/}
                        {/*        </NavLink>*/}
                        {/*        <p className="author-earn mb-0">Item Owner</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {renderBtn()}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SidebarArea;
