import styled from "styled-components";

export const Title = styled.h1`
  padding-bottom: 42px;
  border-bottom: 1px solid #404041;
  font-size: 22px;
  font-weight: 700;
`;

export const ContentsBox = styled.div`
  padding-top: 32px;
  padding-bottom: 100px;
  color: #fff;
`;

export const SubTitle = styled.h2`
  margin: 32px 0;
  font-size: 18px;
  font-weight: 700;
`;
export const Contents = styled.div`
  line-height: 22px;
`;

export const OrderedList = styled.ol`
  list-style-type: decimal;
  margin-top: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
`;

export const InnerOrderedList = styled.ol`
  margin-left: 10px;
`;

export const DeeperInnerOrderedList = styled.ol`
  margin-left: 15px;
`;

export const List = styled.li`
  padding: 3px 0;
  font-size: 15px;
  font-weight: 400
`;

export const InnerList = styled.li`
  padding: 10px 0;
  font-weight: 300
`;

export const InnerTitle = styled.p`
  padding-top: 30px;
  font-size: 15px;
`;
export const UnorderedList = styled.ul`
  font-size: 15px;
  /* list-style-type: disc; */

`;

export const TableBox = styled.div`
  margin: 30px 0;
`;

export const TableContainer = styled.div`
  margin: 0 auto;
  background-color: #ffffff;

  /* 모바일 */
  @media (max-width: 576px) {
    width: 343px;
  }

  /* 태블릿 */
  @media (min-width: 577px) and (max-width: 831px) {
    width: 544px;
  }

  /* 웹 */
  @media (min-width: 832px) {
    width: 800px;
  }
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  height: 37px;
  border-top: solid 1px #E7E8E7;
  border-right: solid 1px #E7E8E7;
  border-bottom: solid 1px #E7E8E7;
  background-color: #F9F0F5;

  /* 모바일 */
  @media (max-width: 576px) {
    width: 343px;
  }

  /* 태블릿 */
  @media (min-width: 577px) and (max-width: 831px) {
    width: 544px;
  }

  /* 웹 */
  @media (min-width: 832px) {
    width: 800px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
    margin-top: -2px;
    border-left: solid 1px #E7E8E7;
    font-size: 13px;
    font-weight: 600;
    color: #595959;
    /* 모바일 */
    @media (max-width: 576px) {
      &:nth-child(1) {
        width: 72px;
      }

      &:nth-child(2) {
        width: 118px;
      }

      &:nth-child(3) {
        width: 61px;
      }

      &:nth-child(4) {
        width: 92px;
      }
    }

    /* 태블릿 */
    @media (min-width: 577px) and (max-width: 831px) {
      &:nth-child(1) {
        width: 100px;
      }

      &:nth-child(2) {
        width: 264px;
      }

      &:nth-child(3) {
        width: 70px;
      }

      &:nth-child(4) {
        width: 100px;
      }
    }

    /* 웹 */
    @media (min-width: 832px) {
      &:nth-child(1) {
        width: 180px;
      }

      &:nth-child(2) {
        width: 325px;
      }

      &:nth-child(3) {
        width: 120px;
      }

      &:nth-child(4) {
        width: 207px;
      }
    }
  }
`;

export const TableHeaderContainer_3 = styled(TableHeaderContainer)`
  div {
    /* 모바일 */
    @media (max-width: 576px) {
      &:nth-child(1) {
        width: 72px;
      }

      &:nth-child(2) {
        width: 118px;
      }

      &:nth-child(3) {
        width: 153px;
      }
    }

    /* 태블릿 */
    @media (min-width: 577px) and (max-width: 831px) {
      &:nth-child(1) {
        width: 100px;
      }

      &:nth-child(2) {
        width: 264px;
      }

      &:nth-child(3) {
        width: 180px;
      }
    }

    /* 웹 */
    @media (min-width: 832px) {
      &:nth-child(1) {
        width: 180px;
      }

      &:nth-child(2) {
        width: 325px;
      }

      &:nth-child(3) {
        width: 295px;
      }
    }
  }
`;

export const TableHeaderContainer_2 = styled(TableHeaderContainer)`
  div {
    /* 모바일 */
    @media (max-width: 576px) {
      &:nth-child(1) {
        width: 72px;
      }

      &:nth-child(2) {
        width: 271px;
      }
    }

    /* 태블릿 */
    @media (min-width: 577px) and (max-width: 831px) {
      &:nth-child(1) {
        width: 100px;
      }

      &:nth-child(2) {
        width: 444px;
      }
    }

    /* 웹 */
    @media (min-width: 832px) {
      &:nth-child(1) {
        width: 180px;
      }

      &:nth-child(2) {
        width: 620px;
      }
    }
  }
`;

export const TableHeaderContainer_1 = styled(TableHeaderContainer)`
  div {
    /* 모바일 */
    @media (max-width: 576px) {
      &:nth-child(1) {
        width: 100%;
      }
    }

    /* 태블릿 */
    @media (min-width: 577px) and (max-width: 831px) {
      &:nth-child(1) {
        width: 100%;
      }
    }

    /* 웹 */
    @media (min-width: 832px) {
      &:nth-child(1) {
        width: 100%;
      }
    }
  }
`;

export const TableLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: solid 1px #E7E8E7;
  border-right: solid 1px #E7E8E7;

  @media (max-width: 576px) {
    width: 343px;
  }

  /* 태블릿 */
  @media (min-width: 577px) and (max-width: 831px) {
    width: 544px;
  }

  /* 웹 */
  @media (min-width: 832px) {
    width: 800px;
  }

  div {
    display: flex;
    padding: 8px;
    border-left: solid 1px #E7E8E7;
    font-size: 12px;
    line-height: 16px;
    color: #676767;

    /* 모바일 */
    @media (max-width: 576px) {
      &:nth-child(1) {
        width: 72px;
      }

      &:nth-child(2) {
        width: 118px;
      }

      &:nth-child(3) {
        width: 61px;
      }

      &:nth-child(4) {
        width: 92px;
      }
    }

    /* 태블릿 */
    @media (min-width: 577px) and (max-width: 831px) {
      &:nth-child(1) {
        width: 100px;
      }

      &:nth-child(2) {
        width: 264px;
      }

      &:nth-child(3) {
        width: 70px;
      }

      &:nth-child(4) {
        width: 100px;
      }
    }

    /* 웹 */
    @media (min-width: 832px) {
      &:nth-child(1) {
        width: 180px;
      }

      &:nth-child(2) {
        width: 325px;
      }

      &:nth-child(3) {
        width: 120px;
      }

      &:nth-child(4) {
        width: 207px;
      }
    }
`;

export const TableLineContainer_3 = styled(TableLineContainer)`
  div {
    /* 모바일 */
    @media (max-width: 576px) {
      &:nth-child(1) {
        width: 72px;
      }

      &:nth-child(2) {
        width: 118px;
      }

      &:nth-child(3) {
        width: 153px;
      }
    }

    /* 태블릿 */
    @media (min-width: 577px) and (max-width: 831px) {
      &:nth-child(1) {
        width: 100px;
      }

      &:nth-child(2) {
        width: 264px;
      }

      &:nth-child(3) {
        width: 180px;
      }
    }

    /* 웹 */
    @media (min-width: 832px) {
      &:nth-child(1) {
        width: 180px;
      }

      &:nth-child(2) {
        width: 325px;
      }

      &:nth-child(3) {
        width: 295px;
      }
    }
  }
`;

export const TableLineContainer_2 = styled(TableLineContainer)`
  div {
    /* 모바일 */
    @media (max-width: 576px) {
      &:nth-child(1) {
        width: 72px;
      }

      &:nth-child(2) {
        width: 271px;
      }
    }

    /* 태블릿 */
    @media (min-width: 577px) and (max-width: 831px) {
      &:nth-child(1) {
        width: 100px;
      }

      &:nth-child(2) {
        width: 444px;
      }
    }

    /* 웹 */
    @media (min-width: 832px) {
      &:nth-child(1) {
        width: 180px;
      }

      &:nth-child(2) {
        width: 620px;
      }
    }
  }
`;

export const TableLineContainer_1 = styled(TableLineContainer)`
  div {
    /* 모바일 */
    @media (max-width: 576px) {
      &:nth-child(1) {
        width: 100%;
      }
    }

    /* 태블릿 */
    @media (min-width: 577px) and (max-width: 831px) {
      &:nth-child(1) {
        width: 100%;
      }
    }

    /* 웹 */
    @media (min-width: 832px) {
      &:nth-child(1) {
        width: 100%;
      }
    }
  }
`;