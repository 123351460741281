import Head from '../../layouts/Head';
import SignUpContainer from '../../containers/SignUp';
import Footer from '../../layouts/Footer';

const SignUp = () => {
  return (
    <>
      <Head Title="Signup" />
      <SignUpContainer />
      <Footer />
    </>
  );
};

export default SignUp;
