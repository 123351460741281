import { InjectedConnector } from "@web3-react/injected-connector";
import { KaikasConnector } from 'kaikas-connector';

const isDev = process.env.NODE_ENV !== 'production'

export const injectedConnector = new InjectedConnector({
  supportedChainIds: isDev ? [
    1, // Mainet
    3, // Ropsten
    4, // Rinkeby
    5, // Goerli
    42, // Kovan
    56, // bsc
    97, // bsc testnet
    1001, // Baobab Test Network
    1337, // Localhost
    8217, // Cypress Main Network
  ] : [1]
})

export const kaikasConnector = new KaikasConnector({
  supportedChainIds: [
    1001, // Baobab Test Network
    8217, // Cypress Main Network
  ]
}) 