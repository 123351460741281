import styled from 'styled-components';

const AuthorImg = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 70px;
  overflow: hidden;
  background-color: white;
  background-size: cover;
  background-position: center;

  img {
    width: 100%;
    height: 100%;
  }
`;

function TopSellersItemPlaceholder() {
    return (
        <div className="ranker-item placeholder-glow">
            {/* <div className="author-rank"></div> */}
            <AuthorImg className="author-img placeholder"/>
            {/* <a href="profile.html"> */}
            <h5 className="author-name placeholder w-25" />
            {/* </a> */}
            <p className="author-earn mb-0 placeholder w-25" />
        </div>
    );
}

export default TopSellersItemPlaceholder;
