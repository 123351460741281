import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { RecoilRoot } from 'recoil';
import { Web3ReactProvider } from '@web3-react/core';
import getLibrary from './library';
import { MoralisProvider } from 'react-moralis';
import * as bsc from '@binance-chain/bsc-use-wallet';

import './i18n';

ReactDOM.render(
  <BrowserRouter>
    <RecoilRoot>
      <Web3ReactProvider getLibrary={getLibrary}>
        <bsc.UseWalletProvider chainId={97} connectors={{ bsc }}>
          <MoralisProvider
            appId="J4JJyCjuYIzbstQek5bZ6KAHRY6splkLe4cCUOhf"
            serverUrl="https://ipsnh81feigz.usemoralis.com:2053/server"
          >
            <React.Suspense fallback={<div>로딩</div>}>
              <App />
            </React.Suspense>
          </MoralisProvider>
        </bsc.UseWalletProvider>
      </Web3ReactProvider>
    </RecoilRoot>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
