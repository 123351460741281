import Detailed from './Detailed';
import SidebarArea from './SidebarArea';
import HighestBid from './HighestBid';

import '../../assets/css/itemDetails.css';
import { useParams } from 'react-router-dom';
import React from 'react';
import { IAuction, IItem } from '../../types';
import { getTokenInfo } from '../../utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import isAuctionFinishAtom from '../../atoms/isAuctionFinish';
import { useInterval } from 'usehooks-ts';
import walletAccountAtom from '../../atoms/walletAccount';
import { web3Main, web3BSC, web3Baobab } from '../../connection/web3';
import NFTMarketCollection from '../../abis/NAMarketV2.json';
import addresses from '../../constants/addresses';
import { caverAPI } from '../../connection/caver';
import { web3BSCTest } from '../../connection/web3';

const ItemDetailsContainer = () => {
  const { chainId, id }: { chainId?: string; id?: string } = useParams();
  const [auction, setAuction] = React.useState<IAuction>();

  const walletAccount = useRecoilValue(walletAccountAtom);
  const [isAuctionFinish, setIsAuctionFinish] =
    useRecoilState(isAuctionFinishAtom);

  const getAuction = async () => {
    if (chainId) {
      try {
        let contract = new web3Main.eth.Contract(
          // @ts-ignore
          NFTMarketCollection.abi,
          // @ts-ignore
          addresses.nftMarket[parseInt(chainId)]
        );
        if (parseInt(chainId) === 56) {
          contract = new web3BSC.eth.Contract(
            // @ts-ignore
            NFTMarketCollection.abi,
            addresses.nftMarket[56]
          );
        }
        if (parseInt(chainId) === 97) {
          contract = new web3BSCTest.eth.Contract(
            // @ts-ignore
            NFTMarketCollection.abi,
            addresses.nftMarket[97]
          );
        }
        if ([1001].includes(parseInt(chainId))) {
          // @ts-ignore
          contract = new web3Baobab.eth.Contract(
            // @ts-ignore
            NFTMarketCollection.abi,
            addresses.nftMarket[1001]
          );
        }

        if ([8217].includes(parseInt(chainId))) {
          // @ts-ignore
          contract = new caverAPI.contract(
            // @ts-ignore
            NFTMarketCollection.abi,
            addresses.nftMarket[8217]
          );
        }

        // @ts-ignore
        const result = await contract.methods.auctions(id).call();

        const tokenInfo: IItem | undefined = await getTokenInfo(
          result.tokenId,
          parseInt(chainId),
          result.auctionTypes.tokenType === '2'
        );

        if (tokenInfo) {
          tokenInfo.chainId = parseInt(chainId);
          tokenInfo.is1155 = result.auctionTypes.tokenType === '2';
          tokenInfo.count = result.auctionTypes.quantity;
        }

        setAuction({ ...result, tokenInfo });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useInterval(
    () => {
      getAuction();
      setIsAuctionFinish(false);
    },
    isAuctionFinish ? 1000 : null
  );

  React.useEffect(() => {
    getAuction();
  }, [chainId]);

  return (
    <>
      {/* <Breadcumb
        namePage="NFT 자세히보기"
        title="NFT 자세히보기"
        useTitle={false}
      /> */}
      <section className="section-padding-100">
        <div className="container pt-5">
          <div className="row">
            {auction ? (
              <>
                <Detailed file={auction.tokenInfo?.img} />

                <SidebarArea {...auction} />

                {!auction.tokenInfo?.is1155 && <HighestBid {...auction} />}
              </>
            ) : (
              'No data'
            )}
          </div>
        </div>
      </section>
      {/* {auction &&
        moment().isBefore(moment(parseInt(auction.expiryDate))) &&
        auction.tokenInfo?.chainId === walletAccount.chainId && (
          <TestPopup {...auction} />
        )} */}
    </>
  );
};

export default ItemDetailsContainer;
