import Breadcumb from '../../components/Breadcumb';
import InfoComponent from '../../components/InfoComponent';
import ContactForm from './ContactForm';

const SignUpContainer = () => {
  return (
    <>
      <section className="section-padding-100 contact_us_area" id="contact">
        <div className="container pt-5">
          <div className="row">
            <div className="col-12">
              <InfoComponent
                titleSm=""
                titleLg="회원가입"
                text={
                  'K-POP NFT 이용을 위해서는 회원가입을 진행해야 합니다.\n아래 정보를 입력해주세요.'
                }
              />
            </div>
          </div>

          <ContactForm />
        </div>
      </section>
    </>
  );
};

export default SignUpContainer;
