import * as React from "react";
import { IAuction } from "../../../types/index";
import BigNumber from "bignumber.js";
import { useWeb3React } from "@web3-react/core";
import contracts from "../../../constants/contracts";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import isAuctionFinishAtom from "../../../atoms/isAuctionFinish";
import walletAccountAtom from "../../../atoms/walletAccount";
import { getSymbolText, zeroAddress } from "../../../utils";
import toastAtom from "../../../atoms/toast";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import caver from "../../../connection/caver";
import klipRequestKeyAtom from "../../../atoms/klipRequestKey";
import { useInterval } from "usehooks-ts";
import { NavLink } from "react-router-dom";
// @ts-ignore
import { prepare, request, getResult } from "klip-sdk";
import addresses from "../../../constants/addresses";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { CancelAuctionABI, SettleAuctionABI } from "../../../utils/abis";

interface IMyAuctionProps extends IAuction {
}

const MyAuction: React.FunctionComponent<IMyAuctionProps> = (props) => {
  const { t } = useTranslation();

  const [isShowModal, setIsShowModal] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingCancel, setIsLoadingCancel] = React.useState<boolean>(false);
  const [isLoadingSettle, setIsLoadingSettle] = React.useState<boolean>(false);
  const [isVideo, setIsVideo] = React.useState<boolean>(false);
  const [kaikasPrivateKey, setKaikasPrivateKey] = React.useState<string>("");

  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);
  const setIsAuctionFinish = useSetRecoilState(isAuctionFinishAtom);
  const walletAccount = useRecoilValue(walletAccountAtom);
  const setToast = useSetRecoilState(toastAtom);

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === "completed") {
            setToast((prev) => ({
              ...prev,
              type: "success",
              message: isLoadingCancel
                ? t("message.myAuction.success1")
                : t("message.myAuction.success2"),
              isShow: true,
            }));
            setKlipRequestKey("");
            setIsAuctionFinish(true);
            setIsLoading(false);
            setIsLoadingCancel(false);
            setIsLoadingSettle(false);
          }
        },
      );
    },
    (isLoading || isLoadingCancel || isLoadingSettle) && klipRequestKey
      ? 1000
      : null,
  );

  const onClickCancelAuction = async () => {
    if (walletAccount.wallet === "klip") {
      setIsLoadingCancel(true);
      const res = await prepare.executeContract({
        bappName: t("bAppName"),
        from: walletAccount.account,
        to: addresses.nftMarket[8217],
        value: "0",
        abi: JSON.stringify(CancelAuctionABI),
        params: JSON.stringify([props.auctionId]),
      });

      if (res.request_key) {
        if (isMobile) {
          request(res.request_key);
        }
        setKlipRequestKey(res.request_key);
      }
      return;
    }
    if (walletAccount.kaikasProvider) {
      const isExisted = caver.wallet.isExisted(walletAccount.account);

      if (!isExisted) {
        setIsShowModal(true);
        return;
      }
    }

    try {
      setIsLoadingCancel(true);

      // @ts-ignore
      const cancelAuction = await contracts
        .nftMarketContract(walletAccount.chainId, walletAccount)
        .methods.cancelAuction(props.auctionId)
        .send({ from: walletAccount.account });

      setIsAuctionFinish(true);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoadingCancel(false);
    }
  };

  const onClickSettleAuction = async () => {
    if (walletAccount.wallet === "klip") {
      setIsLoadingSettle(true);
      const res = await prepare.executeContract({
        bappName: t("bAppName"),
        from: walletAccount.account,
        to: addresses.nftMarket[8217],
        value: "0",
        abi: JSON.stringify(SettleAuctionABI),
        params: JSON.stringify([props.auctionId]),
      });

      if (res.request_key) {
        if (isMobile) {
          request(res.request_key);
        }
        setKlipRequestKey(res.request_key);
      }
      return;
    }
    if (walletAccount.kaikasProvider) {
      const isExisted = caver.wallet.isExisted(walletAccount.account);

      if (!isExisted) {
        setIsShowModal(true);
        return;
      }
    }

    try {
      setIsLoadingSettle(true);

      // @ts-ignore
      const settleAuction = await contracts
        .nftMarketContract(walletAccount.chainId, walletAccount)
        .methods.settleAuction(props.auctionId)
        .send({ from: walletAccount.account });

      setIsAuctionFinish(true);
    } catch (_) {
    } finally {
      setIsLoadingSettle(false);
    }
  };

  const kaikasSign = () => {
    try {
      // @ts-ignore
      const keyring = new caver.wallet.keyring.singleKeyring(
        walletAccount.account,
        kaikasPrivateKey,
      );

      // @ts-ignore
      caver.wallet.newKeyring(walletAccount.account, kaikasPrivateKey);

      setToast((prev) => ({
        ...prev,
        type: "success",
        message: t("message.kaikasPrivateKeyAdded"),
        isShow: true,
      }));
      setIsShowModal(false);
    } catch (e: any) {
      console.log(JSON.stringify(e));
      // setToast((prev) => ({
      //   ...prev,
      //   type: 'danger',
      //   message: e,
      //   isShow: true,
      // }));
    }
  };

  const link = `/itemdetails/${props.tokenInfo?.chainId}/${props.auctionId}`;

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!props.tokenInfo) {
      e.preventDefault();
    }
  };

  React.useEffect(() => {
    if (!klipRequestKey) {
      setIsLoading(false);
      setIsLoadingCancel(false);
      setIsLoadingSettle(false);
    }
  }, [klipRequestKey]);

  return (
    <div className={`col-12 col-md-6 col-lg-3 single_gallery_item`}>
      <div className="pricing-item ">
        <div className="wraper">
          <NavLink to={link} onClick={handleClick}>
            <div
              style={{
                width: "100%",
                aspectRatio: "1",
                backgroundImage: `url('${props.tokenInfo?.img}')`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                borderRadius: 10,
              }}
            >
              {props.tokenInfo &&
                (isVideo ? (
                  <video
                    src={props.tokenInfo.img}
                    style={{ width: "100%", height: "100%" }}
                    autoPlay
                    loop
                    muted
                    playsInline
                  />
                ) : (
                  <img
                    src={props.tokenInfo.img}
                    style={{ width: 0 }}
                    alt=""
                    onError={() => setIsVideo(true)}
                  />
                ))}
            </div>
          </NavLink>

          <NavLink to={link} onClick={handleClick}>
            <h4
              className="d-flex align-items-center justify-content-between"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              #{props.tokenInfo?.id} {props.tokenInfo?.title}
              {props.tokenInfo?.is1155 ? (
                <span>{props.tokenInfo?.count} 개</span>
              ) : (
                <span>&nbsp;</span>
              )}
            </h4>
          </NavLink>

          {props.tokenInfo?.is1155 ? (
            <>
              <div
                className="pricing"
                style={{ marginTop: 20, marginBottom: 0 }}
              >
                {t("myAuction.price")} :{" "}
                <span>
                  {new BigNumber(props.buyNowPrice)
                    .div(new BigNumber(10).pow(18))
                    .toString()}{" "}
                  {getSymbolText(walletAccount.chainId)}
                </span>
              </div>
              <div className="pricing">
                {t("myAuction.count")} : <span>{props.tokenInfo.count}</span>
              </div>
            </>
          ) : (
            <>
              <div
                className="pricing"
                style={{ marginTop: 20, marginBottom: 0 }}
              >
                {t("myAuction.currentPrice")} :{" "}
                <span>
                  {new BigNumber(props.currentPrice)
                    .div(new BigNumber(10).pow(18))
                    .toString()}{" "}
                  {getSymbolText(walletAccount.chainId)}
                </span>
              </div>
              <div className="pricing">
                {t("myAuction.buyNowPrice")} :{" "}
                <span>
                  {new BigNumber(props.buyNowPrice)
                    .div(new BigNumber(10).pow(18))
                    .toString()}{" "}
                  {getSymbolText(walletAccount.chainId)}
                </span>
              </div>
            </>
          )}
          {props.auctionTypes.status === "1" &&
          props.highestBidder === walletAccount.account ? (
            new Date(parseInt(props.expiryDate + "000")) >= new Date() ? (
              <button
                className="btn more-btn w-100 text-center my-0 mx-auto d-flex align-items-center justify-content-center"
                disabled={isLoadingSettle}
                onClick={onClickSettleAuction}
              >
                {isLoadingSettle ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  t("myAuction.settleAuctionButton")
                )}
              </button>
            ) : (
              <div>Bidding</div>
            )
          ) : (
            props.auctionTypes.status === "1" &&
            (new Date(parseInt(props.expiryDate + "000")) <= new Date() ? (
              props.highestBidder === zeroAddress ? (
                <button
                  className="btn more-btn w-100 text-center my-0 mx-auto d-flex align-items-center justify-content-center"
                  disabled={isLoadingCancel}
                  onClick={onClickCancelAuction}
                >
                  {isLoadingCancel ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    t("myAuction.cancelAuctionButton")
                  )}
                </button>
              ) : (
                <button
                  className="btn more-btn w-100 text-center my-0 mx-auto d-flex align-items-center justify-content-center"
                  disabled={isLoadingSettle}
                  onClick={onClickSettleAuction}
                >
                  {isLoadingSettle ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    t("myAuction.settleAuctionButton")
                  )}
                </button>
              )
            ) : (
              <button
                className="btn more-btn w-100 text-center my-0 mx-auto d-flex align-items-center justify-content-center"
                disabled={isLoadingCancel}
                onClick={onClickCancelAuction}
              >
                {isLoadingCancel ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  t("myAuction.cancelAuctionButton")
                )}
              </button>
            ))
          )}
          {props.auctionTypes.status === "2" && (
            <button
              className="btn more-btn w-100 text-center my-0 mx-auto d-flex align-items-center justify-content-center"
              disabled={isLoadingSettle}
              onClick={onClickSettleAuction}
            >
              {isLoadingSettle ? (
                <Spinner animation="border" variant="light" />
              ) : (
                t("myAuction.settleAuctionButton")
              )}
            </button>
          )}
        </div>
      </div>
      <Modal show={isShowModal} onHide={() => setIsShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("kaikasPrivateKeyTitle")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="password"
            className="mb-3"
            placeholder={t("kaikasPrivateKeyPlaceholder")}
            value={kaikasPrivateKey}
            onChange={(e) => setKaikasPrivateKey(e.target.value)}
          />
          <Button type="button" className="w-100" onClick={kaikasSign}>
            {t("kaikasPrivateKeySubmit")}
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MyAuction;
