import React from 'react';
import ConnectWalletIconswallet from '../../../assets/img/icons/wallet.png';

import {
  ConnectWalletIconsw1,
  ConnectWalletIconsw4,
  ConnectWalletIconsw5,
} from '../../../utils/allImgs';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import { useWeb3React } from '@web3-react/core';
import { injectedConnector, kaikasConnector } from '../../../connector/index';
import QRCode from 'qrcode.react';
import { isMobile } from 'react-device-detect';
import { useRecoilState, useSetRecoilState } from 'recoil';
import walletAccountAtom from '../../../atoms/walletAccount';
import { useHistory } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import { provider } from 'web3-core';
import klipRequestKeyAtom from '../../../atoms/klipRequestKey';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import Web3Token from 'web3-token';
import * as web3connection from '../../../connection/web3';
import { api, getWalletDetail } from '../../../utils/api';
import myProfileAtom from '../../../atoms/myProfile';
import ReactGA from 'react-ga';
import { getSymbolText } from '../../../utils';

const bappName = '싸이클럽';

const FeaturesList = () => {
  const { t } = useTranslation();

  const history = useHistory();
  const web3 = useWeb3React();
  const bsc = useWallet();

  const [walletActivate, setWalletActivate] = React.useState<boolean>(false);
  const [walletAccount, setWalletAccount] = useRecoilState(walletAccountAtom);
  const setMyProfile = useSetRecoilState(myProfileAtom);

  const [isKlipRequest, setIsKlipRequest] = React.useState<boolean>(false);
  const [klaytnAddress, setKlaytnAddress] = React.useState<string>('');

  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        async (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            setKlaytnAddress(result.result.klaytn_address);
            setKlipRequestKey('');
            setWalletAccount({
              chainId: 8217,
              wallet: 'klip',
              account: result.result.klaytn_address,
            });

            const walletDetail = await getWalletDetail(
              result.result.klaytn_address
            );

            if (walletDetail) {
              setMyProfile({ ...walletDetail, signed: true });
            }

            ReactGA.event({
              category: 'Button',
              action: 'Klip login',
              label: 'login',
            });
          }
        }
      );
    },
    klipRequestKey ? 1000 : null
  );

  React.useEffect(() => {
    if (walletAccount.account) {
      if (walletAccount.wallet === 'metamask') {
      }
      history.replace('/');
    }
  }, [walletAccount]);

  React.useEffect(() => {
    web3Login();
  }, [web3]);

  const web3Login = async () => {
    if (web3.account && web3.chainId) {
      // metamask
      if (web3.connector === injectedConnector) {
        window.localStorage.setItem('wallet', 'metamask');
        setWalletAccount({
          chainId: web3.chainId,
          wallet: 'metamask',
          account: web3.account,
        });

        ReactGA.event({
          category: 'Button',
          action: `Metamask login (${getSymbolText(web3.chainId)})`,
          label: 'login',
        });
      }
      // kaikas
      // @ts-ignore
      if (web3.connector === kaikasConnector) {
        // @ts-ignore
        const provider = await kaikasConnector.getProvider();

        window.localStorage.setItem('wallet', 'kaikas');

        setWalletAccount({
          chainId: web3.chainId,
          wallet: 'kaikas',
          account: web3.account,
          kaikasProvider: provider,
        });
      }
      const walletDetail = await getWalletDetail(web3.account);

      if (walletDetail) {
        setMyProfile({ ...walletDetail, signed: true });
      }

      const result = await api(`/profile/${web3.account}/check`, 'GET');

      history.replace(result ? '/' : '/signup');
    }
  };

  React.useEffect(() => {
    if (bsc.account && bsc.chainId) {
      history.replace('/');
      window.localStorage.setItem('wallet', 'binance');
      setWalletAccount({
        chainId: bsc.chainId,
        wallet: 'binance',
        account: bsc.account,
        ethereum: bsc.ethereum as provider,
      });

      ReactGA.event({
        category: 'Button',
        action: `Binance login`,
        label: 'login',
      });
    }
  }, [bsc]);

  return (
    <>
      <div className="service-img-wrapper col-lg-5 col-md-12 col-sm-12 no-padding-right">
        <div className="features-list">
          <div className="who-we-contant text-center">
            {/* <img
              src={ConnectWalletIconswallet}
              className="mb-50"
              width="90"
              alt=""
            /> */}
            {/* <h4 className="w-text mb-30" data-wow-delay="0.3s">
              {t('connectWallet.chooseWalletText')}
            </h4> */}
            <div
              className="pricing-item v2"
              onClick={() => {
                if (web3.active) {
                  web3.deactivate();
                }
                web3.activate(injectedConnector);
              }}
            >
              <div className="wraper">
                <img
                  src={ConnectWalletIconsw1}
                  width="30"
                  className="wal-icon"
                  alt=""
                />
                {web3.active
                  ? web3.account
                  : t('connectWallet.connectWithMetamask')}
              </div>
            </div>
            {/* <div
              className="pricing-item v2"
              onClick={() => {
                if (bsc.account) {
                  bsc.reset();
                } else {
                  bsc.connect('bsc');
                }
              }}
            >
              <div className="wraper">
                <svg
                  viewBox="0 0 32 32"
                  width="30"
                  color="text"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: 10 }}
                >
                  <path
                    d="M24 0H8C3.58172 0 0 3.58172 0 8V24C0 28.4183 3.58172 32 8 32H24C28.4183 32 32 28.4183 32 24V8C32 3.58172 28.4183 0 24 0Z"
                    fill="#1E2026"
                  ></path>
                  <path
                    d="M16.2857 4L9.97035 7.6761L12.2922 9.03415L16.2857 6.7161L20.2792 9.03415L22.6011 7.6761L16.2857 4Z"
                    fill="#F0B90B"
                  ></path>
                  <path
                    d="M20.2792 10.9541L22.6011 12.3122V15.0283L18.6075 17.3463V21.9824L16.2857 23.3405L13.9639 21.9824V17.3463L9.97035 15.0283V12.3122L12.2922 10.9541L16.2857 13.2722L20.2792 10.9541Z"
                    fill="#F0B90B"
                  ></path>
                  <path
                    d="M22.6011 16.9483V19.6644L20.2792 21.0224V18.3063L22.6011 16.9483Z"
                    fill="#F0B90B"
                  ></path>
                  <path
                    d="M20.2561 22.9424L24.2496 20.6244V15.9883L26.5714 14.6302V21.9824L20.2561 25.6585V22.9424Z"
                    fill="#F0B90B"
                  ></path>
                  <path
                    d="M24.2496 11.3522L21.9278 9.99414L24.2496 8.63609L26.5714 9.99414V12.7102L24.2496 14.0683V11.3522Z"
                    fill="#F0B90B"
                  ></path>
                  <path
                    d="M13.9639 26.642V23.9259L16.2857 25.2839L18.6075 23.9259V26.642L16.2857 28L13.9639 26.642Z"
                    fill="#F0B90B"
                  ></path>
                  <path
                    d="M12.2922 21.0224L9.97035 19.6644V16.9483L12.2922 18.3063V21.0224Z"
                    fill="#F0B90B"
                  ></path>
                  <path
                    d="M16.2857 11.3522L13.9639 9.99414L16.2857 8.63609L18.6075 9.99414L16.2857 11.3522Z"
                    fill="#F0B90B"
                  ></path>
                  <path
                    d="M10.6437 9.99414L8.32183 11.3522V14.0683L6 12.7102V9.99414L8.32183 8.63609L10.6437 9.99414Z"
                    fill="#F0B90B"
                  ></path>
                  <path
                    d="M6 14.6302L8.32183 15.9883V20.6244L12.3154 22.9424V25.6585L6 21.9824V14.6302Z"
                    fill="#F0B90B"
                  ></path>
                </svg>
                {bsc.account
                  ? bsc.account
                  : t('connectWallet.connectWithBinanceChainWallet')}
              </div>
            </div> */}
            {/* <div
              className="pricing-item v2"
              onClick={async () => {
                // if (web3.active) {
                //   web3.deactivate();
                // } else {
                //   // @ts-ignore
                //   web3.activate(kaikasConnector);
                // }

                // @ts-ignore
                const { klaytn } = window;
                if (typeof klaytn !== 'undefined') {
                  const accounts = await klaytn.enable();

                  setWalletAccount({
                    chainId: 1001,
                    wallet: 'kaikas',
                    account: accounts[0],
                    kaikasProvider: klaytn,
                  });
                }

                // await kaikasConnector.activate();

                // const chainId = (await kaikasConnector.getChainId()) as string;
                // const account = await kaikasConnector.getAccount();
                // const provider = await kaikasConnector.getProvider();

                // if (chainId && account) {
                //   setWalletAccount({
                //     chainId: parseInt(chainId, 16),
                //     wallet: 'kaikas',
                //     account,
                //     kaikasProvider: provider,
                //   });
                // }
              }}
            >
              <div className="wraper">
                <img
                  src={ConnectWalletIconsw4}
                  width="30"
                  className="wal-icon"
                  alt=""
                />
                {walletActivate
                  ? walletAccount
                  : t('connectWallet.connectWithKaikas')}
              </div>
            </div> */}
            {/* <div
              className="pricing-item v2"
              onClick={async () => {
                if (klaytnAddress) {
                  setKlaytnAddress('');
                } else {
                  const res = await prepare.auth({ bappName });

                  setIsKlipRequest(true);

                  if (res.request_key) {
                    if (isMobile) {
                      request(res.request_key);
                    }
                    setKlipRequestKey(res.request_key);
                  }
                }
              }}
            >
              <div className="wraper">
                <img
                  src={ConnectWalletIconsw5}
                  width="30"
                  className="wal-icon"
                  alt=""
                />
                {klaytnAddress || t('connectWallet.connectWithKlip')}
              </div>
            </div> */}
            {/* {whoWeData &&
              whoWeData.map((item, i) => (
                <div key={i} className="pricing-item v2">
                  <img src={item.img} width="30" className="wal-icon" alt="" />
                  {item.text}
                </div>
              ))} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesList;
