import React from 'react';
import { setProfile } from '../../../utils/api';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import walletAccountAtom from '../../../atoms/walletAccount';
import web3 from '../../../connection/web3';
// @ts-ignore
import Web3Token from 'web3-token';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import toastAtom from '../../../atoms/toast';
import myProfileAtom from '../../../atoms/myProfile';

const ContactForm = () => {
  const history = useHistory();
  const walletAccount = useRecoilValue(walletAccountAtom);
  const setToast = useSetRecoilState(toastAtom);
  const setMyProfile = useSetRecoilState(myProfileAtom);
  const form = React.useRef();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const signup: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { token, type } = await getTokenType();

      // @ts-ignore
      const { name, phone, email, address } = e.target;

      const data = {
        // @ts-ignore
        name: name.value,
        // @ts-ignore
        phone: phone.value,
        // @ts-ignore
        email: email.value,
        // @ts-ignore
        address: address.value,
        // @ts-ignore
        username: username.value,
        wallet: walletAccount.account,
        uId: 0,
        bio: '',
        twitter: '',
        instagram: '',
        facebook: '',
        site: '',
      };

      await setProfile(token, type, data);

      setMyProfile((prev) => ({ ...prev, ...data }));

      setToast({
        type: 'success',
        isShow: true,
        message: '회원가입이 완료되었습니다.',
      });

      history.replace('/');
    } catch (_) {
      setToast({
        type: 'danger',
        isShow: true,
        message: '서버 에러',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getTokenType = async () => {
    let token: string, type: 'web3' | 'klaytn';

    token = await Web3Token.sign(
      (msg: any) => {
        return web3.eth.personal.sign(msg, walletAccount.account!, '');
      },
      {
        domain: 'kpopnft.net',
        statement: '',
        not_before: new Date(Date.now() - 24 * 60 * 60 * 1000),
      }
    );
    type = 'web3';

    return { token, type };
  };

  React.useEffect(() => {
    if (!walletAccount.account) {
      history.replace('/connectwallet');
    }
  }, [walletAccount]);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8">
          <div className="contact_form">
            {/* @ts-ignore */}
            <form ref={form} onSubmit={signup}>
              <div className="row">
                <div className="col-12">
                  <div id="success_fail_info"></div>
                </div>
                <div className="col-12 col-md-12">
                  <div className="group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="성함"
                      required
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <div className="group">
                    <input
                      type="text"
                      name="username"
                      id="username"
                      placeholder="프로필명"
                      required
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <div className="group">
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="연락처"
                      required
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="이메일"
                      required
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="group">
                    <input
                      type="text"
                      name="address"
                      id="address"
                      placeholder="주소"
                      required
                    />
                    <span className="highlight"></span>
                    <span className="bar"></span>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="more-btn mx-auto d-flex align-items-center justify-content-center"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      '완료'
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
