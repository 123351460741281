import WhoWeContant from './WhoWeContant'
import ContactForm from './ContactForm'

const CreatorSec = () => {

  return (
    <>
      <div className="creator-sec dd-bg">
          <WhoWeContant />
          <ContactForm />
      </div>
    </>
  );
}

export default CreatorSec;