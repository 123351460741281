import React from 'react';
import InfoComponent from '../InfoComponent';
import LiveAuctionsItem from '../LiveAuctionsItem';

import './liveAuctions.css';
import { IAuction } from '../../types';
import { getTokenInfo } from '../../utils';
import { useRecoilState, useRecoilValue } from 'recoil';
import auctionCategoryAtom from '../../atoms/auctionCategory';
import auctionKeywordAtom from '../../atoms/auctionKeyword';
import auctionSortAtom from '../../atoms/auctionSort';
import NFTMarketCollection from '../../abis/NAMarketV2.json';
import { web3BSC, web3Main, web3BSCTest } from '../../connection/web3';
import addresses from '../../constants/addresses';
import walletAccountAtom from '../../atoms/walletAccount';
import { caverAPI } from '../../connection/caver';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { useParams } from 'react-router';
import { IItem } from '../../types/index';

function LiveAuctionsContainer() {
  const { t } = useTranslation();
  const params = useParams();

  const sorts = [
    t('auction.sortItem1'),
    t('auction.sortItem2'),
    t('auction.sortItem3'),
    t('auction.sortItem4'),
    t('auction.sortItem5'),
  ];
  const categories = [
    t('auction.categoryItem1'),
    t('auction.categoryItem2'),
    t('auction.categoryItem3'),
    // t('auction.categoryItem4'),
    // t('auction.categoryItem5'),
    // t('auction.categoryItem6'),
    // t('auction.categoryItem7'),
    // t('auction.categoryItem8'),
  ];

  const [currentBlockchain, setCurrentBlockchain] = React.useState<
    'ETH' | 'BSC' | 'BSCTEST' | 'KLAY'
  >('ETH');
  const [auctions, setAuctions] = React.useState<IAuction[]>([]);

  const walletAccount = useRecoilValue(walletAccountAtom);
  const [auctionKeyword, setAuctionKeyword] =
    useRecoilState(auctionKeywordAtom);
  const [auctionSort, setAuctionSort] = useRecoilState(auctionSortAtom);
  const [auctionCategory, setAuctionCategory] =
    useRecoilState(auctionCategoryAtom);

  const getAuctions = async () => {
    let chainId: number, contract;
    switch (currentBlockchain) {
      case 'ETH':
        chainId = 1;
        contract = new web3Main.eth.Contract(
          // @ts-ignore
          NFTMarketCollection.abi,
          // @ts-ignore
          addresses.nftMarket[chainId]
        );
        break;
      case 'BSC':
        chainId = 56;
        contract = new web3BSC.eth.Contract(
          // @ts-ignore
          NFTMarketCollection.abi,
          // @ts-ignore
          addresses.nftMarket[chainId]
        );
        break;
      case 'BSCTEST':
        chainId = 97;
        contract = new web3BSCTest.eth.Contract(
          // @ts-ignore
          NFTMarketCollection.abi,
          // @ts-ignore
          addresses.nftMarket[chainId]
        );
        break;
      case 'KLAY':
        chainId = 8217;
        contract = new caverAPI.contract(
          // @ts-ignore
          NFTMarketCollection.abi,
          // @ts-ignore
          addresses.nftMarket[chainId]
        );
        break;
    }

    const auctions = await contract.methods
      .getOpenAuctions(auctionCategory, auctionSort, auctionKeyword, 0, 10)
      .call();

    const openAuctions = auctions[0];
    setAuctions(openAuctions);

    const promises = openAuctions.map(async (auction: IAuction) => {
      const tokenInfo: IItem | undefined = await getTokenInfo(
        parseInt(auction.tokenId),
        chainId,
        auction.auctionTypes.tokenType === '2'
      );
      if (tokenInfo) {
        tokenInfo.chainId = chainId;
        tokenInfo.is1155 = auction.auctionTypes.tokenType === '2';
        tokenInfo.count = auction.auctionTypes.quantity;
      }

      return {
        ...auction,
        tokenInfo,
      };
    });

    const data = await Promise.all(promises);
    setAuctions(data);
  };

  React.useEffect(() => {
    getAuctions();
  }, [currentBlockchain, auctionSort, auctionCategory, auctionKeyword]);

  React.useEffect(() => {
    // @ts-ignore
    if (params.category) {
      setTimeout(() => {
        setAuctions([]);
        // @ts-ignore
        setAuctionCategory(params.category);
      }, 500);
    }
  }, []);

  return (
    <section className="features  section-padding-50 ">
      <div className="container pt-5">
        {/* <InfoComponent
          titleSm={t('auction.infoTitleSm')}
          titleLg={t('auction.infoTitleLg')}
          text={t('auction.infoText')}
        /> */}
        <div className="row mb-5 justify-content-center">
          {/* <div className="col-6 col-md-3 mb-3">
            <label htmlFor="category" className="form-label text-white">
              {t('auction.itemToken')}
            </label>
            <Dropdown>
              <Dropdown.Toggle
                className="w-100 d-flex align-items-center justify-content-between"
                style={{
                  backgroundColor: 'white',
                  color: '#212529',
                  borderColor: '#ced4da',
                }}
              >
                {currentBlockchain}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                <Dropdown.Item onClick={() => setCurrentBlockchain('ETH')}>
                  ETH
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setCurrentBlockchain('BSC')}>
                  BSC
                </Dropdown.Item>
                {process.env.NODE_ENV !== 'production' && (
                  <Dropdown.Item
                    onClick={() => setCurrentBlockchain('BSCTEST')}
                  >
                    BSCTEST
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={() => setCurrentBlockchain('KLAY')}>
                  KLAY
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
          <div className="col-6 col-md-3 mb-3">
            <label htmlFor="category" className="form-label text-white">
              {t('auction.itemCategory')}
            </label>
            <Dropdown>
              <Dropdown.Toggle
                className="w-100 d-flex align-items-center justify-content-between"
                style={{
                  backgroundColor: 'white',
                  color: '#212529',
                  borderColor: '#ced4da',
                }}
              >
                {categories[auctionCategory]}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {categories.map((category, i: number) => (
                  <Dropdown.Item key={i} onClick={() => setAuctionCategory(i)}>
                    {category}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-6 col-md-3 mb-3">
            <label htmlFor="sort" className="form-label text-white">
              {t('auction.itemSort')}
            </label>
            <Dropdown>
              <Dropdown.Toggle
                className="w-100 d-flex align-items-center justify-content-between"
                style={{
                  backgroundColor: 'white',
                  color: '#212529',
                  borderColor: '#ced4da',
                }}
              >
                {sorts[auctionSort]}
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-100">
                {sorts.map((sort, i: number) => (
                  <Dropdown.Item key={i} onClick={() => setAuctionSort(i)}>
                    {sort}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-6 col-md-3 mb-3">
            <label htmlFor="keyword" className="form-label text-white">
              {t('auction.itemKeyword')}
            </label>
            <input
              id="keyword"
              type="text"
              className="form-control"
              placeholder={t('auction.itemKeywordPlaceholder')}
              value={auctionKeyword}
              onChange={(e) => setAuctionKeyword(e.target.value)}
            />
          </div>
        </div>
        <div className="row align-items-center">
          {auctions.length > 0 ? (
            auctions.map((item, i) => <LiveAuctionsItem key={i} {...item} />)
          ) : (
            <div className="text-center py-5">
              <h5>{t('auction.noData')}</h5>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default LiveAuctionsContainer;
