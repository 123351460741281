import React from 'react';

import './footer.css';

import data from '../../data/data-layouts/data-Footer.json';
import FooterLogo from '../../assets/img/footer_logo.png';
import { ReactComponent as IconFacebook } from '../../assets/img/icon-facebook.svg';
import { ReactComponent as IconTwitter } from '../../assets/img/icon-twitter.svg';
import { ReactComponent as IconMedium } from '../../assets/img/icon-medium.svg';
import { ReactComponent as IconTelegram } from '../../assets/img/icon-telegram.svg';
import { useWindowSize } from 'usehooks-ts';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function Footer() {
  const { width } = useWindowSize();
  // const iconSize = React.useMemo(() => (width >= 992 ? 40 : 30), [width]);
  const iconSize = 24;
  const { t } = useTranslation();

  return (
    <footer className="main-footer text-center">
      <div className="widgets-section padding-top-small padding-bottom-small">
        <div className="container">
          <div className="row clearfix">
            <div className="footer-column col-xl-3 col-lg-3 offset-lg-0 col-md-6 offset-md-3 col-10 offset-1">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ marginBottom: 57, fontSize: 14 }}
              >
                <Link to="/policy" style={{ color: '#c4bcf7' }}>
                  {t('footer.policy')}
                </Link>
                <Link to="/privacy" style={{ color: '#c4bcf7' }}>
                  {t('footer.privacy')}
                </Link>
                <Link to="/faq" style={{ color: '#c4bcf7' }}>
                  {t('footer.faq')}
                </Link>
              </div>
            </div>

            <div className="footer-column col-xl-6 col-lg-6 offset-lg-0 col-12  mb-5 mb-lg-0">
              <img src={FooterLogo} alt="Footer Logo" width={150} height={40} />
              <div style={{ color: '#6c668b', marginTop: 30, fontSize: 14 }}>
                <div>{t('footer.address')}</div>
                <div>
                  e-mail:{' '}
                  <a
                    href="mailto:help@cyclub.co"
                    style={{ color: '#6c668b' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    help@cyclub.co
                  </a>
                </div>
                {/* <div>
                  Tel:{' '}
                  <a
                    href="tel:02-2157-0270"
                    style={{ color: '#6c668b' }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    02-2157-0270
                  </a>
                </div> */}
              </div>
              {/* <div
                style={{ color: '#c4bcf7', fontSize: 30, fontWeight: 'bold' }}
              >
                {t('footer.newsletter')}
              </div>
              <div
                style={{
                  marginTop: 46,
                  marginBottom: 20,
                  fontSize: 18,
                  color: '#9c95c6',
                }}
              >
                {t('footer.text1')}
              </div>
              <div
                className="d-inline-flex align-items-center justify-content-center overflow-hidden"
                style={{
                  borderRadius: 10,
                  maxWidth: 500,
                  width: '100%',
                }}
              >
                <input
                  type="text"
                  style={{
                    maxWidth: 400,
                    width: '100%',
                    height: 45,
                    border: 0,
                    padding: '0 15px',
                    backgroundColor: '#8a84b1',
                    outline: 'none',
                  }}
                />
                <button
                  type="button"
                  style={{
                    width: 100,
                    height: 45,
                    lineHeight: '45px',
                    border: 0,
                    backgroundColor: '#532d84',
                    color: '#c4bcf7',
                    fontSize: 18,
                    fontWeight: 500,
                  }}
                >
                  {t('footer.confirm')}
                </button>
              </div> */}
            </div>

            <div className="footer-column col-lg-2 offset-lg-1 col-md-6 offset-md-3 col-10 offset-1">
              {/* <div
                className="d-flex align-items-center justify-content-between"
                style={{ marginBottom: 57, fontSize: 14 }}
              >
                <a href="javascript:void(0);" style={{ color: '#c4bcf7' }}>
                  {t('footer.policy')}
                </a>
                <a href="javascript:void(0);" style={{ color: '#c4bcf7' }}>
                  {t('footer.privacy')}
                </a>
              </div> */}
              <div className="d-flex align-items-center justify-content-between">
                <a
                  className="d-block"
                  href="https://twitter.com/kpopnft_"
                  // href="https://www.instagram.com/kpopnft__official"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconTwitter width={iconSize} height={iconSize} />
                </a>
                <a
                  className="d-block"
                  href="https://www.facebook.com/KPOP-NFT-100776129148131"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconFacebook width={iconSize} height={iconSize} />
                </a>
                <a
                  className="d-block"
                  href="https://t.me/kpopnft_official"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconTelegram width={iconSize} height={iconSize} />
                </a>
                <a
                  className="d-block"
                  href="https://medium.com/@kpopnft"
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconMedium width={iconSize} height={iconSize} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="auto-container">
          <div
            className="copyright-text"
            style={{ color: '#6c668b', fontSize: 14 }}
          >
            Copyright© cyclub, inc. All Rights Reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
