import { useEffect } from 'react';
import { loader } from '../../utils';
import { Helmet } from 'react-helmet';

function PreloaderContainer({ Title }: { Title: string }) {
  useEffect(() => {
    loader();
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>KPOP NFTs - {Title}</title>
        <meta name="robots" content="noindex, follow" />
        <meta
          name="description"
          content="The Best Place to Collect, Buy and Sell"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Helmet>
      <div id="preloader">
        <div className="preload-content">
          <div id="dream-load"></div>
        </div>
      </div>
    </>
  );
}

export default PreloaderContainer;
