import Breadcumb from '../../components/Breadcumb';
import InfoComponent from '../../components/InfoComponent';
import ContactForm from './ContactForm';
import { useTranslation } from 'react-i18next';
import '../../assets/css/contact.css';

const ContactContainer = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <Breadcumb
        namePage={t('contact.breadcumbNamePage')}
        title={t('contact.breadcumbTitle')}
        animNone={false}
        useTitle={false}
      /> */}
      <section className="section-padding-100 contact_us_area" id="contact">
        <div className="container pt-5">
          <div className="row">
            <div className="col-12">
              <InfoComponent
                titleSm={t('contact.infoTitleSm')}
                titleLg={t('contact.infoTitleLg')}
                text={t('contact.infoText')}
              />
            </div>
          </div>

          <ContactForm />
        </div>
      </section>
    </>
  );
};

export default ContactContainer;
