export const CreateAuctionABI = {
  inputs: [
    {
      internalType: 'address',
      name: '_contractAddress',
      type: 'address',
    },
    {
      internalType: 'uint256',
      name: '_tokenId',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_startingPrice',
      type: 'uint256',
    },
    {
      internalType: 'string',
      name: 'auctionTitle',
      type: 'string',
    },
    {
      internalType: 'uint256',
      name: '_buyNowPrice',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'expiryDate',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: '_category',
      type: 'uint256',
    },
    {
      internalType: 'enum NAMarketV2.TokenType',
      name: '_tokenType',
      type: 'uint8',
    },
    {
      internalType: 'uint256',
      name: '_quantity',
      type: 'uint256',
    },
  ],
  name: 'createAuction',
  outputs: [
    {
      internalType: 'uint256',
      name: 'auctionId',
      type: 'uint256',
    },
  ],
  stateMutability: 'payable',
  type: 'function',
  payable: true,
};

export const CancelAuctionABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'auctionId',
      type: 'uint256',
    },
  ],
  name: 'cancelAuction',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

export const SettleAuctionABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'auctionId',
      type: 'uint256',
    },
  ],
  name: 'settleAuction',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};

export const PlaceBidABI = {
  inputs: [
    {
      internalType: 'uint256',
      name: 'auctionId',
      type: 'uint256',
    },
    {
      internalType: 'uint256',
      name: 'bidPrice',
      type: 'uint256',
    },
  ],
  name: 'placeBid',
  outputs: [],
  stateMutability: 'payable',
  type: 'function',
  payable: true,
};

export const WithdrawABI = {
  inputs: [],
  name: 'withdrawCredit',
  outputs: [],
  stateMutability: 'nonpayable',
  type: 'function',
};