import {IProfile} from '../../types/index';
import styled from 'styled-components';


const AuthorImg = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 70px;
  overflow: hidden;
  background-color: white;
  background-size: cover;
  background-posigion: center;

  img {
    width: 100%;
    height: 100%;
  }
`;

function TopSellersItem({
                            rank,
                            title,
                            price,
                            sellerInfo,
                        }: {
    rank: number;
    title: string;
    price: string;
    sellerInfo?: IProfile;
}) {
    return (
        <div className="ranker-item">
            <div className="ranker-profile">
                <div className="ranking-number">{rank}</div>
                <AuthorImg
                    className="author-img"
                    style={{
                        backgroundImage: sellerInfo?.img ? `url(${sellerInfo.img})` : 'none',
                    }}
                />
            </div>
            {/* <a href="profile.html"> */}
            <h5 className="ranker-name">{sellerInfo?.username || title}</h5>
            {/* </a> */}
            <p className="author-earn mb-0" style={{color: "#5C54F2"}}>${price}</p>
        </div>
    );
}

export default TopSellersItem;
