import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

// import './script.js'
import './navbar.css';
import { Logo } from '../../utils/allImgs';
import { Addshrink, getSymbolText } from '../../utils';
import Preloader from '../../components/Preloader';
import { useWeb3React } from '@web3-react/core';
import contracts from '../../constants/contracts';
import { useInterval } from 'usehooks-ts';
import BigNumber from 'bignumber.js';
import { useRecoilState, useSetRecoilState } from 'recoil';
import walletAccountAtom from '../../atoms/walletAccount';
import { useWallet } from '@binance-chain/bsc-use-wallet';
import IconETH from '../../assets/img/icons/icon-eth.png';
import IconBNB from '../../assets/img/icons/icon-bnb.png';
import IconKLAY from '../../assets/img/icons/icon-klay.png';
import addresses from '../../constants/addresses';
// @ts-ignore
import { prepare, request, getResult } from 'klip-sdk';
import { Spinner } from 'react-bootstrap';
import klipRequestKeyAtom from '../../atoms/klipRequestKey';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import toastAtom from '../../atoms/toast';
import i18n from 'i18next';
import myProfileAtom, { initialMyProfile } from '../../atoms/myProfile';
import { WithdrawABI } from '../../utils/abis';

function Head({ Title }: { Title: any }) {
  const { t } = useTranslation();

  const web3 = useWeb3React();
  const bsc = useWallet();
  const [userPrice, setUserPrice] = React.useState<number>(0);
  const [isLoadingWithdraw, setIsLoadingWithdraw] =
    React.useState<boolean>(false);

  const [walletAccount, setWalletAccount] = useRecoilState(walletAccountAtom);
  const [klipRequestKey, setKlipRequestKey] =
    useRecoilState(klipRequestKeyAtom);
  const setToast = useSetRecoilState(toastAtom);
  const setMyProfile = useSetRecoilState(myProfileAtom);

  const notSupportedNetwork = React.useMemo(
    () => walletAccount.account && walletAccount.chainId !== 1,
    [walletAccount]
  );

  const Icon = React.useMemo(() => {
    switch (walletAccount.chainId) {
      case 1:
      case 3:
      case 4:
      case 5:
      case 42:
        return IconETH;
      case 56:
      case 97:
        return IconBNB;
      case 1001:
      case 8217:
        return IconKLAY;
    }
  }, [walletAccount]);

  const LanguageTitle = React.useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return 'English';
      case 'ko':
        return '한국어';
    }
  }, [t]);

  const onClickLogout = React.useCallback(() => {
    try {
      if (!walletAccount.kaikasProvider) {
        web3.deactivate();
        bsc.reset();
      }
    } catch (e) {
      console.log(e);
    } finally {
      window.localStorage.removeItem('wallet');
      setWalletAccount({
        ...walletAccount,
        account: '',
        wallet: '',
        ethereum: undefined,
        kaikasProvider: undefined,
      });
      setMyProfile(initialMyProfile);
    }
  }, [walletAccount, web3, bsc]);

  const getUserPriceList = React.useCallback(async () => {
    if (walletAccount.account) {
      try {
        const result = await contracts
          .nftMarketContract(walletAccount.chainId, walletAccount)
          .methods.userPriceList(walletAccount.account)
          .call();

        setUserPrice(result);
      } catch (e) {
        console.log(e);
      }
      // }
    }
  }, [walletAccount]);

  useInterval(
    () => {
      getResult(klipRequestKey).then(
        (result: {
          expiration_time: number;
          request_key: string;
          result: { klaytn_address: string };
          klaytn_address: string;
          status: string;
        }) => {
          if (result.status === 'completed') {
            setKlipRequestKey('');
            setIsLoadingWithdraw(false);
          }
        }
      );
    },
    isLoadingWithdraw && klipRequestKey ? 1000 : null
  );

  const withdraw = React.useCallback(async () => {
    if (walletAccount.account) {
      setIsLoadingWithdraw(true);
      if (walletAccount.wallet === 'klip') {
        const res = await prepare.executeContract({
          bappName: '싸이클럽',
          from: walletAccount.account,
          to: addresses.nftMarket[8217],
          value: '0',
          abi: JSON.stringify(WithdrawABI),
          params: JSON.stringify([]),
        });

        if (res.request_key) {
          if (isMobile) {
            request(res.request_key);
          }
          setKlipRequestKey(res.request_key);
        }
        return;
      }
      try {
        await contracts
          .nftMarketContract(walletAccount.chainId, walletAccount)
          .methods.withdrawCredit()
          .send({ from: walletAccount.account });

        setToast((prev) => ({
          ...prev,
          type: 'success',
          message: t('message.withdraw.success1'),
          isShow: true,
        }));
      } catch (e) {
        console.log(e);
      }
    }
  }, [walletAccount]);

  useInterval(
    () => {
      getUserPriceList();
    },
    walletAccount ? 2000 : null
  );

  useEffect(() => {
    Addshrink();
  }, [window.pageYOffset]);

  return (
    <>
      <Preloader Title={Title} />
      <nav
        className="navbar navbar-expand-lg navbar-white fixed-top"
        id="banner"
        style={{ paddingTop: notSupportedNetwork ? 30 : '0.5rem' }}
      >
        {notSupportedNetwork && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: 30,
              lineHeight: '30px',
              backgroundColor: '#E51052',
              textAlign: 'center',
              color: 'white',
              fontSize: 14,
            }}
          >
            {t('header.notSupportedNetwork')}
          </div>
        )}
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <span>
              <img src={Logo} alt="logo" style={{ height: 40 }} />
            </span>
          </NavLink>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="collapsibleNavbar">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  {t('header.home')}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/auctions">
                  {t('header.auction')}
                </NavLink>
              </li>
              {/* <li className="nav-item Navdropdown">
                <NavLink
                  className="nav-link Navdropdown-toggle"
                  to="#"
                  data-toggle="Navdropdown"
                >
                  Discover
                </NavLink>
                <div className="Navdropdown-menu">
                  {data[0].dataUp &&
                    data[0].dataUp.map((item, i) => (
                      <NavLink key={i} className="Navdropdown-item" to={item.path}>
                        {item.title}
                      </NavLink>
                    ))}
                </div>
              </li> */}
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/activity">
                  Activity
                </NavLink>
              </li> */}

              {/* <li className="nav-item Navdropdown">
                <NavLink
                  className="nav-link Navdropdown-toggle"
                  to="#"
                  data-toggle="Navdropdown"
                >
                  Pages
                </NavLink>
                <div className="Navdropdown-menu">
                  {data[1].dataDown &&
                    data[1].dataDown.map((item, i) => (
                      <NavLink key={i} className="Navdropdown-item" to={item.path}>
                        {item.title}
                      </NavLink>
                    ))}
                </div>
              </li> */}
              {walletAccount.account && (
                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/createitem">
                      {t('header.createItem')}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/collection">
                      {t('header.collection')}
                    </NavLink>
                  </li>
                </>
              )}
              <li className="nav-item">
                <NavLink className="nav-link" to="/contact">
                  {t('header.contact')}
                </NavLink>
              </li>
              {/* <li>
                <NavDropdown title={LanguageTitle}>
                  <NavDropdown.Item onClick={() => i18n.changeLanguage('ko')}>
                    한국어
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => i18n.changeLanguage('en')}>
                    English
                  </NavDropdown.Item>
                </NavDropdown>
              </li> */}
              <li className="lh-55px ms-lg-5">
                {walletAccount && userPrice > 0 ? (
                  <button
                    type="button"
                    disabled={isLoadingWithdraw}
                    className="btn login-btn me-3"
                    onClick={withdraw}
                  >
                    {isLoadingWithdraw ? (
                      <Spinner animation="border" variant="light" />
                    ) : (
                      <>
                        {t('header.withdraw')} (
                        {new BigNumber(userPrice)
                          .div(new BigNumber(10).pow(18))
                          .toString()}{' '}
                        {getSymbolText(walletAccount.chainId)})
                      </>
                    )}
                  </button>
                ) : null}
                {walletAccount.account ? (
                  <button
                    type="button"
                    className="btn login-btn"
                    onClick={onClickLogout}
                  >
                    <img
                      src={Icon}
                      alt=""
                      style={{ width: 20, height: 20, marginRight: 10 }}
                    />
                    {walletAccount.account.substr(0, 7)}...
                    {walletAccount.account.substr(
                      walletAccount.account.length - 7
                    )}
                  </button>
                ) : (
                  <NavLink to="/connectwallet" className="btn login-btn">
                    {t('header.connectWallet')}
                  </NavLink>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Head;
