import { useTranslation } from 'react-i18next';

const WhoWeContant = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="who-we-contant">
        <div className="dream-dots text-left">
          <span className="gradient-text ">{t('createItem.topTitle1')}</span>
        </div>
        <h4>{t('createItem.topTitle2')}</h4>
      </div>
    </>
  );
};

export default WhoWeContant;
