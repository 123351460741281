import Head from '../../layouts/Head';
import AuctionsContainer from '../../containers/Auctions';
import Footer from '../../layouts/Footer';
import { useTranslation } from 'react-i18next';

function Auctions() {
  const { t } = useTranslation();

  return (
    <>
      <Head Title={t('auction.title')} />
      <AuctionsContainer />
      <Footer />
    </>
  );
}

export default Auctions;
