import { IProfile } from '../types/index';
const API_URL = 'psda3kr2mh.us-east-2.awsapprunner.com'
const API_PROTOCAL = 'https://'

export const api = (url: string, method?: string, body?: BodyInit, headers?: HeadersInit) => {
  return fetch(`${API_PROTOCAL}${API_URL}/api/v1${url}`, {
    body,
    headers,
    method
  })
  .then((res: any) => res.json())
  .catch((e: any) => {
    return Promise.reject(e);
  });
}

export const getWalletDetail = (wallet:string) => api(`/profile/${wallet}/detail`)

export const checkUserName = (username:string) => api(`/profile/${username}/chkname`)

export const getProfile = (addresses: string[]) => 
  api(`/profile/data`, 'POST', JSON.stringify({address: addresses}), {'Content-Type': 'application/json'})

export const setProfile = (token: string, type: 'web3' | 'klaytn', data: IProfile) => 
  api(`/profile`, 'POST', JSON.stringify(data), {'Authorization': token, 'access-type': type, 'Content-Type': 'application/json'})
